// this will be, of course, later loaded from database

import provoni from "./themes/provoni"
import salabka from "./themes/salabka"
import danielapolubedovova from "./themes/danielapolubedovova"

export default {
  provoni,
  salabka,
  danielapolubedovova,
}
