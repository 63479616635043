import merge from "lodash/merge"
import {twMerge} from "tailwind-merge"

export default {
  computed: {
    // merge default props with override props from parent component(s)
    props() {
      // if (!this) return
      // console.log("defaults:", Object.hasOwn(this, "defaults"))
      // Object.hasOwn(this, "defaults") breaks on production
      // let hasDefaults = Object.hasOwn(this, "defaults") && Object.keys(this.defaults).length > 0 // typeof this.defaults !== "undefined" //&& Object.keys(this.defaults).length > 0
      // console.log("this:", typeof this, this)
      let hasDefaults =
        typeof this === "object" &&
        // Object.hasOwn(this, "defaults") &&
        // this.hasOwnProperty("defaults") &&
        this.defaults &&
        Object.keys(this.defaults).length > 0
      let hasOverride =
        typeof this === "object" &&
        // Object.hasOwn(this, "override") &&
        // this.hasOwnProperty("override") &&
        this.override &&
        Object.keys(this.override).length > 0
      let props = {}
      let newProps = {}

      // console.log(
      //   "hasDefaults:",
      //   hasDefaults,
      //   "hasOverride:",
      //   hasOverride,
      //   this.componentFile,
      //   this.componentName
      // )

      if (hasDefaults && hasOverride) props = merge(this.defaults, this.override)
      else if (hasDefaults) props = JSON.parse(JSON.stringify(this.defaults)) //this.defaults
      else if (hasOverride) props = this.override

      let hasProps = props && Object.keys(props).length > 0
      if (hasProps) {
        newProps = extendPropsWithClass({
          props,
          that: this,
        })
      }

      return newProps
    },
  },
  props: {
    override: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}

let extendPropsWithClass = (args) => {
  // console.log("extendPropsWithClass: args:", args.props.root?.class)
  if (args.props?.root?.class) {
    // console.log("extendPropsWithClass: args:", args.props.root?.class)
  }

  if (!args) return {}
  let props = args.props
  let newProps = {}
  let that = args.that
  // console.log("that:", that)
  let classes = {}
  let commonClasses = Object.hasOwn(props, "commonClasses") ? props.commonClasses : ""
  let activeProps = Object.keys(that.$props).reduce(
    (acc, key) => Object.assign(acc, that[key] !== undefined ? {[key]: that[key]} : {}),
    {}
  )

  // console.log("activeProps:", JSON.parse(JSON.stringify(activeProps)))

  let hasProps = props && Object.keys(props).length > 0

  if (hasProps) {
    Object.keys(props).forEach((pKey) => {
      if (isObject(props[pKey])) {
        // ignore props that don't specify class
        // console.log("props[pKey]", pKey, props[pKey], props)
        classes[pKey] = []

        // prefix common classes to all combinations
        classes[pKey].push(commonClasses)

        // append classes
        if (Object.hasOwn(props[pKey], "class")) {
          classes[pKey].push(props[pKey].class)
        }

        // append classes according to active props
        if (Object.hasOwn(props[pKey], "propClass")) {
          let propClass = props[pKey].propClass

          Object.keys(propClass).forEach((pcKey) => {
            let activePropValue =
              activeProps && activeProps[pcKey] !== null && activeProps[pcKey] !== undefined
                ? activeProps[pcKey].toString()
                : false // for cases when prop is boolean or 0
            // append _common
            if (Object.hasOwn(propClass[pcKey], "_common"))
              classes[pKey].push(propClass[pcKey]._common)

            // append class according to active prop
            if (activePropValue) classes[pKey].push(propClass[pcKey][activePropValue])
          })
        }

        // console.log("classes:", classes)

        // extend props with generated classes
        newProps[pKey] = props[pKey]
        const jointClasses = classes[pKey].join(" ")
        const merged = twMerge(jointClasses)
        // console.log("jointClasses:", jointClasses)
        // console.log("merged:", merged)
        newProps[pKey].class = merged
      }
    })
  }

  // console.log("EXT:", that.$options.name, activeProps, props, classes)
  // console.log("newProps:", newProps)
  return newProps
}

let isObject = (v) => {
  return typeof v === "object" && v !== null
}
