<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- text -->
      <OneParagraph :content="content" :align="align" :override="props.text" v-bind="props.text" />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneText",
  mixins: [mixinClasses],
  props: {
    content: {
      type: String,
    },
    align: {
      type: String,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
      text: {
        class: "w-full",
      },
    },
  }),
}
</script>
