<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- card -->
    <!-- <a :href="props.link.href"
      data-one-label="cardWrap"
      v-bind="props.link"
      > -->

    <!-- <div v-html="svgString"></div> -->

    <OneLink
      ref="link"
      :style="{height: `${width}px`}"
      v-bind="props.link"
      :disabled="linkDisabled"
    >
      <!-- image & caption wrap -->
      <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
        <!-- logo -->
        <OneImage v-bind="props.logo" v-if="props.logo && props.logo.src" />

        <!-- heading -->
        <OneHeading
          v-bind="props.heading"
          :color="headingColor"
          v-if="props.heading && props.heading.content"
        />

        <!-- caption -->
        <OneParagraph
          v-bind="props.caption"
          :color="captionColor"
          v-if="props.caption && props.caption.content"
        />
      </div>

      <!-- background image -->
      <!-- <OneImage
        v-bind="props.backgroundImage"
        v-if="props.backgroundImage && props.backgroundImage.src"
      /> -->
      <canvas class="absolute inset-0" :id="`canvas-${this.componentUid}`" />
    </OneLink>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

// https://github.com/g-harel/blobs
// TODO: preserve image aspect ratio
// https://stackoverflow.com/questions/28047792/html-canvas-scaling-image-to-fit-without-stretching
// import * as blobs2 from "blobs/v2"
import * as blobs2Animate from "blobs/v2/animate"
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneCard3",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    cardCount: {
      type: Number,
      default: 1,
    },
    headingColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
  },
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    width: Math.min(window.innerWidth, window.innerHeight),
    defaults: {
      commonClasses: "",
      root: {
        propClass: {
          cardCount: {
            _common: "w-full",
            1: "",
            2: "md:w-1/2 md:first:pr-2 md:last:pl-2",
            3: "md:w-1/3 md:first:pr-2 md:last:pl-2",
            4: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            5: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            6: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            7: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            8: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            9: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            10: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
          },
        },
      },
      link: {
        class:
          "z-10 relative overflow-hidden w-full flex-center rounded-lg hover:opacity-75 transition-all duration-300 ease-in-out",
        style: {
          height: "50vh",
        },
      },
      backgroundImage: {
        class: "absolute inset-0",
        maxWidth: "full",
        minHeight: "full",
      },
      logoCaptionWrap: {
        class: "relative z-10 flex flex-col",
        // style: {
        //   width: '20%'
        // }
      },
      logo: {
        fit: "contain",
      },
      caption: {
        align: "center",
        color: "white",
        class: "whitespace-nowrap",
      },
    },
  }),
  computed: {
    linkDisabled() {
      return this.props.link.href ||
        this.props.link.url ||
        this.props.link.pageId ||
        (this.props.link.path && this.props.link.path.length)
        ? false
        : true
    },
    //   svgString() {
    //     return blobs2.svg(
    //   {
    //       seed: Math.random(),
    //       extraPoints: 8,
    //       randomness: 4,
    //       size: 256,
    //   },
    //   {
    //       fill: "white", // 🚨 NOT SANITIZED
    //       stroke: "black", // 🚨 NOT SANITIZED
    //       strokeWidth: 4,
    //   }
    //     )
    // },
  },
  created() {
    // console.log('created')
    // console.log(this.svgString)
  },
  mounted() {
    this.width = this.$refs.link.$el.getBoundingClientRect().width
    this.renderCanvas()
  },
  methods: {
    renderCanvas() {
      // Fetch reference to canvas.
      const canvas = document.getElementById(`canvas-${this.componentUid}`)

      // Set blob size relative to window, but limit to 600.
      // const size = Math.min(600, Math.min(window.innerWidth - 64, window.innerHeight - 256));
      const width = this.width
      const height = width

      canvas.style.width = `${width}px`
      canvas.style.height = `${height}px`

      // Scale resolution to take into account device pixel ratio.
      const resolution = width * (window.devicePixelRatio || 1)
      canvas.width = resolution
      canvas.height = resolution

      // Compute logo size and position.
      const logoWidth = width * 2 //resolution * 0.8;
      const logoHeight = height * 2 //logoWidth * 0.8;
      const logoOffsetX = (resolution - logoWidth) / 2
      const logoOffsetY = (resolution - logoHeight) / 2

      // Set blob color and set context to erase intersection of content.
      const ctx = canvas.getContext("2d")
      ctx.fillStyle = "#ec576b"

      // Use logo word asset to cut out from the generated blob.
      const logoWord = new Image()
      // logoWord.src = "./assets/logo.svg";
      logoWord.src = this.props.backgroundImage.src

      // Create animation and draw its frames in `requestAnimationFrame` callbacks.
      const animation = blobs2Animate.canvasPath()
      const renderFrame = () => {
        ctx.clearRect(0, 0, resolution, resolution)

        ctx.drawImage(logoWord, logoOffsetX, logoOffsetY, logoWidth, logoHeight)
        ctx.globalCompositeOperation = "destination-in"
        ctx.fill(animation.renderFrame())

        ctx.globalCompositeOperation = "source-over"

        requestAnimationFrame(renderFrame)
      }
      requestAnimationFrame(renderFrame)

      // Generate a keyframe with overridable default values.
      const genFrame = (overrides) => ({
        duration: 8000,
        timingFunction: "ease",
        // callback: loopAnimation,
        blobOptions: {
          extraPoints: 3,
          randomness: 4,
          seed: Math.random(),
          size: resolution,
        },
        ...overrides,
      })

      // Callback for every frame which starts transition to a new frame.
      // const loopAnimation = () => animation.transition(genFrame())

      // Immediately show a shape.
      animation.transition(genFrame({duration: 0}))

      // Quickly animate to a new frame when canvas is clicked.
      // canvas.onclick = () => {
      //     animation.transition(genFrame({duration: 400, timingFunction: "elasticEnd0"}));
      // };
    },
  },
}
</script>
