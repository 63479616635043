<template>
  <!-- variant 1 -->
  <OneCard1
    :override="override"
    :headingColor="headingColor"
    :captionColor="captionColor"
    :cardCount="cardCount"
    v-if="variant == 1"
  />

  <!-- variant 2 -->
  <OneCard2
    :override="override"
    :headingColor="headingColor"
    :captionColor="captionColor"
    :cardCount="cardCount"
    v-else-if="variant == 2"
  />

  <!-- variant 3 -->
  <OneCard3
    :override="override"
    :headingColor="headingColor"
    :captionColor="captionColor"
    :cardCount="cardCount"
    :index="index"
    v-else-if="variant == 3"
  />
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneCard",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    cardCount: {
      type: Number,
      default: 1,
    },
    headingColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
}
</script>
