<template>
  <div
    class="one-section-menu"
    :data-one-component="$options.name"
    v-bind="props.root"
    :style="menuStyle"
  >
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- logo -->
      <OneImage
        class="logo"
        v-bind="props.logo"
        v-if="props.logo && props.logo.src"
        @click="onLogoClick()"
      />

      <div class="mt-4 w-full relative flex flex-center">
        <!-- back button -->
        <div class="absolute left-0 top-0 bottom-0 h-full flex flex-col justify-center">
          <OneButton
            type="icon"
            size="auto"
            v-bind="props.backButton"
            icon="ArrowLeft"
            v-if="showBackButton"
            @update:click="onBackBtnClick()"
          />
        </div>

        <div class="menu-items flex flex-row justify-center">
          <OneLink
            class="whitespace-nowrap"
            v-bind="{...props.menuItem, ...item}"
            v-for="(item, index) in menuItems"
            :key="`item-${index}`"
            >{{ item.title }}</OneLink
          >
        </div>

        <!-- commerce: cart button -->
        <div class="absolute right-0">
          <OneButton
            type="icon"
            size="auto"
            v-bind="props.cartButton"
            icon="Cart2"
            v-if="showCartButton"
            @update:click="onCartBtnClick()"
          />
        </div>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneMenu4",
  mixins: [mixinClasses],
  props: {
    openedSize: {
      type: String,
      default: "small",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    showHamburgerButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showCartButton: {
      type: Boolean,
      default: false,
    },

    // positions
    hamburgerWrapPosition: {
      type: String,
      default: "right",
    },
    cartButtonIcon: {
      type: String,
      default: "Cart1",
    },
    cartButtonPosition: {
      type: String,
      default: "right",
    },
    logoPosition: {
      type: String,
      default: "center",
    },
    backButtonPosition: {
      type: String,
      default: "left",
    },

    keepLogoCentered: {
      type: Boolean,
      default: true,
    },
    menuItems: {
      type: Array,
    },
    background: {
      type: String,
      default: "transparent",
    },
    currentLang: {
      type: String,
    },
    siteLangs: {
      type: Array,
      default: () => ["cs", "en"],
    },
  },
  emits: ["update:logoClick", "update:backBtnClick", "update:cartBtnClick"],
  data: () => ({
    prevScrollPos: 0,
    menuStyle: null,
    menuOpen: false,
    menuVisible: true,
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full transition-all duration-500 ease-in-out",
        propClass: {
          fixed: {
            true: "fixed top-0 z-50",
            false: "relative z-0",
          },
          background: {
            white: "bg-white",
            transparent: "",
          },
        },
      },
      container: {
        // class: "grid grid-cols-3 grid-rows-1",
        // contentLayout: "grid",
        class: "flex-col justify-center items-center",
        contentAlign: "none",
      },
      langWrap: {
        class: "col-span-1 row-start-1 row-span-1 self-center",
        propClass: {},
      },
      hamburgerWrap: {
        class: "flex flex-row justify-center items-center col-span-1 row-start-1 row-span-1 z-20",
        propClass: {
          hamburgerWrapPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      backButton: {
        class: "col-span-1 row-start-1 row-span-1",
        propClass: {
          backButtonPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      logo: {
        class: "cursor-pointer col-span-1 row-start-1 row-span-1 flex-center",
        propClass: {
          logoPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
        fit: "contain",
        style: {
          width: "100%",
          maxWidth: "200px",
          maxHeight: "30px",
          alignSelf: "center",
        },
      },
      cartButton: {
        class: "col-span-1 row-start-1 row-span-1",
        propClass: {
          cartButtonPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      menuItems: {
        class: "",
        propClass: {
          openedSize: {
            small: "absolute top-0 flex flex-col mt-20 px-2 xs:px-4 sm:px-6 xl:px-32 pb-5",
            fullscreen:
              "absolute top-0 w-screen h-screen z-10 flex flex-col justify-center items-center text-center ",
          },
          hamburgerWrapPosition: {
            // this is intentinal - to align the menu items with the hamburger icon
            left: "left-0 text-left",
            center: "left-0 text-center w-full",
            right: "right-0 text-right",
          },
          background: {
            white: "bg-white",
            transparent: "",
          },
        },
      },
      menuItem: {
        class: "px-3 my-1 py-1",
        propClass: {
          openedSize: {
            small: "",
            fullscreen: "!w-auto",
          },
        },
      },
      menuLang: {
        class: "px-3 my-1 py-1 text-dark",
      },
    },
  }),
  computed: {
    siteLangsFull() {
      let langLength = "short"
      let l = []
      let langsMap = {
        cs: {
          short: "cs",
          long: "česky",
        },
        en: {
          short: "en",
          long: "english",
        },
      }

      this.siteLangs.forEach((lang) => {
        if (lang != this.currentLang) {
          l.push({
            title: langsMap[lang][langLength],
            code: lang,
          })
        }
      })

      return l
    },
    showHamburger() {
      return this.showHamburgerButton && this.menuItems.length > 0
    },
    showBackBtnFill() {
      // return true
      return this.keepLogoCentered && !this.showBackButton && !this.showHamburger
    },
    showCartBtnFill() {
      return this.keepLogoCentered && !this.showCartButton && this.showHamburger
    },
  },
  created() {
    if (this.autohide) this.enableAutohide()
  },
  methods: {
    enableAutohide() {
      window.addEventListener("scroll", (e) => {
        this.scrolled(e)
      })
    },
    scrolled() {
      let currentScrollPos = window.pageYOffset
      // console.log('scrolled:', currentScrollPos, this.prevScrollPos, e)
      if (this.prevScrollPos >= currentScrollPos) {
        if (!this.menuVisible) this.showMenu()
      } else {
        if (this.menuVisible && this.prevScrollPos >= 0) this.hideMenu()
      }
      this.prevScrollPos = currentScrollPos
    },
    showMenu() {
      // console.log('showMenu()')
      this.menuStyle = {
        top: "0",
      }
      this.menuVisible = true
    },
    hideMenu() {
      // console.log('hideMenu()')
      this.menuStyle = {
        top: "-100px",
      }
      this.menuVisible = false
      this.menuOpen = false
    },
    onLogoClick() {
      this.$emit("update:logoClick")
    },
    onBackBtnClick() {
      this.$emit("update:backBtnClick")
    },
    onCartBtnClick() {
      this.$emit("update:cartBtnClick")
    },
  },
}
</script>

<style scope lang="less">
.btn-back-fill,
.btn-cart-fill {
  width: 48px;
  height: 48px;
}
</style>
