<template>
  <svg class="svg-clip-container" :width="width" :height="width" xmlns="http:/www.w3.org/2000/svg">
    <!-- <defs> -->
    <!-- <clipPath :id="`svg-clip-${componentUid}`"> -->
    <path stroke="none" stroke-width="0" :fill="fill" :d="svgPath"></path>
    <!-- </clipPath> -->
    <!-- </defs> -->
  </svg>
</template>

<script>
import mixinClasses from "../../mixins/classes"
import * as blobs2 from "blobs/v2"
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneShape",
  mixins: [mixinClasses],
  props: {
    shape: {
      type: String,
      default: "blob",
    },
    fill: {
      type: String,
      default: "black",
    },
    width: {
      type: Number,
      default: 100,
    },
    // size: {
    //   type: String,
    //   _setsClass: true,
    //   default: 'default',
    //   validator: val => ['small', 'default', 'large'].includes(val)
    // },
    // icon: {
    //   type: String,
    //   default: ''
    // },
    // link: {
    //   type: Boolean,
    //   default: false
    // }
  },
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    svgPath: "",
    defaults: {
      commonClasses: "",
      root: {
        // class: ' children:fill-primary-dark w-12 h-12'
        class: "",
        propClass: {
          // link: {
          //   true: 'cursor-pointer hover:opacity-75 transition-all duration-300 ease-in-out',
          //   false: ''
          // },
          // size: {
          //   small: 'w-8 h-8',
          //   default: 'w-12 h-12',
          //   large: 'w-14 h-14'
          // }
        },
      },
    },
  }),
  computed: {},
  mounted() {
    this.svgPath = this.getSvgPath()
  },
  methods: {
    getSvgPath() {
      if (this.shape == "blob") {
        return blobs2.svgPath(
          {
            seed: Math.random(),
            extraPoints: 4,
            randomness: 4,
            size: this.width,
          },
          {
            fill: this.fill, // 🚨 NOT SANITIZED
            // stroke: "black", // 🚨 NOT SANITIZED
            // strokeWidth: 4,
          }
        )
      } else {
        return ""
      }
    },
  },
}
</script>
