<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- video -->
      <OneVideoYoutube
        :override="props.video"
        v-bind="props.video"
        :code="code"
        :videoKind="videoKind"
        :autoplay="autoplay"
        :mute="mute"
      />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneSingleVideoYoutube",
  mixins: [mixinClasses],
  props: {
    code: {
      type: String,
    },
    videoKind: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    mute: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex-wrap bg-black",
      },
      card: {
        class: "pb-5",
      },
    },
  }),
}
</script>
