<template>
  <!-- variant 1 -->
  <OneMenu1
    v-bind="{...$props, ...$attrs}"
    @update:logoClick="onLogoClick"
    @update:backBtnClick="onBackBtnClick"
    @update:cartBtnClick="onCartBtnClick"
    @update:setCartCurrency="setCartCurrency"
    v-if="variant == 1"
  />

  <!-- variant 2 -->
  <OneMenu2
    v-bind="{...$props, ...$attrs}"
    @update:logoClick="onLogoClick"
    @update:backBtnClick="onBackBtnClick"
    @update:cartBtnClick="onCartBtnClick"
    @update:setCartCurrency="setCartCurrency"
    v-else-if="variant == 2"
  />

  <!-- variant 3 -->
  <OneMenu3
    v-bind="{...$props, ...$attrs}"
    @update:logoClick="onLogoClick"
    @update:backBtnClick="onBackBtnClick"
    @update:cartBtnClick="onCartBtnClick"
    @update:setCartCurrency="setCartCurrency"
    v-else-if="variant == 3"
  />

  <!-- variant 4 -->
  <OneMenu4
    v-bind="{...$props, ...$attrs}"
    @update:logoClick="onLogoClick"
    @update:backBtnClick="onBackBtnClick"
    @update:cartBtnClick="onCartBtnClick"
    @update:setCartCurrency="setCartCurrency"
    v-else-if="variant == 4"
  />
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneMenu",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
  },
  emits: ["logo-click", "back-btn-click", "cart-btn-click", "set-cart-currency"],
  methods: {
    onLogoClick() {
      this.$emit("logo-click")
    },
    onBackBtnClick() {
      this.$emit("back-btn-click")
    },
    onCartBtnClick() {
      this.$emit("cart-btn-click")
    },
    setCartCurrency(v) {
      this.$emit("set-cart-currency", v)
    },
  },
}
</script>
