<template>
  <span class="label" v-if="text">{{ text }}</span>
</template>

<script type="text/javascript">
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";
span.label {
  font-size: 0.75rem;

  text-transform: uppercase;
  font-weight: bold;
  padding-left: 8px;
  color: @pfm-color-neutral-600;
}
</style>
