<template>
  <div class="one-element-widget-calendar" :class="[`mode-${mode}`]">
    <div v-if="mode == 'edit'">
      <!-- current month, year & arrows -->
      <div class="picked-date">
        <span class="month-year">{{ currentMonthAndYear }}</span>
        <a class="arrow left" href="javascript:" @click="prevMonth">
          <OneIcon icon="ChevronLeft" />
        </a>
        <a class="arrow right" href="javascript:" @click="nextMonth">
          <OneIcon icon="ChevronRight" />
        </a>
      </div>

      <!-- calendar -->
      <div class="view-grid">
        <!-- day labels -->
        <div class="day-names">
          <span class="day-name">Po</span>
          <span class="day-name">Út</span>
          <span class="day-name">St</span>
          <span class="day-name">Čt</span>
          <span class="day-name">Pá</span>
          <span class="day-name">So</span>
          <span class="day-name">Ne</span>
        </div>

        <!-- days -->
        <div class="days-wrap" v-if="modelValue">
          <div class="days">
            <div
              class="day-details empty"
              v-for="(day, index) in (0, currentViewPrevMonthDays)"
              :key="index"
            >
              <!-- Empty days from previous month -->
            </div>
            <div
              class="day-details"
              v-for="(day, index) in currentViewMonthDays"
              :key="index"
              :class="[
                {selected: selectedDate && selectedDate.getTime() == day.midnightDate.getTime()},
                {disabled: day.disabled},
              ]"
              @click="selectDate({date: day.midnightDate})"
            >
              <div class="day-number">{{ day.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mode == 'view'">
      {{ selectedDateString }}
    </div>
  </div>
</template>

<script>
import moment from "moment"
import localization from "moment/locale/cs"
moment.updateLocale("cs", localization)
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneWidgetCalendar",
  props: {
    mode: {
      type: String,
      default: "edit",
    },
    modelValue: {
      type: Date,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    currentView: {
      year: null,
      month: null,
    },
  }),
  computed: {
    currentMonthAndYear() {
      return moment(new Date(this.currentView.year, this.currentView.month, 0)).format("MMMM YYYY")
    },
    currentViewPrevMonthDays() {
      let tmpDate = new Date(this.currentView.year, this.currentView.month - 1, 0)
      // first day of week in current month
      let dayOfWeek = tmpDate.getDay()
      return dayOfWeek
    },
    currentViewMonthDays() {
      let days = []
      let currentMonth = new Date(this.currentView.year, this.currentView.month, 0)
      let countOfDaysInCurrentMonth = moment(currentMonth).daysInMonth()

      // console.log('countOfDaysInCurrentMonth', countOfDaysInCurrentMonth)
      for (var i = 0; i < countOfDaysInCurrentMonth; i++) {
        let number = i + 1
        let midnightDate = new Date(this.currentView.year, this.currentView.month - 1, number)
        let day = {
          number,
          midnightDate,
        }
        // append day's date
        days.push(day)
      }
      return days
    },
    selectedDate() {
      let date = null
      if (!this.modelValue) date = new Date()
      else date = this.modelValue
      let dateYear = date.getFullYear()
      let dateMonth = date.getMonth()
      let dateDay = date.getDate()
      let midnightDate = new Date(dateYear, dateMonth, dateDay)
      return midnightDate // new Date()
    },
    selectedDateString() {
      return moment(this.selectedDate).format("D. M. YYYY HH:mm")
    },
  },
  created() {
    this.setInitialMonth()
  },
  methods: {
    prevMonth() {
      // console.log('prevMonth')
      this.currentView.month = this.currentView.month - 1
    },
    nextMonth() {
      // console.log('nextMonth')
      this.currentView.month = this.currentView.month + 1
    },
    getDayName(date) {
      // return moment(date).format('dddd')
      return moment(date).calendar(null, {
        lastDay: "[včera]",
        sameDay: "[dnes]",
        nextDay: "[zítra]",
        nextWeek: "dddd",
        sameElse: "dddd",
      })
      // return this.dayNames[date.getDay()]
    },
    selectDate(v) {
      this.$emit("update:modelValue", v.date, {fieldId: this.componentUid})
    },
    setInitialMonth() {
      this.currentView.year = this.selectedDate.getFullYear()
      this.currentView.month = this.selectedDate.getMonth() + 1
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../variables.less";

.one-element-widget-calendar {
  padding-top: 20px;
  min-height: 160px;
  // border: 1px solid #ff0000;

  &.mode-view {
    min-height: initial;
    padding: 0 0 0 8px;
  }

  .picked-date {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;

    span.month-year {
      flex: 1;
      padding-bottom: 0;
      padding-left: 12px;
      font-size: 1.25rem;
      font-weight: bold;
      display: block;
    }

    a.view-toggle {
      padding-left: 20px;

      svg {
        width: 32px;
        height: 32px;
        transition: all 0.25s ease-in-out;

        &:hover {
          opacity: 0.5;
        }

        :deep(*) {
          fill: @pfm-color-neutral-600;
        }
      }
    }

    .arrow {
      padding: 0 15px;

      &.right {
        padding-right: 0;
      }

      svg {
        width: 28px;
        height: 28px;
        transition: all 0.25s ease-in-out;

        &:hover {
          opacity: 0.5;
        }

        :deep(*) {
          fill: @pfm-color-neutral-600;
        }
      }
    }
  }

  .view-grid {
    .picked-month {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      h3 {
        flex: 1;
      }

      .arrow {
        padding: 15px;

        &.right {
          padding-right: 0;
        }

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }

    .day-names {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 4px 4px;
      padding-bottom: 8px;

      span.day-name {
        user-select: none;
        text-align: center;
        font-weight: bold;
        font-size: 0.65rem;
        color: @pfm-color-neutral-500;
        text-transform: uppercase;
      }
    }

    .days-wrap {
      width: 100%;
      // overflow-x: scroll;
      // overflow-y: hidden;
      pointer-events: auto;
      // scrollbar-width: none;
      // position: absolute;
      // left: 0;
      // right: 0;
      // padding: 0 10px;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      .days {
        // display: flex;
        // flex-direction: row;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 4px 4px;
        margin-bottom: 20px;

        .day-details {
          padding: 6px 0;
          // min-width: 70px;
          // width: 50px;
          // height: 40px;
          border-radius: 7px;
          background-color: @pfm-color-neutral-300;
          // margin: 0 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          transition: background-color 0.5s ease;

          &.empty {
            visibility: hidden;
          }

          &:hover {
            background-color: @pfm-color-neutral-400;
          }

          &.selected {
            background-color: @pfm-color-neutral-700;

            .day-number {
              color: #ffffff;
            }
          }

          &.disabled {
            background-color: @pfm-color-neutral-100;

            .day-number {
              color: @pfm-color-neutral-400;
              cursor: not-allowed;
            }
          }

          .day-number {
            user-select: none;
            font-size: 1.2rem;
            color: @pfm-color-neutral-700;
            font-weight: 500;
          }
        }
      }
    }

    .selected-date {
      text-align: center;
      span {
        font-weight: bold;
        color: @pfm-color-neutral-700;
        font-size: 1rem;
      }
    }
  }
}
</style>
