<template>
  <div class="iframe-container" :data-one-component="$options.name" v-bind="props.root">
    <!-- video -->
    <iframe
      :src="src"
      ref="iframe"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      v-bind="props.iframe"
    ></iframe>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "oneVideoYoutube",
  mixins: [mixinClasses],
  props: {
    code: {
      type: String,
    },
    videoKind: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    mute: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
        propClass: {},
      },
      iframe: {
        class: "w-full h-auto",
        propClass: {},
      },
    },
  }),
  computed: {
    src() {
      let code = this.code
      let src = ""

      // params
      let autoplay = this.autoplay ? `autoplay=1` : `autoplay=0`
      let mute = this.mute ? `mute=1` : `mute=0`
      let controls = this.controls ? `controls=1` : `controls=0`
      let allowFullscreen = this.allowFullscreen ? `fs=1` : `fs=0`
      let loop = this.loop ? `loop=1` : `loop=0`
      let params = `${autoplay}&${mute}&${controls}&${allowFullscreen}&${loop}`

      if (this.videoKind == "video") {
        src = `https://youtube.com/embed/${code}?${params}`
      } else if (this.videoKind == "playlist") {
        src = `https://youtube.com/embed/videoseries?list=${code}&${params}`
      }
      return src
    },
  },
}
</script>

<style scoped lang="less">
// this only works for 16:9 videos. need to tweak this for other ratios
.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
