<!-- empty component used for adding products to cart via URL -->

<template>
  <section></section>
</template>

<script>
import {useAppStore} from "@/stores/app"
import {useShopCartStore} from "@/stores/shopCart"
import {useShopItemsStore} from "@/stores/shopItems"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "Add",

  setup() {
    // stores
    const appStore = useAppStore()
    const shopCartStore = useShopCartStore()
    const shopItemsStore = useShopItemsStore()
    const siteStore = useSiteStore()

    // states
    const {app} = storeToRefs(appStore)
    const {frontend: shopCartFrontend} = storeToRefs(shopCartStore)
    const {selected: shopItemsSelected} = storeToRefs(shopItemsStore)

    // getters
    const {shopItemsLoaded, selectedItemVariant: shopItemsSelectedItemVariant} =
      storeToRefs(shopItemsStore)
    const {hasShop: siteHasShop} = storeToRefs(siteStore)

    // actions
    const {itemAdd: shopCartItemAdd} = shopCartStore
    const {setSelectedItemBySKU: shopItemsSetSelectedItemBySKU} = shopItemsStore

    return {
      app,
      siteHasShop,
      shopCartFrontend,
      shopCartItemAdd,
      shopItemsLoaded,
      shopItemsSetSelectedItemBySKU,
      shopItemsSelected,
      shopItemsSelectedItemVariant,
    }
  },
  watch: {
    shopItemsLoaded: {
      handler() {
        if (this.siteHasShop && this.shopItemsLoaded && this.$route.params.productSKU) {
          // console.log('watch: productSKU', this.$route.params.productSKU)
          this.setProductDetail()
        }
      },
    },
  },
  computed: {
    mode() {
      return this.app.mode
    },
  },
  created() {
    this.shopCartFrontend.showBackToStoreBtn(false)
    if (this.shopItemsLoaded) this.setProductDetail() // if not, it should fire from watcher
  },
  updated() {
    this.setProductDetail()
  },
  methods: {
    setProductDetail() {
      if (!this.siteHasShop) return
      // console.log('page: setProductDetail()')
      this.shopItemsSetSelectedItemBySKU(this.$route.params.productSKU)

      // add item to cart
      this.shopCartItemAdd({
        id: this.shopItemsSelected.variantId,
        snapshot: this.shopItemsSelectedItemVariant,
        open: true,
      })
    },
  },
}
</script>
