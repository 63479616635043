<template>
  <div
    class="one-element-button-plusminus flex flex-row rounded-md transition-all duration-500 ease-in-out cursor-pointer border border-neutral-500 w-32"
  >
    <OneIcon
      class="w-1/2 border-r border-neutral-300"
      color="primary"
      icon="Minus"
      size="small"
      key="minus"
      @update:click="subtract"
    />
    <OneIcon
      class="w-1/2"
      color="primary"
      icon="Plus"
      size="small"
      key="plus"
      @update:click="add"
    />
  </div>
</template>

<script>
export default {
  name: "OneButtonPlusMinus",
  emits: ["add", "subtract"],
  methods: {
    add() {
      this.$emit("add")
    },
    subtract() {
      this.$emit("subtract")
    },
  },
}
</script>

<style type="less">
.one-element-button-plusminus {
  padding-top: 11px;
  padding-bottom: 11px;
}
</style>
