import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {auth} from "@/firebase/index.js"
import {onAuthStateChanged} from "firebase/auth"
import {useShopCustomerStore} from "./shopCustomer"

export const useUserStore = defineStore("user", () => {
  // stores
  const shopCustomerStore = useShopCustomerStore()

  // state
  const user = ref({}) // firebase auth

  // getters
  const userId = computed(() => user.value.uid)
  const userSignedIn = computed(() => user.value && user.value.uid)

  // actions
  function listenAuth() {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        console.log("auth: user signed in", firebaseUser)
        user.value = firebaseUser
        shopCustomerStore.bind({customerId: firebaseUser.uid})
      } else {
        console.log("auth: no user signed in")
        user.value = {}
      }
    })
  }

  return {
    // state
    user,

    // getters
    userId,
    userSignedIn,

    // actions
    listenAuth,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
