import {DocumentReference, CollectionReference, Query, onSnapshot} from "firebase/firestore"

function refsToPaths(o) {
  Object.keys(o).forEach(function (k) {
    if (o[k] !== null && typeof o[k] === "object" && !(o[k] instanceof DocumentReference)) {
      refsToPaths(o[k])
      return
    }
    if (o[k] instanceof DocumentReference) {
      // TODO: ? this is where sub refs would be bound if i decide to. but i don't think it's a good idea for efficiency reasons
      // console.log('pinia: subscribe to docRef:', o[k].path)
      // console.log('pinia: DocumentReference:', o[k].path)
      // if (typeof o !== 'string' && o.isArray()) {
      //   console.log(...o)
      // } else {
      //   console.log(o)
      // }
      o[k] = o[k].path
    }
  })
}

export const attachFirestore = ({store}) => {
  store.attach = async (key, firestoreRef, customSerializer) => {
    // console.log('attach:', {key, firestoreRef, customSerializer})
    // don't bind again if already bound
    if (store.status !== "init") return

    return new Promise((resolve, reject) => {
      // if (store.status !== "init") {
      //   // console.warn("pinia: already bound:", firestoreRef.path)
      //   return //reject()
      // }

      store.$patch((state) => (state.status = "loading"))
      store.$subscribe((mutation, state) => {
        // if (mutation.storeId == "shopItems") {
        //   console.log("pinia: subscribe:", mutation, JSON.stringify(state))
        // }
        if (state.status == "loaded") {
          resolve({storeId: store.$id, status: "loaded"})
        }
      })

      let unsubscribe
      try {
        // console.log("pinia: calling onSnapshot", firestoreRef)
        unsubscribe = onSnapshot(firestoreRef, (snapshot) => {
          // console.log("onsnapshot:", firestoreRef, snapshot)
          // Document
          if (firestoreRef instanceof DocumentReference) {
            if (!snapshot.exists()) {
              console.error("document does not exist:", firestoreRef.path)
              return
            }

            const data = snapshot.data()

            if (customSerializer) {
              customSerializer(snapshot, data)
            } else {
              Object.defineProperty(data, "id", {
                value: firestoreRef.id,
                writable: false,
                enumerable: false,
              })
            }

            refsToPaths(data)

            store.$patch((state) => {
              // console.log("pinia: patching data", JSON.stringify(data))
              state[key] = data
              state.status = "loaded"
            })
          }

          // Collection or Query
          else if (firestoreRef instanceof CollectionReference || firestoreRef instanceof Query) {
            // console.log("query:", store.$id)
            // console.log("docs count:", snapshot.docs.length)

            // if query returns empty result, set status loaded
            if (snapshot.docs.length === 0) {
              store.$patch((state) => {
                state.status = "loaded"
              })
            }

            snapshot.docChanges().forEach((change, index) => {
              let proceedWithItem = true // if customSerializer returns false, item should be filtered out
              // console.log("docChange()", {change, index})
              // added
              if (change.type === "added") {
                const data = change.doc.data()

                // console.log('new item added to firestore', {app: import.meta.env.VITE_APP, store: store.$id, key, change, data})

                if (customSerializer) {
                  proceedWithItem = customSerializer(change.doc, data)
                } else {
                  Object.defineProperty(data, "id", {
                    value: change.doc.id,
                    writable: false,
                    enumerable: false,
                  })
                }
                refsToPaths(data)

                store.$patch((state) => {
                  // state[key].push(data)
                  if (proceedWithItem) {
                    state[key].splice(change.newIndex, 0, data)
                  }
                })

                // last doc
                if (index === snapshot.docs.length - 1) {
                  // console.log("last doc")
                  store.$patch((state) => {
                    // only for initial load, not each update
                    if (state.status === "loading") {
                      state.status = "loaded"
                    }
                  })
                }
              }

              // modified
              if (change.type === "modified") {
                const data = change.doc.data()

                // console.log("modified:", data)

                if (customSerializer) {
                  proceedWithItem = customSerializer(change.doc, data)
                } else {
                  Object.defineProperty(data, "id", {
                    value: change.doc.id,
                    writable: false,
                    enumerable: false,
                  })
                }

                refsToPaths(data)

                store.$patch((state) => {
                  if (proceedWithItem) {
                    const index = state[key].findIndex((i) => i.id === change.doc.id)
                    state[key][index] = data
                  }
                })
              }

              // removed
              if (change.type === "removed") {
                store.$patch((state) => {
                  const index = state[key].findIndex((i) => i.id === change.doc.id)
                  state[key].splice(index, 1)
                })
              }
            })
          }

          return unsubscribe
        })

        store.$patch((state) => (state.unsubscribe = unsubscribe))
      } catch (error) {
        console.error(error)
        store.$patch((state) => (state.status = "error"))
        reject({storeId: store.$id, status: "error"})
      }
      return
    })
  }

  store.detach = (args) => {
    // console.log("store.detach:", args)
    return new Promise((resolve) => {
      store.$subscribe((mutation, state) => {
        // console.log("pinia: subscribe:", mutation, JSON.stringify(state.loadingState))
        if (state.status == "init") {
          resolve()
        }
      })

      if (typeof store.unsubscribe === "function") {
        store.unsubscribe()
      }

      if (args && Object.hasOwn(args, "reset") && args.reset === false) {
        console.log("reset is false -> resolving right away")
        resolve()
      } else {
        store.$reset()
      }
    })
  }
}
