<!-- empty component used for adding products to cart via URL -->

<template>
  <div class="text-center p-4">
    <a href="/">go to homepage</a><br />
    ---<br />
    <a href="#" @click="resetCart">reset cart</a>
  </div>
</template>

<script>
export default {
  name: "Debug",
  methods: {
    resetCart() {
      localStorage.removeItem("@platformaone/store/commerce/cart")
      location.reload()
    },
  },
}
</script>
