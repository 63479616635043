<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container" :style="{height}">
      <div
        v-bind="props.line"
        :style="{height: thickness, width, borderBottomWidth: thickness}"
      ></div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneSeparator",
  mixins: [mixinClasses],
  props: {
    height: {
      type: String,
      default: "1vh",
    },
    thickness: {
      type: String,
      default: "1px",
    },
    width: {
      type: String,
      default: "100%",
    },
    align: {
      type: String,
      default: "center",
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        root: {
          style: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        },
      },
      line: {
        class: "w-1/2 border-primary",
      },
    },
  }),
}
</script>
