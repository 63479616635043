// The Shopping Cart calculation utility
function calculate(cartObj) {
  var curr = cartObj.currency

  // console.log('Calculating for currency:', curr)

  var total = {
    type: "total",
    vat: 0,
    vat_pct: null,
    netto: 0,
    brutto: 0,
    currency: curr,
  }

  let result = []

  try {
    // console.log('commerce-calc: Cart Obj request body', cartObj)
    // First calculate products
    if (cartObj.items) {
      // console.log('Starting calculation', cartObj.products)

      // First calculate and cummulate products per VAT
      let productsPerVatMap = {}
      // Also make a list of prices for product, for case the user wants more peaces of one item
      let productsPerSKU = {}

      for (let i = 0; i < cartObj.items.length; i++) {
        let prod = cartObj.items[i]
        // console.log(`Calculating product index: ${i} from count: ${prod.count}`)
        // console.log(prod.snapshot)

        // Get proper price definition for given count
        let prodPrice = null
        let price = null
        let priceKind = prod.snapshot.config.priceKind
        if (priceKind == "package") {
          price = prod.snapshot.price.find((p) => p.currency == curr && p.model == "package" && p.units == prod.count)
        } else if (priceKind == "voluntary") {
          price = prod.snapshot.price.find((p) => p.currency == curr && p.model == "voluntary")
        } else if (priceKind == "fixed") {
          price = prod.snapshot.price.find((p) => p.currency == curr && p.model == "fixed")
        }

        if (!price) {
          console.error("price not found")
          return
        }
        prodPrice = correctPrice(price)

        if (!Object.hasOwn(productsPerVatMap, "vat" + prodPrice.vat_pct)) {
          productsPerVatMap["vat" + prodPrice.vat_pct] = {
            type: "products",
            vat: 0,
            vat_pct: prodPrice.vat_pct,
            netto: 0,
            brutto: 0,
            currency: curr,
          }
        }
        if (!Object.hasOwn(productsPerSKU,"SKU" + prod.snapshot.sku)) {
          productsPerSKU["SKU" + prod.snapshot.sku] = {
            type: "product",
            sku: prod.snapshot.sku,
            vat: 0,
            vat_pct: prodPrice.vat_pct,
            netto: 0,
            brutto: 0,
            currency: curr,
          }
        }
        // var vat = prodPrice.brutto * (prodPrice.vat_pct / 100)
        // var netto = prodPrice.brutto - vat
        productsPerVatMap["vat" + prodPrice.vat_pct].vat =
          productsPerVatMap["vat" + prodPrice.vat_pct].vat + prodPrice.vat * prod.count
        productsPerVatMap["vat" + prodPrice.vat_pct].netto =
          productsPerVatMap["vat" + prodPrice.vat_pct].netto + prodPrice.netto * prod.count
        productsPerVatMap["vat" + prodPrice.vat_pct].brutto =
          productsPerVatMap["vat" + prodPrice.vat_pct].brutto + prodPrice.brutto * prod.count

        productsPerSKU["SKU" + prod.snapshot.sku].vat =
          productsPerSKU["SKU" + prod.snapshot.sku].vat + prodPrice.vat * prod.count
        productsPerSKU["SKU" + prod.snapshot.sku].netto =
          productsPerSKU["SKU" + prod.snapshot.sku].netto + prodPrice.netto * prod.count
        productsPerSKU["SKU" + prod.snapshot.sku].brutto =
          productsPerSKU["SKU" + prod.snapshot.sku].brutto + prodPrice.brutto * prod.count
      }

      // Output products per VAT into result
      let keys = Object.keys(productsPerVatMap)
      for (let i = 0; i < keys.length; i++) {
        let prod = productsPerVatMap[keys[i]]
        result.push({
          type: "products",
          vat_pct: prod.vat_pct,
          vat: prod.vat,
          netto: prod.netto,
          brutto: prod.brutto,
          currency: curr,
        })

        total = addTo(total, prod)
      }

      // Output products per SKU into result
      keys = Object.keys(productsPerSKU)
      for (let i = 0; i < keys.length; i++) {
        let prod = productsPerSKU[keys[i]]
        result.push({
          type: "product",
          sku: prod.sku,
          vat_pct: prod.vat_pct,
          vat: prod.vat,
          netto: prod.netto,
          brutto: prod.brutto,
          currency: curr,
        })
      }
    }

    // Discount
    let discount = 0
    if (cartObj.promoCode && cartObj.promoCode.snapshot) {
      let codeSnapshot = cartObj.promoCode.snapshot
      if (codeSnapshot.unit == "value") {
        // The discount amount has exact value
        discount = codeSnapshot.valueRemaining ? codeSnapshot.valueRemaining : codeSnapshot.value

        // discount can't exceed the order value
        if (total.brutto < discount) discount = total.brutto
      } else if (codeSnapshot.unit == "percent") {
        // The discount amount is calulated from the price base.
        discount = (codeSnapshot.value / 100) * total.brutto
      }

      let discountPrice = correctPrice({
        type: "discount",
        unit: codeSnapshot.unit,
        vat_pct: total.vat_pct,
        brutto: discount * -1,
        currency: curr,
      })
      result.push(discountPrice)

      // Alter the total price value
      total.brutto = total.brutto + discountPrice.brutto
      total.netto = total.netto + discountPrice.netto
      if (total.brutto < 0) total.brutto = 0
      if (total.netto < 0) total.netto = 0
    }

    // Add packing
    // Delivery
    if (cartObj.delivery && cartObj.delivery.price) {
      let deliveryPrice = correctPrice(cartObj.delivery.price)
      // console.log('Delivery', JSON.stringify(deliveryPrice))
      deliveryPrice.type = "delivery"
      result.push(deliveryPrice)
      total = addTo(total, deliveryPrice)
    }

    // Payment
    if (cartObj.payment && cartObj.payment.method && cartObj.payment.method.price) {
      let price = cartObj.payment.method.price.find((p) => p.currency == curr && p.model == "fixed")
      // console.log('PRICE', price)
      let paymentPrice = correctPrice(price)
      // console.log('Payment', JSON.stringify(paymentPrice))
      paymentPrice.type = "payment"
      result.push(paymentPrice)
      total = addTo(total, paymentPrice)
    }

    // Provision

    // And thesummary is...
    // total = correctPrice(total)
    result.push(total)

    // Round values
    result.forEach((pricePart) => {
      round(pricePart)
    })
    // console.log('Calculation done', JSON.stringify(result))
    result.state = "ok"
    return result
  } catch (err) {
    console.error("error", err)
    return {
      status: "error",
      msq: "Unexpected exception",
    }
  }
}

function round(price) {
  if (price.netto && !isNaN(price.netto)) {
    price.netto = Math.round(price.netto * 100) / 100
  }
  if (price.brutto && !isNaN(price.brutto)) {
    price.brutto = Math.round(price.brutto * 100) / 100
  }
  if (price.vat && !isNaN(price.vat)) {
    price.vat = Math.round(price.vat * 100) / 100
  }
}

function correctPrice(price) {
  let usedVatPct = !isNaN(price.vat_pct) ? price.vat_pct : 21
  var vat = ((price.brutto !== null ? price.brutto : price.incl_vat) / (usedVatPct + 100)) * usedVatPct
  var netto = (price.brutto !== null ? price.brutto : price.incl_vat) - vat

  return {
    type: price.type,
    brutto: price.brutto !== null ? price.brutto : price.incl_vat,
    netto: netto,
    vat_pct: usedVatPct === undefined || usedVatPct == null ? null : usedVatPct,
    vat: vat,
    currency: price.currency === undefined || price.currency == null ? null : price.currency,
  }
}

function addTo(total, price) {
  // TODO here we should check the currencies and we should make a transfer to base currency in case they differ.
  // console.log('>> Adding to', JSON.stringify(total), JSON.stringify(price))

  let result = {
    type: total.type,
    brutto: total.brutto * 1 + price.brutto * 1,
    netto: total.netto * 1 + price.netto * 1,
    vat_pct: valueOrNA(total.vat_pct, price.vat_pct),
    vat: total.vat * 1 + price.vat * 1,
    currency: valueOrNA(total.currency, price.currency),
  }

  // console.log('== ', JSON.stringify(result))
  return result
}

function valueOrNA(a, b) {
  if (a != null && a == b) {
    return a // They are the same and not NULL
  } else if (a != null && b != null) {
    return "N/A" // They are not NULL, but different
  } else if (a != null) {
    return a // The A is not NULL
  } else {
    return b // The B is not NULL
  }
}

export {calculate}
