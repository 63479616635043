<template>
  <div class="block-selector">
    <div class="selector-container">
      <h3 v-localize="{key: 'sections'}"></h3>

      <!-- sections: main -->
      <h4 v-localize="{key: 'section-main'}"></h4>
      <div class="blocks">
        <div
          class="block"
          v-for="section in sectionsMain"
          :key="section.name"
          @click="addBlock(section.kind)"
        >
          <div class="icon">
            <OneIcon :icon="section.name" />
          </div>
          <div class="title">
            <span v-localize="{key: section.name}">{{ section.name }}</span>
          </div>
        </div>
      </div>

      <!-- sections: commerce -->
      <div v-if="hasCommerce">
        <h4 v-localize="{key: 'section-commerce'}"></h4>
        <div class="blocks">
          <div
            class="block"
            v-for="section in sectionsCommerce"
            :key="section.name"
            @click="addBlock(section.kind)"
          >
            <div class="icon">
              <OneIcon :icon="section.name" />
            </div>
            <div class="title">
              <span v-localize="{key: section.name}">{{ section.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- sections: shared -->
      <div class="shared-blocks" v-if="sharedBlocks && sharedBlocks.length > 0">
        <h4 v-localize="{key: 'section-shared'}"></h4>
        <div class="blocks">
          <div
            class="block"
            v-for="block in sharedBlocks"
            @click="addBlockShared(block.id)"
            :key="block.id"
          >
            <div class="icon">
              <OneIcon :icon="getSharedBlockName(block.nativeBlock)" />
            </div>
            <div class="title">
              <span>{{ block.config.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- sections: custom -->
      <div class="custom-blocks" v-if="customBlocks && customBlocks.length > 0">
        <h4 v-localize="{key: 'section-custom'}"></h4>
        <div class="blocks">
          <div
            class="block"
            v-for="block in customBlocks"
            @click="addBlockCustom(block)"
            :key="block.id"
          >
            <div class="icon">
              <!-- TODO: custom section icon -->
              <!-- <OneIcon icon="custom-block" /> -->
            </div>
            <div class="title">
              <span>{{ getCustomBlockName(block) }}</span>
            </div>
          </div>
        </div>
      </div>

      <span class="cancel" @click="cancel()">cancel</span>
    </div>
  </div>
</template>

<script>
import {useAdminStore} from "@/stores/admin"
import {useAppStore} from "@/stores/app"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "pfmBlockSelector",
  props: {
    pageId: {
      type: String,
    },
  },
  setup() {
    // stores
    const adminStore = useAdminStore()
    const appStore = useAppStore()
    const siteStore = useSiteStore()

    // states
    const {admin} = storeToRefs(adminStore)
    const {app} = storeToRefs(appStore)
    const {siteVar} = storeToRefs(siteStore)
    const {siteVarFetched} = storeToRefs(siteStore)

    // getters
    const {pages: sitePages} = storeToRefs(siteStore)

    // actions
    const {blockAdded: adminBlockAdded} = adminStore

    return {
      app,
      siteVar,
      siteVarFetched,
      sitePages,
      admin,
      adminBlockAdded,
    }
  },
  data: () => ({
    kind: null,
    defaultClasses: {},
    sections: [
      {
        kind: "main/hero",
        name: "section-main-hero",
      },
      {
        kind: "main/header",
        name: "section-main-header",
      },
      {
        kind: "main/textWithHeader",
        name: "section-main-headerText",
      },
      {
        kind: "main/imageText",
        name: "section-main-imageText",
      },
      {
        kind: "main/text",
        name: "section-main-text",
      },
      {
        kind: "main/gallery",
        name: "section-main-gallery",
      },
      {
        kind: "main/instagram",
        name: "section-main-instagram",
      },
      {
        kind: "main/singleImage",
        name: "section-main-image",
      },
      {
        kind: "main/team",
        name: "section-main-team",
      },
      {
        kind: "main/singleVideo",
        name: "section-main-video",
      },
      {
        kind: "main/cards",
        name: "section-main-cards",
      },
      {
        kind: "main/button",
        name: "section-main-button",
      },
      {
        kind: "main/menu",
        name: "section-main-menu",
      },
      {
        kind: "main/gridList",
        name: "section-main-gridList",
      },
      {
        kind: "main/logoCloud",
        name: "section-main-logoCloud",
      },
      {
        kind: "main/popup",
        name: "section-main-popup",
      },
      {
        kind: "main/spacer",
        name: "section-main-space",
      },
      {
        kind: "main/separator",
        name: "section-main-separator",
      },
      {
        kind: "main/footer",
        name: "section-main-footer",
      },

      // commerce
      {
        kind: "commerce/productDetail",
        name: "section-commerce-productDetail",
      },
      {
        kind: "commerce/productListing",
        name: "section-commerce-productListing",
      },
      {
        kind: "commerce/productImages",
        name: "section-commerce-productImages",
      },
      {
        kind: "commerce/productPriceSlider",
        name: "section-commerce-productPriceSlider",
      },
    ],
  }),
  computed: {
    sectionsMain() {
      return this.sections.filter((s) => s.kind.startsWith("main"))
    },
    sectionsCommerce() {
      return this.sections.filter((s) => s.kind.startsWith("commerce"))
    },
    mode() {
      return this.app.mode
    },
    hasCommerce() {
      return (this.siteVarFetched && this.siteVar.shopId) || (window.site && window.site.shopId)
    },
    pageIndex() {
      return this.pages.findIndex((p) => p.id == this.pageId)
    },
    pages() {
      return this.sitePages
    },
    pageBlocks() {
      return this.pages[this.pageIndex].blocks
    },
    sharedBlocks() {
      let sb = []
      this.pages.forEach((p) => {
        // console.log('sb', p.config.pageName.cs, p.blocks)
        p.blocks.forEach((b) => {
          // console.log('sb2 ', sb, sb.findIndex(blck => blck.id == b.id))
          if (b.config.shared) {
            if (sb.findIndex((blck) => blck.id == b.id) == -1) {
              // do not include duplicates
              sb.push(b)
            }
          }
        })
      })
      // console.log('sb2: blocks', sb)
      return sb
    },
    customBlocks() {
      let templates = this.siteVar.customBlocks.templates
      return templates
    },
  },
  methods: {
    getSharedBlockName(blockKind) {
      let sb = this.sections.find((s) => s.kind == blockKind)
      return sb ? sb.name : ""
    },
    getCustomBlockName(block) {
      return block.name[this.admin.lang]
    },
    addBlock(blockKind) {
      if (this.mode != "admin") return
      // console.log('addBlock():', blockKind, this.pageId)

      window.parent.postMessage(
        {
          action: "addBlock",
          blockKind,
          pageId: this.pageId,
          newBlockIndex: this.getNewBlockIndex(),
        },
        "*"
      )
      this.adminBlockAdded()
    },
    addBlockShared(blockId) {
      if (this.mode != "admin") return
      // console.log('addBlockShared():', blockId, this.pageId)
      window.parent.postMessage(
        {
          action: "addBlockShared",
          blockId,
          pageId: this.pageId,
          newBlockIndex: this.getNewBlockIndex(),
        },
        "*"
      )
      this.adminBlockAdded()
    },
    addBlockCustom(block) {
      // console.log('addBlockCustom', block.id, block)
      if (this.mode != "admin") return
      window.parent.postMessage(
        {
          action: "addBlockCustom",
          blockKind: "main/custom",
          blockTemplate: block,
          pageId: this.pageId,
          newBlockIndex: this.getNewBlockIndex(),
        },
        "*"
      )
      this.adminBlockAdded()
    },
    getNewBlockIndex() {
      if (this.admin.newBlockPosition == "above") return this.admin.referenceBlockIndex
      if (this.admin.newBlockPosition == "below") return this.admin.referenceBlockIndex + 1
    },
    cancel() {
      this.admin.addingBlock = false
    },
  },
}
</script>

<style lang="less" scoped>
@import "../variables.less";

.block-selector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #ffffff;
  z-index: 9999;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow-y: scroll;
  padding: 30px 0 50px;

  .selector-container {
    text-align: center;

    h3,
    h4 {
      text-align: center;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 1rem;
    }

    .blocks {
      padding-top: 5px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        transition: all 0.25s ease-in-out;

        &:hover {
          cursor: pointer;
          background: @pfm-color-neutral-200;
        }

        svg {
          width: 165px;
          height: 90px;
        }

        span {
          padding: 4px 0 0 0;
          color: @pfm-color-neutral-500;
          font-size: 0.85rem;
        }
      }
    }

    span.cancel {
      display: inline-block;
      font-weight: bold;
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 8px;
      color: @pfm-color-neutral-100;
      background: @pfm-color-neutral-600;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        background: @pfm-color-neutral-400;
      }
    }
  }
}
</style>
