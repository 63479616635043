import {getApp, getApps, initializeApp} from "firebase/app"
import {initializeFirestore, connectFirestoreEmulator} from "firebase/firestore"
import {getFunctions, connectFunctionsEmulator} from "firebase/functions"
import {getAuth, connectAuthEmulator} from "firebase/auth"
import {getAnalytics, isSupported as isSupportedAnalytics} from "firebase/analytics"

const useEmulator = import.meta.env.VITE_USE_EMULATOR === "true" || false

// config
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

const firestoreConfig = {
  ignoreUndefinedProperties: true,
  // experimentalAutoDetectLongPolling: true,
  experimentalForceLongPolling: true,
  merge: true,
}

// init
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
const firestore = initializeFirestore(app, firestoreConfig)
const functions = getFunctions(app, "europe-west3")
const auth = getAuth(app)
const analytics = useEmulator
  ? false
  : (async () => {
      try {
        const isSupportedBrowser = await isSupportedAnalytics()
        if (isSupportedBrowser) {
          return getAnalytics(app)
        }
        console.log("Firebase analytics is not supported this browser")
        return null
      } catch (err) {
        console.log(err)
        return null
      }
    })()

// emulator
if (import.meta.env.VITE_ENVIRONMENT == "local_dev" && useEmulator) {
  console.log("using emulator: functions, firestore, auth")

  let emulatorConfig = {
    firestore: {
      host: "127.0.0.1",
      port: 4406,
    },
    functions: {
      host: "127.0.0.1",
      port: 4405,
    },
    auth: {
      url: "http://127.0.0.1:4404",
    },
  }

  // ngrok
  const ngrok = import.meta.env.VITE_NGROK === "true"
  if (ngrok) {
    emulatorConfig.firestore.host = "pfm-fbs-firestore.eu.ngrok.io"
    emulatorConfig.firestore.port = 443

    emulatorConfig.functions.host = "https://pfm-fbs-functions.eu.ngrok.io"
    emulatorConfig.functions.port = 443

    emulatorConfig.auth.url = "http://pfm-fbs-auth.eu.ngrok.io"
  }

  connectFirestoreEmulator(firestore, emulatorConfig.firestore.host, emulatorConfig.firestore.port)
  connectFunctionsEmulator(functions, emulatorConfig.functions.host, emulatorConfig.functions.port)
  connectAuthEmulator(auth, emulatorConfig.auth.url, {disableWarnings: true})
}

export {firestore, functions, auth, analytics}
