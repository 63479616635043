<template>
  <div
    :data-one-component="$options.name"
    :style="{marginTop: `${paddingTop}px`}"
    v-bind="props.root"
  >
    <!-- card -->
    <!-- <a :href="props.link.href"
      data-one-label="cardWrap"
      v-bind="props.link"
      > -->

    <!-- <div v-html="svgString"></div> -->

    <OneLink
      ref="link"
      :style="{height: `${width}px`}"
      v-bind="props.link"
      :disabled="linkDisabled"
    >
      <div
        class="w-full h-full flex-center absolute z-10"
        :style="`clip-path: url(#svg-clip-${componentUid}-${counter})`"
      >
        <!-- image & caption wrap -->
        <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
          <!-- logo -->
          <OneImage v-bind="props.logo" v-if="props.logo && props.logo.src" />

          <!-- heading -->
          <OneHeading
            v-bind="props.heading"
            :color="headingColor"
            v-if="props.heading && props.heading.content"
          />

          <!-- caption -->
          <OneParagraph
            v-bind="props.caption"
            :color="captionColor"
            v-if="props.caption && props.caption.content"
          />
        </div>
      </div>

      <!-- background image -->
      <OneImage
        class="background-image"
        :style="`clip-path: url(#svg-clip-${componentUid}-${counter})`"
        v-bind="props.backgroundImage"
        v-if="props.backgroundImage && props.backgroundImage.src"
        :preload="true"
      />

      <!-- same for text mask -->
      <div class="w-full h-full flex-center absolute" style="z-index: -1">
        <!-- image & caption wrap -->
        <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
          <!-- logo -->
          <OneImage v-bind="props.logo" v-if="props.logo && props.logo.src" />

          <!-- heading -->
          <OneHeading
            v-bind="props.heading"
            :color="headingColor2"
            v-if="props.heading && props.heading.content"
          />

          <!-- caption -->
          <OneParagraph
            v-bind="props.caption"
            :color="captionColor2"
            v-if="props.caption && props.caption.content"
          />
        </div>
      </div>
    </OneLink>

    <!-- svg blob clip mask -->
    <svg class="svg-clip-container" width="0" height="0" xmlns="http:/www.w3.org/2000/svg">
      <defs>
        <clipPath :id="`svg-clip-${componentUid}-${counter}`">
          <path stroke="none" stroke-width="0" fill="black" :d="svgPath"></path>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

// https://github.com/g-harel/blobs
// TODO: preserve image aspect ratio
// https://stackoverflow.com/questions/28047792/html-canvas-scaling-image-to-fit-without-stretching
import * as blobs2 from "blobs/v2"
// import * as blobs2Animate from 'blobs/v2/animate'
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneCard3",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    cardCount: {
      type: Number,
      default: 1,
    },
    headingColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    headingColor2: "black",
    captionColor2: "black",
    elementWidth: 100,
    svgPath: "",
    counter: 0,
    defaults: {
      commonClasses: "",
      root: {
        propClass: {
          cardCount: {
            _common: "w-full",
            // _common: "inline-block",
            // "1": "",
            // "2": "md:w-1/2 md:first:pr-2 md:last:pl-2",
            // "3": "md:w-1/3 md:first:pr-2 md:last:pl-2",
            // "4": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "5": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "6": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "7": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "8": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "9": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "10": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "11": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "12": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "13": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "14": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "15": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "16": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "17": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "18": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "19": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            // "20": "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
          },
        },
      },
      link: {
        class:
          "z-10 relative overflow-hidden w-full flex-center rounded-lg hover:opacity-75 transition-all duration-300 ease-in-out",
        style: {
          // height: "50vh",
        },
      },
      backgroundImage: {
        class: "absolute inset-0",
        maxWidth: "full",
        minHeight: "full",
      },
      logoCaptionWrap: {
        class: "relative z-10 flex flex-col",
        // style: {
        //   width: '20%'
        // }
      },
      logo: {
        fit: "contain",
      },
      caption: {
        align: "center",
        color: "white",
        class: "whitespace-nowrap",
      },
    },
  }),
  watch: {
    viewportWidth() {
      this.elementWidth = this.$refs.link.$el.getBoundingClientRect().width
      // this.svgPath = this.getSvgPath()
    },
  },
  computed: {
    paddingTop() {
      // if (this.index == 0) {
      //   return this.width
      // } else {
      return 0
      // }
    },
    viewportWidth() {
      if (this.$pinia?.state?.value?.app?.app?.viewport?.width) {
        return this.$pinia.state.value.app.app.viewport.width
      } else {
        return false
      }
    },
    cardWidth() {
      // if (this.$refs && this.$refs.link) {
      //   return this.$refs.link.$el.getBoundingClientRect().width
      // } else {
      //   return 100
      // }
      return this.elementWidth
    },
    width() {
      return this.cardWidth
    },
    linkDisabled() {
      return this.props.link.href ||
        this.props.link.url ||
        this.props.link.pageId ||
        (this.props.link.path && this.props.link.path.length)
        ? false
        : true
    },
  },
  created() {},
  mounted() {
    this.elementWidth = this.$refs.link.$el.getBoundingClientRect().width
    this.svgPath = this.getSvgPath()
    this.updatePath()
  },
  methods: {
    updatePath() {
      // console.log("updating path")
      this.svgPath = this.getSvgPath()
      this.counter = this.counter + 1

      // change shape
      // setTimeout(() => {
      //   this.updatePath()
      // }, 5000)
    },
    getSvgPath() {
      return blobs2.svgPath(
        {
          seed: Math.random(),
          extraPoints: 4,
          randomness: 4,
          size: this.width,
        },
        {
          fill: "black", // 🚨 NOT SANITIZED
          // stroke: "black", // 🚨 NOT SANITIZED
          // strokeWidth: 4,
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.background-image {
  transition: 0.4s cubic-bezier(1, -1, 0, 2);
}
</style>
