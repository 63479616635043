<template>
  <div class="one-element-indicator-save" :class="`state-${state}`">
    <div class="saving">
      <OneLoading size="s" />
    </div>

    <div class="saved">
      <OneIcon icon="Tick" class="icon-tick" />
    </div>

    <div class="error">
      <OneIcon icon="X" color="danger" size="xs" class="icon-x" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OneSave",
  props: {
    state: {
      type: String,
      default: "init", // init | saving | saved | error
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-indicator-save {
  position: relative;
  pointer-events: none;

  &.state-saving {
    .saving {
      opacity: 1;
    }
  }

  &.state-saved {
    .saved {
      opacity: 1;
    }
  }

  &.state-error {
    .error {
      opacity: 1;
    }
  }

  .saving,
  .saved,
  .error {
    transition: all 0.25s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }

  .saved {
    // width: 22px;
    // height: 22px;
    width: 28px;
    height: 28px;
    display: block;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: @pfm-color-success;

    svg.icon-tick {
      :deep(*) {
        // fill: @pfm-color-success-dark;
        fill: @pfm-color-success;
      }
    }
  }
}
</style>
