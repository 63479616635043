import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, query, where} from "firebase/firestore"
import {useShopCartStore} from "@/stores/shopCart"
import moment from "moment"

export const useShopDeliverySlotsStore = defineStore("shopDeliverySlots", () => {
  // stores
  const shopCartStore = useShopCartStore()

  // state
  const slots = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopDeliverySlotsLoaded = computed(() => status.value == "loaded")
  const deliverySlots = computed(() => {
    const cartItemsSelectedDeliveryMethodOptions = shopCartStore.itemsSelectedDeliveryMethodOptions
    console.log(
      "getSlots(): cartItemsSelectedDeliveryMethodOptions:",
      cartItemsSelectedDeliveryMethodOptions
    )
    const currentTime = new Date()
    let slotsReturned = []
    slots.value.forEach((s) => {
      let constrained = false
      if (cartItemsSelectedDeliveryMethodOptions.minShippingMin) {
        let slotDateTo = s.dateTo.toDate()
        let minShippingDate = moment(currentTime)
          .add(cartItemsSelectedDeliveryMethodOptions.minShippingMin, "minutes")
          .toDate()
        // console.log(slotDateTo, minShippingDate)
        if (slotDateTo.getTime() < minShippingDate.getTime()) constrained = true
      }
      if (cartItemsSelectedDeliveryMethodOptions.shippingDateFrom) {
        let slotDateTo = s.dateTo.toDate()
        let shippingDateFrom = new Date(cartItemsSelectedDeliveryMethodOptions.shippingDateFrom)
        // console.log(slotDateTo, shippingDateFrom)
        if (slotDateTo.getTime() < shippingDateFrom.getTime()) constrained = true
      }
      if (cartItemsSelectedDeliveryMethodOptions.shippingDateTo) {
        let slotDateFrom = s.dateFrom.toDate()
        let shippingDateTo = new Date(cartItemsSelectedDeliveryMethodOptions.shippingDateTo)
        // console.log(slotDateFrom, shippingDateTo)
        if (slotDateFrom.getTime() > shippingDateTo.getTime()) constrained = true
      }

      let slotReturned = s
      slotReturned._constrained = constrained

      slotsReturned.push(slotReturned)
    })
    return slotsReturned
  })
  const selectedDayDeliverySlots = computed(() => {
    console.log("sdds:", shopCartStore.selectedSlotDate)
    if (!shopCartStore.selectedSlotDate) return []
    const selectedDate = shopCartStore.selectedSlotDate
    if (!selectedDate) return []

    const selectedMethod = shopCartStore.selectedDeliveryMethod
    if (!selectedMethod) return []

    return deliverySlots.value.filter(
      (s) =>
        selectedDate.getTime() === s._date.getTime() &&
        s.deliveryMethods &&
        s.deliveryMethods.some((dm) => dm.id == selectedMethod.id)
    )
  })
  const selectedSlot = computed(() => {
    if (!shopCartStore.selectedSlot) return false
    const selectedSlot = shopCartStore.selectedSlot
    if (!selectedSlot) return false

    return slots.value.find((s) => s.id === selectedSlot.id)
  })

  // actions
  async function bind() {
    const shopId = window.site.shopId
    let todayMidnight = new Date()
    todayMidnight.setHours(0, 0, 0, 0)
    const collectionRef = collection(firestore, `shops/${shopId}/deliverySlots`)
    const q = query(
      collectionRef,
      where("metadata.deleted", "==", false),
      where("dateFrom", ">=", todayMidnight)
    )
    return await this.attach("slots", q, serializeDeliverySlots)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    slots,
    status,
    unsubscribe,

    // getters
    shopDeliverySlotsLoaded,
    deliverySlots,
    selectedDayDeliverySlots,
    selectedSlot,

    // actions
    bind,
    unbind,
  }
})

// extend slots with custom local properties
const serializeDeliverySlots = (snapshot, data) => {
  let date = data.dateFrom.toDate()
  date.setHours(0, 0, 0, 0)

  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _date: {
      value: date,
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopDeliverySlotsStore, import.meta.hot))
}
