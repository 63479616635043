<template>
  <div class="one-element-form-stepper">
    <OneLabel :text="label" v-if="label" />

    <div class="flex flex-row items-center">
      <span class="value">{{ modelValue }}</span>

      <div class="button-wrap flex flex-row" v-if="mode == 'edit'">
        <div class="button left">
          <OneIcon icon="Minus" @update:click="subtract()" />
        </div>
        <div class="button right">
          <OneIcon icon="Plus" @update:click="add()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid"
export default {
  name: "OneStepper",
  props: {
    min: {
      type: Number,
      // default: -99999 // negative numbers dont work
    },
    max: {
      type: Number,
      default: 999999,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.modelValue)
    },
    add() {
      if (this.modelValue < this.max) {
        let v = this.modelValue + 1
        this.$emit("update:modelValue", v, {fieldId: this.componentUid})
      }
    },
    subtract() {
      // // console.log('subtract', this.modelValue, this.min)
      if (this.modelValue > 0) {
        // negative min numbers dont work
        let v = this.modelValue - 1
        this.$emit("update:modelValue", v, {fieldId: this.componentUid})
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../variables.less";

.one-element-form-stepper {
  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }

  span.value {
    font-weight: bold;
    font-size: 1.5rem;
    // padding: 0 20px 0 8px;
    min-width: 60px;
    padding-left: 8px;
  }

  .button-wrap {
    margin-top: 4px;

    .button {
      background: @pfm-color-neutral-600;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background: @pfm-color-neutral-400;
      }

      &.left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      svg {
        width: 28px;
        height: 28px;

        :deep(*) {
          fill: @pfm-color-neutral-800;
        }
      }
    }
  }
}
</style>
