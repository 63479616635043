import themes from "@/themes"
import {getCurrentInstance} from "vue"
import {useAppStore} from "@/stores/app"

export function useClasses(args) {
  function classes(layout) {
    const componentName = getCurrentInstance().type.name

    // console.log("classes", layout, componentName)

    if (!componentName) {
      console.error("missing component name")
      return
    }
    return getStyle({
      component: componentName,
      layout,
      defaultClasses: this.defaultClasses,
      classOverride: this.classOverride,
      props: args.props,
      that: this,
    })
  }

  return {
    classes,
  }
}

function getStyle(args) {
  const appStore = useAppStore()

  // console.log('getStyle', args)
  let cmp = args.component
  let lo = args.layout
  let c = args.defaultClasses
  if (!c) return false
  if (!args.props) return false
  let selectedThemeName = appStore.app.value.selectedThemeName
  if (!selectedThemeName || !themes[selectedThemeName]) return false
  let tc = selectedThemeName ? themes[selectedThemeName].classes : null
  let cl = [] // class list
  let propsEvaluated = {}

  Object.keys(args.props).forEach((prop) => {
    // // console.log('evaluating prop', prop, args.that[prop], args.props)
    if (args.that[prop] && args.props && args.props[prop]._setsClass) {
      propsEvaluated[prop] = args.that[prop]
    }
  })

  // console.log('propsEvaluated:', propsEvaluated)

  // console.log('getStyle:', c, cmp, selectedThemeName, tc, cl)

  // this is a subcomponent or layout element
  if (lo) {
    // check for theme settings for subcomponent
    if (tc && tc[cmp] && tc[cmp].layout && tc[cmp].layout[lo]) {
      cl.push(tc[cmp].layout[lo])
      // // console.log('found layout theme settings')
      // if this is an extension, append the original classes
      if (/^\+ /.test(tc[cmp].layout[lo])) {
        cl.push(c.layout[lo])
      }
    }
    // default layout
    else {
      cl.push(c.layout[lo])
    }
  }
  // check for theme settings for base
  else if (tc && tc[cmp] && tc[cmp].base) {
    cl.push(tc[cmp].base)
    // if this is an extension, append the original classes
    if (/^\+ /.test(tc[cmp].base)) {
      cl.push(c.base)
    }
  }
  // default base
  else if (c && c.base) {
    // console.log('default base', c.base, args.classOverride)
    // if this is a subcomponent, override original base
    if (args.classOverride && args.classOverride.base) {
      cl.push(args.classOverride.base)

      // if this is an extension, append the original classes
      if (/^\+ /.test(args.classOverride.base)) {
        cl.push(c.base)
      }
    } else {
      cl.push(c.base)
    }
  } else {
    // console.log('no base found')
  }

  Object.keys(propsEvaluated).forEach((pk) => {
    let pv = propsEvaluated[pk] // prop value

    // // console.log('evaluating prop:', pk, pv, propsEvaluated, tc, cmp, tc[cmp])

    // check for theme settings for prop
    if (tc && tc[cmp] && tc[cmp].props && tc[cmp].props[pk] && tc[cmp].props[pk][pv]) {
      cl.push(tc[cmp].props[pk][pv])
      // if this is an extension, append the original classes
      if (/^\+ /.test(tc[cmp].props[pk][pv])) {
        cl.push(c.props[pk][pv])
      }
    }
    // default props
    else if (c && c.props && c.props[pk] && c.props[pk][pv]) {
      // if this is a subcomponent, override original classes
      if (
        args.classOverride &&
        args.classOverride.props &&
        args.classOverride.props[pk] &&
        args.classOverride.props[pk][pv]
      ) {
        // // console.log('classOverride', args.classOverride.props[pk], pk, pv)
        cl.push(args.classOverride.props[pk][pv])

        // if this is an extension, append the original classes
        if (/^\+ /.test(args.classOverride.props[pk][pv])) {
          cl.push(c.props[pk][pv])
        }
      } else {
        cl.push(c.props[pk][pv])
      }
    } else {
      // // console.log('no props found')
    }
  })

  cl = cl.join(" ").replace(/\+ /g, "")
  // console.log('cs:', cl)
  return cl
}
