<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <div class="flex flex-col justify-center items-center">
        <OneIcon class="mt-4 mb-6" icon="SocialInstagram" size="large" v-if="showLogo" />

        <!-- images -->
        <OneImages
          :images="images"
          :override="props.images"
          :layout="layout"
          :ratio="ratio"
          :columns="columns"
          :gap="gap"
          onClick="href"
          v-bind="props.images"
        />
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"
import axios from "axios"

export default {
  name: "OneSocialInstagram",
  mixins: [mixinClasses],
  props: {
    token: {
      type: String,
      default: null,
    },
    userId: {
      type: [String, Number],
      default: null,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    maxImages: {
      type: Number,
      default: 8,
    },
    layout: {
      type: String,
      default: "masonry",
    },
    ratio: {
      type: String,
      default: "auto",
    },
    columns: {
      type: Number,
      default: 3,
    },
    gap: {
      type: Number,
      default: 2,
    },
  },
  data: () => ({
    images: [],
    defaults: {
      commonClasses: "",
    },
  }),
  created() {
    if (this.token && this.userId) this.fetchImages()
  },
  methods: {
    async fetchImages() {
      console.log("fetchImages")
      try {
        var response = await axios({
          method: "get",
          url: `https://graph.instagram.com/${this.userId}/media`,
          params: {
            // fields: 'id,username,caption,media_type,media_url,permalink,thumbnail_url,timestamp',
            fields: "media_type,media_url,permalink",
            access_token: this.token,
          },
        })
      } catch (e) {
        console.error("fetchImages error:", e)
      }
      if (response && response.data) {
        response.data.data.forEach((i) => {
          if (this.images.length < this.maxImages) {
            if (i.media_type == "IMAGE") {
              this.images.push({
                ...i,
                src: i.media_url,
                href: i.permalink,
              })
            }
          }
        })
      }
    },
  },
}
</script>
