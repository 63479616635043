<template>
  <div
    class="block-admin"
    :class="[
      {'block-admin-tools-open-global': admin.toolsOpenGlobal},
      {'block-admin-tools-open-local': toolsOpen},
    ]"
  >
    <!-- add block button -->
    <a class="btn-add-block below" :class="{last: last}" @click="addBlock('below')" v-if="last">
      <OneIcon icon="Plus" />
    </a>
    <a class="btn-add-block above" :class="{first: first}" @click="addBlock('above')">
      <OneIcon icon="Plus" />
    </a>

    <!-- tools -->
    <div class="block-tools" :class="{open: toolsOpen}">
      <a class="tool-btn" @click="openTools()" v-if="!toolsOpen">
        <OneIcon icon="Cog" key="cog" />
        <!-- <span class='label'>block</span> -->
      </a>

      <a class="tool-btn" @click="moveBlock('up')" v-if="toolsOpen && !first">
        <OneIcon icon="ArrowUp" />
        <span class="label">move</span>
      </a>

      <a class="tool-btn" @click="moveBlock('down')" v-if="toolsOpen && !last">
        <OneIcon icon="ArrowDown" />
        <span class="label">move</span>
      </a>

      <a class="tool-btn" @click="deleteBlock()" v-if="toolsOpen">
        <OneIcon icon="Trash" class="danger" />
        <span class="label danger">delete</span>
      </a>

      <a class="tool-btn" @click="closeTools()" v-if="toolsOpen">
        <OneIcon icon="Times" key="times" />
        <!-- <span class='label'>close</span> -->
      </a>
    </div>

    <!-- selector -->
    <div class="block-admin-inner" @click="selectBlock"></div>
  </div>
</template>

<script>
import {useAdminStore} from "@/stores/admin"
import {useAppStore} from "@/stores/app"
import {storeToRefs} from "pinia"

export default {
  name: "pfmBlockAdmin",
  props: {
    block: {
      type: Object,
    },
    blockIndex: {
      type: Number,
    },
    last: {
      type: Boolean,
    },
  },
  setup() {
    // stores
    const adminStore = useAdminStore()
    const appStore = useAppStore()

    // states
    const {admin} = storeToRefs(adminStore)
    const {app} = storeToRefs(appStore)

    // actions
    const {addBlock: adminAddBlock} = adminStore

    return {
      app,
      admin,
      adminAddBlock,
    }
  },
  data: () => ({
    toolsOpen: false, // local (current block)
    defaultClasses: {},
  }),
  computed: {
    first() {
      return this.blockIndex == 0
    },
    mode() {
      return this.app.mode
    },
  },
  created() {
    if (this.admin.toolsOpenBlockId === this.block.id) {
      this.toolsOpen = true
    }
  },
  methods: {
    openTools() {
      this.admin.toolsOpenGlobal = true
      this.admin.toolsOpenBlockId = this.block.id
      this.toolsOpen = true
    },
    closeTools() {
      this.admin.toolsOpenGlobal = false
      this.admin.toolsOpenBlockId = null
      this.toolsOpen = false
    },
    selectBlock() {
      if (this.mode != "admin") return
      // console.log('selectBlock()')
      window.parent.postMessage(
        {
          action: "selectBlock",
          blockId: this.block.id,
          blockIndex: this.blockIndex, // page may have multiple blocks with same id
        },
        "*"
      )
    },
    addBlock(position) {
      if (this.mode != "admin") return
      // console.log('addBlock()', this.block.id, position)
      this.adminAddBlock({
        referenceBlockId: this.block.id,
        referenceBlockIndex: this.blockIndex,
        position,
      })
      this.closeTools()
    },
    moveBlock(direction) {
      if (this.mode != "admin") return
      // console.log('moveBlock()', this.block.id, direction)
      window.parent.postMessage(
        {
          action: "moveBlock",
          blockId: this.block.id,
          blockIndex: this.blockIndex,
          direction,
        },
        "*"
      )
    },
    deleteBlock() {
      if (this.mode != "admin") return
      // console.log('deleteBlock()', this.block.id)
      window.parent.postMessage(
        {
          action: "deleteBlock",
          blockId: this.block.id,
          blockIndex: this.blockIndex, // page may have multiple blocks with same id
        },
        "*"
      )
      this.closeTools()
    },
  },
}
</script>

<style lang="less" scoped>
@import "../variables.less";

.block-admin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 1px solid #ff0000;
  text-align: right;
  color: #acacac;
  z-index: 45;
  transition: background-color 0.25s ease-in-out;
  min-height: 70px;

  a.btn-add-block {
    background-color: @pfm-color-neutral-900;
    width: 32px;
    height: 32px;
    display: block;
    left: calc(50% - 15px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: @pfm-color-neutral-700;
    }

    &.above {
      position: absolute;
      top: -16px;

      &.first {
        top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }

    &.below {
      position: absolute;
      bottom: 0;

      &.last {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    svg {
      width: 20px;
      height: 20px;

      :deep(*) {
        fill: #fff;
      }
    }
  }

  .block-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 6px;
    border-radius: 8px;
    z-index: 10000;

    &.open {
      // background-color: rgba(255,255,255,0.75);
    }

    a.tool-btn {
      background-color: @pfm-color-neutral-900;
      display: flex;
      color: #fff;
      font-family: "MarkPro";
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 8px;
      font-size: 0.75rem;
      margin-left: 10px;
      transition: background-color 0.25s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: @pfm-color-neutral-700;
      }

      &:first-child {
        margin-left: 0;
      }

      svg {
        width: 20px;
        height: 20px;

        &.danger {
          :deep(*) {
            fill: @pfm-color-danger;
          }
        }

        :deep(*) {
          fill: #fff;
        }
      }
    }
  }

  &.block-admin-tools-open-global {
    .block-admin-inner {
      background: rgba(42, 49, 60, 0.85);
    }

    &.block-admin-tools-open-local {
      .block-admin-inner {
        background: none !important;
      }
    }
  }

  .block-admin-inner {
    height: 100%;
    width: 100%;

    &:hover {
      background: rgba(42, 49, 60, 0.2);
      cursor: pointer;
    }

    &:active {
      background: rgba(42, 49, 60, 0.3);
    }
  }
}
</style>
