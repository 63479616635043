import {router} from "@/router"
import Langs from "@/langs"
import {useSiteStore} from "@/stores/site.js"
import sentry from "@/plugins/sentry"

// admin mode - shown in iframe within app-admin
export default {
  install(app) {
    const siteStore = useSiteStore()

    Langs.setLang("cs") // TODO: this should be messaged from admin instead
    listenForAdminEvents()

    function receiveMessage(event) {
      if (event && event.data) {
        if (event.data.action) {
          if (event.data.action == "siteVar") {
            // this is updated siteVar -> update necessary items
            if (siteStore.siteVarFetched) {
              siteStore.setSiteVar({siteVar: event.data.siteVar})
            }

            // this is first siteVar -> create app
            else {
              siteStore.setSiteVar({siteVar: event.data.siteVar})
              router.init(app)
              app.use(router.r())
              app.use(sentry)
              app.mount("#app")
            }
          } else if (event.data.action == "updateBlock") {
            siteStore.updateBlock({block: event.data.block})
          } else if (event.data.action == "updatePage") {
            siteStore.updatePage({page: event.data.page})
          }
        }
      }
    }

    function listenForAdminEvents() {
      console.log("listening for admin events")
      window.parent.postMessage({action: "ready"}, "*")
      window.addEventListener("message", receiveMessage, false)
    }
  },
}
