<template>
  <component
    class="relative overflow-hidden inline-block"
    :data-one-component="$options.name"
    v-bind="props.root"
    :is="defaults.root.is"
    :to="routerLinkTo"
    @click="onClick()"
  >
    <OneIcon
      :icon="icon"
      :color="iconColor"
      :size="iconSize"
      v-bind="props.icon"
      v-if="icon && iconPosition == 'left'"
    />

    <div class="absolute inset-0 w-full h-full z-10" v-if="loadingLocal">
      <OneLoading size="s" class="h-full z-20 relative" color="white" />
      <div class="bg-black opacity-75 absolute inset-0 z-0"></div>
    </div>

    <!-- <div v-if="!loading"> -->
    <span
      v-if="label && type != 'icon'"
      :class="[{'text-neutral-600': type == 'outlined'}]"
      v-html="label"
    ></span>
    <slot v-if="!label && type != 'icon'">Button {{ type }} {{ size }}</slot>
    <!-- <OneIcon
  ref="icon"
  :classOverride="{base: 'children:fill-primary-light'}"
></oneIcon> -->
    <OneIcon
      :icon="icon"
      :color="iconColor"
      :size="iconSize"
      v-bind="props.icon"
      v-if="icon && iconPosition == 'right'"
    />
    <!-- </div> -->
  </component>
</template>

<script>
// import mixinBlock from '@/mixins/block'
import mixinClasses from "../../mixins/classes"
import axios from "axios"
import {inject} from "vue"

export default {
  name: "OneButton",
  // mixins: [mixinBlock],
  mixins: [mixinClasses],
  props: {
    to: {
      type: String,
      default: "",
    },
    href: {
      type: String,
    },
    kind: {
      type: String,
      default: "page",
    },
    url: {
      type: String,
    },
    file: {
      type: Object,
    },
    pageId: {
      type: String,
    },
    // tag: {
    //   type: String,
    //   default: "button",
    // },
    type: {
      type: String,
      default: "primary",
      validator: (val) =>
        [
          "primary",
          "secondary",
          "tertiary",
          "outlined",
          "ghost",
          "info",
          "success",
          "danger",
          "warning",
          "icon",
          "pill",
        ].includes(val),
    },
    size: {
      type: String,
      default: "default",
      validator: (val) => ["auto", "xs", "small", "default", "large"].includes(val),
    },
    classOverride: {
      type: Object,
    },
    label: {
      type: String,
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    iconColor: {
      type: String,
      default: "default",
    },
    iconPosition: {
      type: String,
      default: "right", // right | left
    },
    iconSize: {
      type: String,
      default: "small",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = inject("VUE_ROUTER_USE_ROUTE")
    const router = inject("VUE_ROUTER_USE_ROUTER")

    return {
      route,
      router,
    }
  },
  emits: ["update:click"],
  data: () => ({
    loadingForced: false,
    defaults: {
      commonClasses: "",
      root: {
        class:
          "flex flex-row justify-center items-center flex-none rounded-md block uppercase font-bold tracking-normal transition-all duration-500 ease-in-out cursor-pointer focus:hover:outline-none",
        propClass: {
          tag: {
            button: "",
          },
          type: {
            _common: "focus:outline-none",
            primary: "bg-primary dark:bg-neutral-800 hover:bg-primary-light rounded-md !text-white",
            secondary: "bg-secondary hover:bg-secondary-light !text-white",
            tertiary: "bg-tertiary hover:bg-tertiary-light text-tertiary-light",
            ghost: "hover:bg-neutral-300",
            outlined: "border border-neutral-600",
            success: "bg-success hover:bg-success-light",
            danger: "bg-danger hover:opacity-75 text-white",
            icon: "",
            pill: "bg-neutral-300 hover:bg-neutral-400 rounded-3xl text-neutral-600",
          },
          size: {
            auto: "",
            xs: "py-1 px-2 text-xs",
            small: "py-2 px-4 text-sm",
            default: "py-4 px-6",
            large: "py-8 px-10",
          },
          // TODO: fix updating classes
          disabled: {
            true: "", //'opacity-50 pointer-events-none cursor-not-allowed',
            false: "", //'opacity-100 pointer-events-auto cursor-auto'
          },
        },
      },
    },
  }),
  computed: {
    loadingLocal() {
      if (this.loading) return true
      if (this.loadingForced) return true
      else return false
    },
    routerLinkTo() {
      if (this.to) return this.to
      if (this.defaults.root.is != "RouterLink") return ""

      // generic link, follow current lang
      let lang = this.route && this.route.meta && this.route.meta.lang ? this.route.meta.lang : null

      // lang switch link, change lang
      if (Object.hasOwn(this, "switchLangTo") && this.switchLangTo) lang = this.switchLangTo

      // platforma.one specific:
      const siteDefaultLang = this.$pinia?.state?.value?.site?.siteVar?.lang?.default
        ? this.$pinia.state.value.site.siteVar.lang.default
        : false

      let to = {}

      if (Object.hasOwn(this, "switchLangTo") && this.switchLangTo) {
        to.name = `${this.route.meta.id}-${lang}`
      } else if (this.pageId) {
        if (siteDefaultLang == lang) lang = "default"
        to.name = `${this.pageId}-${lang}`
      } else {
        if (siteDefaultLang == lang) lang = null
        to.path = lang ? `/${lang}` + this.path : this.path
      }

      return to
    },
  },
  created() {
    // console.log("Button.vue:", this.defaults, this.router, this.route)
    if (this.kind == "close") this.defaults.root.is = "button"
    else if (this.href || (this.kind == "url" && this.url)) {
      this.defaults.root.is = "a"
      this.defaults.root.href = this.href ? this.href : this.url
      if (
        !this.defaults.root.href.startsWith("http://") &&
        !this.defaults.root.href.startsWith("https://") &&
        !this.defaults.root.href.startsWith("mailto:")
      ) {
        this.defaults.root.href = "https://" + this.defaults.root.href
      }
      this.defaults.root.target = "_blank"
    } else if ((this.path || this.pageId) && this.router) {
      this.defaults.root.is = "RouterLink"
    } else {
      this.defaults.root.is = "button"
    }
  },
  methods: {
    onClick() {
      console.log("onClick")
      // ignore if this is RouterLink or disabled
      if (this.disabled || this.to) return
      this.$emit("update:click")

      if (this.kind == "file") {
        let filename = this.file.fileName
        this.download({filename, url: this.file.url, contentType: this.file.contentType})
      }
    },
    download(args) {
      console.log("download():", args)
      this.loadingForced = true
      axios({
        method: "get",
        url: args.url,
        headers: {
          Accept: args.contentType,
        },
        withCredentials: false,
        responseType: "blob",
      }).then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", args.filename)
        document.body.appendChild(link)
        link.click()
        this.loadingForced = false
      })
    },
  },
}
</script>
