<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- image -->
      <OneImage
        :override="props.image"
        v-bind="props.image"
        :src="image.src"
        :imageId="image.imageId"
      />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneSingleImage",
  mixins: [mixinClasses],
  props: {
    image: {
      type: Object,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex-wrap",
      },
      card: {
        class: "pb-5",
      },
    },
  }),
}
</script>
