import text_cs from "./cs.js"
import text_en from "./en.js"
import {useShopStore} from "@/stores/shop"
import {useShopCartStore} from "@/stores/shopCart"
import {useSiteStore} from "@/stores/site"

export default {
  data: {cs: text_cs, en: text_en},
  available: ["cs", "en"],
  lang: "en",
  overrides: {},

  get(key, fill, forcedLang) {
    let lang = forcedLang ? forcedLang : this.lang
    // console.log('Langs/get():', key, fill, forcedLang)
    let result = key

    // user overrides
    if (this.overrides[key]) {
      // console.log('Langs/get(): got override', this.overrides[key])
      result = this.overrides[key][lang]
    } else {
      if (this.data[lang] != null && this.data[lang].text[key] != undefined) {
        result = this.data[lang].text[key]
      }
    }
    if (fill) {
      for (let i = 0; i < fill.length; i++) {
        result = result.replace("%" + i, fill[i])
      }
    }
    return result
  },

  gets(key) {
    // TODO get provided values and fit them into texts placeholders
    if (this.data[this.lang] != null && this.data[this.lang].text[key] != undefined) {
      return this.data[this.lang].text[key]
    }
    return key
  },

  setLang(lang) {
    const shopStore = useShopStore()
    const shopCartStore = useShopCartStore()
    const siteStore = useSiteStore()
    // console.log("Selecting lang", lang)

    // also set default shop currency
    const hasShop = siteStore.hasShop.value
    if (hasShop) {
      const defaultCurrencies = shopStore.shop.value.config?.language?.defaultCurrencies

      if (defaultCurrencies) {
        let currency = defaultCurrencies[lang]
        if (currency) shopCartStore.setCurrency(currency)
      }
    }

    if (this.available.indexOf(lang) == -1) {
      // Administrationis not supporting this language
      return false
    }
    this.lang = lang
    // // console.log('Selected lang', lang, this.data[this.lang])
    return true
  },
}
