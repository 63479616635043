import langs from "@/langs"

export default {
  install(app) {
    app.directive("localize", {
      beforeMount: function (el, binding) {
        if (binding.value.fill) {
          el.innerHTML = langs.get(binding.value.key, binding.value.fill)
        } else {
          el.innerHTML = langs.get(binding.value.key)
        }
      },
    })
  },
}
