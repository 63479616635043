<template>
  <div class="one-layout-columns w-full flex flex-row overflow-x-scroll">
    <slot />
  </div>
</template>

<script>
// import mixinClasses from '../../mixins/classes'

export default {
  name: "oneLayoutColumns",
  // mixins: [mixinClasses],
  props: {},
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
    },
  }),
}
</script>
