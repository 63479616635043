import * as components from "./components.js"

const prefix = "One"

export default {
  install(Vue) {
    for (const name in components) {
      const component = components[name]
      const componentName = `${prefix}${name}`
      Vue.component(componentName, component)
    }
  },
}
