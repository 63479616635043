<template>
  <div class="one-element-form-toggle-array" v-if="loaded">
    <OneLabel :text="label" />
    <OneToggle
      v-for="option in options"
      :key="option.value"
      v-model="vls[option.value]"
      align="left"
      :labelTrue="getLabel(option)"
      class="btn-toggle"
      :disabled="option.disabled ? option.disabled : false"
      @update:modelValue="toggle(option.value, $event)"
    />
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid"
export default {
  props: {
    modelValue: {
      type: Array,
    },
    options: {
      type: Array,
    },
    currentLang: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    labelFalse: {
      type: String,
      default: null,
    },
    labelTrue: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: "center", // left | center | right
    },
    size: {
      type: [String, Number],
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data() {
    return {
      values: {},
      loaded: false,
    }
  },
  computed: {
    vls() {
      let v = {}
      this.options.forEach((o) => {
        v[o.value] = this.modelValue.includes(o.value) ? true : false
      })
      return v
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.options.forEach((o) => {
        this.values[o.value] = this.modelValue.includes(o.value) ? true : false
      })
      this.loaded = true
    },
    getLabel(option) {
      if (typeof option.name === "string") {
        return option.name
      } else if (typeof option.name === "object") {
        return option.name[this.currentLang]
      }
    },
    toggle(v, e) {
      // console.log("toggle()", v, e)

      if (e == true) {
        this.modelValue.push(v)
      } else {
        let index = this.modelValue.findIndex((i) => i == v)
        // console.log('found index:', index)
        this.modelValue.splice(index, 1)
      }

      this.$emit("update:modelValue", this.modelValue, {fieldId: this.componentUid})
    },
  },
}
</script>
