import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {functions, firestore} from "@/firebase/index.js"
import {collection, doc, setDoc, updateDoc, serverTimestamp} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import {calculate} from "@platformaone/common/commerce"
import {useSiteStore} from "./site.js"
import {useShopStore} from "./shop.js"
import {useShopItemsStore} from "./shopItems.js"
import {useShopDeliverySlotsStore} from "./shopDeliverySlots.js"
import {useValidatorStore} from "./validator.js"
import sink from "@/sink"

const cartCheckStockFn = httpsCallable(functions, "calls-commerce-cart-checkStock")

export const useShopCartStore = defineStore("shopCart", () => {
  console.log("shopCart:", useShopCartStore(), this)
  // stores
  const siteStore = useSiteStore()
  const shopStore = useShopStore()
  const shopItemsStore = useShopItemsStore()
  const shopDeliverySlotsStore = useShopDeliverySlotsStore()
  const validatorStore = useValidatorStore()

  // state
  const cart = ref({})
  const itemsCache = ref([]) // used to make adding items visually quick. items are first added here, then to a real cart. ItemList.vue displays items from here.
  const dbBatch = ref({})
  const frontend = ref({}) // system/interface
  const confirmation = ref({}) // confirmation view - this is filled after closing the cart to show orderinfo in confirmation view
  const view = ref("form") // form | confirmation
  const totalDbWrites = ref(0)
  const statusStockCheck = ref("init")
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopCartLoaded = computed(() => status.value == "loaded")
  const stockCheckLoaded = computed(() => statusStockCheck.value == "loaded")
  const id = computed(() => {
    if (cart.value && cart.value.id) {
      return cart.value.id
    } else {
      const lsCart = localStorage.getItem("@platformaone/store/commerce/cart")
      const lss = JSON.parse(lsCart)
      if (lss) return lss.id
      else return false
    }
  })
  const exists = computed(() => {
    return cart.value && cart.value.id && shopCartLoaded.value ? true : false
  })
  const selectedDeliveryMethod = computed(() => {
    if (
      !cart.value ||
      !cart.value.delivery ||
      !cart.value.delivery.method ||
      !cart.value.delivery.method.id
    ) {
      return false
    }
    if (!shopStore.shop) {
      return false
    }
    return shopStore.deliveryMethods.find((m) => m.id === cart.value.delivery.method.id)
  })
  const selectedSlot = computed(() => cart.value.delivery?.slot)
  const selectedSlotDate = computed(() => cart.value.delivery?.slot?.date?.toDate())
  const isDeliveryValid = computed(() => {
    let sdm = selectedDeliveryMethod.value
    // delivery method not selected
    if (!sdm) {
      return false
    }

    // delivery method has branchas and branch was not selected
    if (sdm.branches && sdm.branches.length > 0 && !cart.value.delivery.method.branchId) {
      return false
    }

    // delivery method does not allow delivery info & does not require delivery slot selection
    if (["denied"].includes(sdm.constraints.deliveryInfo) && !sdm.constraints.deliverySlots) {
      return true
    }

    // delivery method requires slot selection
    if (sdm.constraints.deliverySlots) {
      return cart.value.delivery.country &&
        cart.value.delivery.method &&
        cart.value.delivery.method.id &&
        cart.value.delivery.slot.date &&
        cart.value.delivery.slot.date.toDate() &&
        cart.value.delivery.slot.id
        ? true
        : false
    }

    // other delivery methods
    else {
      return cart.value.delivery.country &&
        cart.value.delivery.method &&
        cart.value.delivery.method.id
        ? true
        : false
    }
  })
  const itemsSelectedDeliveryMethodOptions = computed(() => {
    if (!shopStore.shopLoaded) return {}
    if (!cart.value.delivery || !cart.value.delivery.method) return {}

    let o = {}
    let selectedDeliveryMethodDetail = shopStore.deliveryMethods.find(
      (m) => m.id == cart.value.delivery.method.id
    )

    if (!selectedDeliveryMethodDetail) return {}
    // check for delivery method restrictions for all items
    cart.value.items.forEach((ci) => {
      if (ci.snapshot.config.shipping.methods) {
        let m = ci.snapshot.config.shipping.methods.find(
          (m) => m.id == selectedDeliveryMethodDetail.id
        )
        if (m && m.constraints) {
          if (m.constraints.minShippingMinEnabled) {
            // if there is no current or new is more than current, override
            if (!o.minShippingMin || m.constraints.minShippingMin > o.minShippingMin)
              o.minShippingMin = m.constraints.minShippingMin
          }
          if (m.constraints.shippingDateFromEnabled) {
            let currentDate = o.shippingDateFrom
            let newDate = m.constraints.shippingDateFrom.toDate()
            if (!currentDate || newDate > currentDate) o.shippingDateFrom = newDate
          }
          if (m.constraints.shippingDateToEnabled) {
            let currentDate = o.shippingDateTo
            let newDate = m.constraints.shippingDateTo.toDate()
            if (!currentDate || newDate < currentDate) o.shippingDateTo = newDate
          }
        }
      }
    })
    return o
  })

  // actions
  // - cart
  async function bind(args) {
    const context = args.context

    console.log("bind context:", context, args)
    const shopId = window.site.shopId
    const cartId = args && args.cartId ? args.cartId : id.value

    if (!shopId) console.error("bindCart(): cannot bind, missing shopId:", shopId)
    if (!cartId) console.error("bindCart(): cannot bind, missing cartId:", cartId)

    const docRef = doc(firestore, `shops/${shopId}/carts/${cartId}`)
    await useShopCartStore().attach("cart", docRef)

    // if cart contains any items, update itemsCache
    if (cart.value && cart.value.items && cart.value.items.length) {
      itemsCache.value = cart.value.items
    }

    // TODO: this is temporary solution for fixing cart refresh. Items stock should be re-checked when cart is reloaded. This will be implemented in a stock tracking solution not relying on Cloud Function
    statusStockCheck.value = "loaded"
    return
  }

  async function unbind() {
    await useShopCartStore().detach({reset: false}) // this resets the entire store - is that intended? -> no
    console.log("unbind(): cart detached")

    // thigs have to be reset manually to preserve 'frontend', 'confirmation' and 'view'
    cart.value = {}
    itemsCache.value = []
    dbBatch.value = {}
    totalDbWrites.value = 0
    statusStockCheck.value = "init"
    status.value = "init"
    unsubscribe.value = null
    return
  }

  async function init() {
    resetFrontend()
    resetConfirmation()
    console.log("init() this", this)
    if (id.value) {
      return await bind({context: this})
    }
  }

  async function create(args) {
    resetConfirmation({keepOpen: true}) // if customer leaves confirmation open and adds another product

    const shopId = window.site.shopId
    const siteVar = siteStore.siteVar
    const context = args.context

    // preset payment method if shop has only one
    let presetPaymentMethod = null
    if (shopStore.paymentMethods.length === 1) {
      presetPaymentMethod = shopStore.paymentMethods[0]
    }

    const initialCart = {
      items: [],
      currency: frontend.value.currency,
      language: siteVar.lang.current,
      customer: {
        email: null,
        name: {
          original: null,
          first: null,
          last: null,
        },
        phone: null,
      },
      billing: {
        address: {
          manual: {
            street: null,
            postal: null,
            city: null,
          },
          company: {
            ico: null,
            name: null,
          },
          google: {
            formatted_address: "",
          },
          googlePicked: false,
          forceManualEntry: false,
          includeCompanyInfo: false,
          sameAsDelivery: true,
        },
        customerFilled: false,
        note: null,
      },
      delivery: {
        recipient: {
          name: {
            original: null,
            first: null,
            last: null,
          },
          phone: null,
          note: null,
          sameAsCustomer: true,
        },
        address: {
          manual: {
            street: null,
            postal: null,
            city: null,
          },
          company: {
            ico: null,
            name: null,
          },
          google: {
            formatted_address: "",
          },
          googlePicked: false,
          forceManualEntry: false,
          includeCompanyInfo: false,
        },
        contactless: false,
        note: null,
        country: "CZ",
        method: null,
        slot: {
          date: null,
          slot: null,
        },
      },
      total: {
        brutto: 0,
        netto: 0,
      },
      payment: {
        method: presetPaymentMethod,
        processingState: "init",
      },
      promoCode: {
        code: null,
      },
      note: null,
    }

    const data = {
      ...initialCart,
      meta: {
        created: serverTimestamp(),
        updated: false,
        deleted: false,
        cart: {
          version: "__VERSION__",
          commit: "__RELEASE_SHA__",
        },
      },
    }

    const collectionRef = collection(firestore, `shops/${shopId}/carts`)
    const docRef = doc(collectionRef)

    updateLocalStorage({
      data: {
        id: docRef.id,
        version: "__VERSION__",
        commit: "__RELEASE_SHA__",
      },
    })

    await setDoc(docRef, data)
    await bind({context, cartId: docRef.id})
    return
  }

  async function markOrderClick() {
    await update({
      data: {
        "meta.events.clickedOrder": serverTimestamp(),
      },
    })
  }

  async function update(args) {
    totalDbWrites.value++

    const shopId = window.site.shopId
    const cartId = id.value
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }

    console.log("🔴 updateCart() DB WRITE | total:", totalDbWrites.value)
    // console.log("data:", data)
    let cartRef = doc(firestore, `shops/${shopId}/carts/${cartId}`)
    await updateDoc(cartRef, data)

    updateCartMainHeight()
    return
  }

  async function updateTotal() {
    const afterCalc = calculate(cart.value)
    if (!afterCalc) return
    const totalPrice = afterCalc.filter((item) => item.type == "total")[0]
    const total = {
      brutto: totalPrice.brutto,
      netto: totalPrice.netto,
    }

    await update({
      data: {
        total: total,
      },
    })
  }

  async function setCurrency(args) {
    const currency = args
    frontend.value.currency = currency
    if (exists.value) {
      await update({
        data: {
          currency,
        },
      })
      await updateTotal()
    }
  }

  function getDeliveryMethodPriceConstrained(args) {
    const methodId = args
    return computed(() => {
      const method = shopStore.deliveryMethods.find((m) => m.id == methodId)
      const price = method.price.find((p) => {
        let passed = true

        if (p.model != "fixed") {
          passed = false
        }
        if (p.enabled === false) {
          passed = false
        }
        if (p.currency != cart.value.currency) {
          passed = false
        }
        if (p.constraints.deliveryCountriesEnabled) {
          if (p.constraints.deliveryCountries.includes(cart.value.delivery.country) === false) {
            passed = false
          }
        }
        if (p.constraints.orderPriceEnabled) {
          const itemsTotal = cart.value.total.brutto
          if (itemsTotal < p.constraints.orderPriceMin || itemsTotal > p.constraints.orderPriceMax)
            passed = false
        }
        // TODO: order dimensions
        // if (p.constraints.orderDimensionsEnabled) {}
        // TODO: order weight
        // if (p.constraints.orderWeightEnabled) {}
        return passed
      })

      return price
    })
  }

  // - batch
  function batchAdd(args) {
    dbBatch.value = {...dbBatch.value, ...args}
  }

  async function batchAddUpdateCartTotal() {
    batchAdd({
      total: await cartTotal({fromBatch: true}),
    })
  }

  async function batchUpdateCart() {
    await update({
      data: dbBatch.value,
    })
    dbBatch.value = {}
  }

  // - items
  async function itemAdd(args) {
    // console.log("addItem(): args:", args)

    if (!args.id) console.error("addItem(): missing product id")

    if (!args.snapshot) {
      args.snapshot = shopItemsStore.getItemById(args.id)
      if (!args.snapshot) console.error("addItem(): could not get product snapshot")
    }

    const itemId = args.id // item id
    const shopId = siteStore.siteVar.shopId
    let uniqueId = false // item unique id in cart
    let newItems = exists.value ? cart.value.items : itemsCache.value ? itemsCache.value : []
    let itemsMetaUpdate = exists.value && cart.value.meta.items ? cart.value.meta.items : {}
    let itemIndex = newItems.findIndex((i) => i.id == itemId)
    const itemAlreadyInCart = itemIndex !== -1
    let action = itemAlreadyInCart ? "multiply" : "add"

    sink.event("add_to_cart", {item: args.snapshot})

    // action: gift cards don't support multiplication
    if (args.snapshot.config.productSubKind == "giftCard") {
      action = "add" // ability to remove products that don't support multiplication
      uniqueId = Math.random().toString(16).slice(2)

      if (args.count > 1) {
        const remaining = args.count - 1
        for (let i = 0; i < remaining; i++) {
          let newPayload = args
          newPayload.count = 1
          itemAdd(newPayload)
        }
      }
    }
    // action: packages don't support multiplication
    if (args.snapshot.config.priceKind == "package") {
      action = "add" // ability to remove products that don't support multiplication
      uniqueId = Math.random().toString(16).slice(2)
    }

    // action: add as new item (item not in cart yet or item doesn't support multiplying)
    console.log("action", action)
    if (action == "add") {
      if (!args.count) args.count = 1
      newItems.push({
        id: itemId,
        uniqueId,
        count: args.count,
        snapshot: args.snapshot,
      })
      // Firestore does not support serverTimestamp in arrays, hence it is separately
      if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
      itemsMetaUpdate[itemId].added = serverTimestamp()

      // await dispatch('batchAdd', {items: newItems})
    } else if (action == "multiply") {
      const count = newItems[itemIndex].count + 1
      newItems[itemIndex].count = count
      // Firestore does not support serverTimestamp in arrays, hence it is separately
      if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
      itemsMetaUpdate[itemId].updated = serverTimestamp()
      // await dispatch('batchAdd', {items: newItems})
    }

    // deselect previously selected delivery method
    // console.log('addItem(): deselecting delivery method')
    // commit('setDeliveryMethod', null)

    // set cart params from URL
    let query = window.location.search
    let urlParams = new URLSearchParams(query)
    let selectDeliveryMethod = urlParams.get("dm")
    let hideDeliveryMethods = urlParams.has("hidedms")
    // console.log('URL PARAMS:', urlParams, selectDeliveryMethod, hideDeliveryMethods)
    if (selectDeliveryMethod) {
      await setDeliveryMethod(selectDeliveryMethod)
      if (hideDeliveryMethods) {
        frontend.value.showDeliveryMethods = false
      }
    }

    itemsCache.value = newItems
    if (args.open) frontend.value.open = true

    if (!exists.value) await create({context: this})
    const cartId = id.value

    // check if item is available
    // this is done afterwards to make the UX as fast as possible. Most of times the product is available anyway, because the 'add to cart' button was enabled.
    // sum all products with same id (some products w/ same id may be split to multiple items)
    let requestedCount = null
    newItems.forEach((i) => {
      if (i.id == itemId) requestedCount += i.count
    })
    console.log("requestedCount", requestedCount)

    let icqs = args.snapshot.config.quantity.stock
    let stockCheckRequired = icqs.trackingEnabled
    if (icqs.available === false && icqs.whenUnavailable == "preorder") stockCheckRequired = false

    console.log("stockCheckRequired:", stockCheckRequired)
    if (stockCheckRequired) {
      const req = {
        shopId,
        cartId,
        itemId,
        requestedCount,
      }
      console.log("req:", req)
      try {
        statusStockCheck.value = "loading"

        let response = await cartCheckStockFn(req)
        console.log("addItem(): check stock response:", response)

        // if not enough, revert back
        if (response.data.available === false && response.data.availablePreorder === false) {
          // packages don't support non-preset counts
          if (args.snapshot.config.priceKind == "package") {
            console.log("removing item, priceKind package. id:", itemId, "uniqueId:", uniqueId)
            // remove item
            newItems = newItems.filter((i) => i.id != itemId || i.uniqueId != uniqueId)
            // TODO: should popup some note to user
          }
          // gift cards don't support multiplication
          else if (args.snapshot.config.productSubKind == "giftCard") {
            console.log(
              "removing item, productSubKind giftCard. itemId:",
              itemId,
              "uniqueId:",
              uniqueId
            )
            // remove item
            newItems = newItems.filter((i) => i.id != itemId || i.uniqueId != uniqueId)
          }
          // lower count to max available
          else if (response.data.availableCount > 0) {
            console.log("lowering item count to max available")
            // TODO: should popup some note to user
            let itemToUpdateIndex = newItems.findIndex((i) => i.id == itemId)
            console.log("lowering count:", newItems, itemToUpdateIndex)
            newItems[itemToUpdateIndex].count = response.data.availableCount
            // Firestore does not support serverTimestamp in arrays, hence it is separately
            if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
            itemsMetaUpdate[itemId].updated = serverTimestamp()
          }
          // remove item
          else {
            console.log("removing item")
            newItems = newItems.filter((i) => i.id != itemId || i.uniqueId != uniqueId)
            // Firestore does not support serverTimestamp in arrays, hence it is separately
            if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
            itemsMetaUpdate[itemId].removed = serverTimestamp()
          }
        }
      } catch (e) {
        console.error("addItem(): failed:", e)
      }
    }
    statusStockCheck.value = "loaded"

    let batchAddItems = {items: newItems}
    if (itemsMetaUpdate) {
      batchAddItems[`meta.items`] = itemsMetaUpdate
    }

    itemsCache.value = newItems

    await batchAdd(batchAddItems)
    await batchAddUpdateCartTotal()
    await batchUpdateCart()
  }

  async function itemSubtract(args) {
    console.log("subtractItemCount():", args)
    const itemId = args.id
    let newItems = exists.value ? cart.value.items : itemsCache.value ? itemsCache.value : []
    let itemsMetaUpdate = exists.value ? cart.value.meta.items : {}
    const itemToUpdateIndex = newItems.findIndex((i) => i.id == itemId)

    console.log("lowering count:", newItems, itemToUpdateIndex)
    newItems[itemToUpdateIndex].count--
    if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
    itemsMetaUpdate[itemId].updated = serverTimestamp()

    if (newItems[itemToUpdateIndex].count < 1) {
      // remove item
      newItems = newItems.filter((i) => i.id != itemId)
      // Firestore does not support serverTimestamp in arrays, hence it is separately
      if (!itemsMetaUpdate[itemId]) itemsMetaUpdate[itemId] = {}
      itemsMetaUpdate[itemId].removed = serverTimestamp()
    }

    let batchAddItems = {items: newItems}
    if (itemsMetaUpdate) {
      batchAddItems[`meta.items`] = itemsMetaUpdate
    }

    itemsCache.value = newItems
    await batchAdd(batchAddItems)
    await batchAddUpdateCartTotal()
    await batchUpdateCart()

    // if newItems is empty, clear cart
    if (newItems.length < 1) {
      await unbind()
      cart.value = {}
      status.value = "init"
      localStorage.removeItem("@platformaone/store/commerce/cart")
    }
  }

  async function itemRemove(args) {
    console.log("removeItem:", args)
    let itemsMetaUpdate = exists.value ? cart.value.meta.items : {}
    const items = cart.value.items.filter((i) => i.id != args.id || i.uniqueId != args.uniqueId)
    // Firestore does not support serverTimestamp in arrays, hence it is separately
    if (!itemsMetaUpdate[args.id]) itemsMetaUpdate[args.id] = {}
    itemsMetaUpdate[args.id].removed = serverTimestamp()

    let data = {items}
    if (itemsMetaUpdate) {
      data[`meta.items`] = itemsMetaUpdate
    }

    itemsCache.value = items
    await update({
      data,
    })
    await updateTotal()
    // if cart is empty, clear cart
    if (cart.value.items.length < 1) {
      await unbind()
      cart.value = {}
      status.value = "init"
      localStorage.removeItem("@platformaone/store/commerce/cart")
    }
  }

  // - delivery methods
  async function setDeliveryMethod(args) {
    console.log("setDeliveryMethod():", args)
    // commit("setDeliveryMethod", args)
    const methodId = args
    let newMethod = {}
    let newPrice = null

    if (methodId) {
      let method = shopStore.deliveryMethods.find((d) => d.id == methodId)

      console.log("method:", method)

      // update requirement: deliveryAddress
      validatorStore.setFieldRequirement({
        name: "deliveryAddress",
        required: method.constraints.deliveryInfo == "required",
      })

      // update requirement: deliverySlotDate
      validatorStore.setFieldRequirement({
        name: "deliverySlotDate",
        required: method.constraints.deliverySlots || false,
      })

      // update requirement: deliverySlotSlot
      validatorStore.setFieldRequirement({
        name: "deliverySlotSlot",
        required: method.constraints.deliverySlots || false,
      })

      // update requirement: recipientName
      validatorStore.setFieldRequirement({
        name: "recipientName",
        required: false,
      })

      // update requirement: recipientPhone
      validatorStore.setFieldRequirement({
        name: "recipientPhone",
        required: false,
      })

      newPrice = {
        brutto: 0,
        netto: 0,
        vat: 0,
        vat_pct: 0,
        constraints: {},
        model: "fixed",
        currency: cart.value.currency,
      }

      if (method.constraints.deliverySlots == false) {
        console.log("delivery price is fixed", method)
        // select correct price
        newPrice = getDeliveryMethodPriceConstrained(method.id)
        console.log("selected price:", newPrice)
      } else {
        console.log("delivery price differs by slot", method)
      }

      newMethod = {
        id: method.id,
        code: method.code,
      }

      // ZASILKOVNA-COURIER -> set carrier
      if (method.code == "ZASILKOVNA-COURIER") {
        let country = cart.value.delivery.country
        if (!method.config || !method.config.carriers)
          console.error("missing method.config.carriers")
        if (!method.config.carriers[country])
          console.error(`missing method.config.carriers.${country}`)
        let point = method.config.carriers[country]
        newMethod.point = point
      }
    } else {
      newMethod = null
      newPrice = null
    }

    await batchAdd({
      "delivery.method": newMethod,
      "delivery.price": newPrice,
      "delivery.slot.id": null, // deselect previously selected slot
    })
    await batchAddUpdateCartTotal()
    await batchUpdateCart()
  }

  // - delivery slots
  async function setDeliverySlotDate(args) {
    const date = args
    console.log("setDeliverySlotDate", date)

    // update interaction
    validatorStore.setFieldInteraction({
      name: "deliverySlotDate",
    })

    await batchAdd({
      "delivery.slot.date": date,
      "delivery.slot.id": null, // deselect previously selected slot
    })
    await batchAddUpdateCartTotal()
    await batchUpdateCart()
  }

  async function setDeliverySlotSlot(args) {
    const slotId = args
    const valid = slotId != null && slotId != undefined

    // update interaction
    validatorStore.setFieldInteraction({
      name: "deliverySlotSlot",
    })

    if (valid) {
      const slot = shopDeliverySlotsStore.deliverySlots.find((s) => s.id == slotId)
      const sdm = slot.deliveryMethods.find((dm) => dm.id == selectedDeliveryMethod.value.id)
      const price = sdm.price.find((p) => p.currency == cart.value.currency && p.model == "fixed")
      await batchAdd({"delivery.price": price})
      validatorStore.setFieldValidity({name: "deliverySlotDate", valid: true})
      validatorStore.setFieldValidity({name: "deliverySlotSlot", valid: true})
    } else {
      await batchAdd({"delivery.price": null})
    }

    await batchAdd({"delivery.slot.id": slotId})
    await batchAddUpdateCartTotal()
    await batchUpdateCart()
  }

  // - google address field
  async function setAddressGoogle(args) {
    console.log("setAddressGoogle()", args.v)

    if (args.v) {
      let street = ""
      if (args.v.route) street = args.v.route.short_name
      if (args.v.street_number) street += ` ${args.v.street_number.short_name}`

      let city = ""
      if (args.v.administrative_area_level_2) city = args.v.administrative_area_level_2.short_name
      if (args.v.administrative_area_level_1) city = args.v.administrative_area_level_1.short_name
      if (args.v.locality) city = args.v.locality.short_name
      if (args.v.sublocality_level_1) city = args.v.sublocality_level_1.short_name

      let postal = ""
      if (args.v.postal_code) postal = args.v.postal_code.short_name

      await batchAdd({
        [`${args.scope}.address.google`]: args.v,
        [`${args.scope}.address.googlePicked`]: true,
        [`${args.scope}.address.manual.street`]: street,
        [`${args.scope}.address.manual.city`]: city,
        [`${args.scope}.address.manual.postal`]: postal,
      })
    } else {
      await batchAdd({
        [`${args.scope}.address.google`]: {
          formatted_address: "",
        },
        [`${args.scope}.address.googlePicked`]: false,
        [`${args.scope}.address.manual.street`]: null,
        [`${args.scope}.address.manual.city`]: null,
        [`${args.scope}.address.manual.postal`]: null,
      })
      validatorStore.resetField({
        name: `${args.scope}Address`,
      })
    }
    await batchUpdateCart()
  }

  // - order close / confirmation
  async function closeOrder(args) {
    await batchAdd({"payment.processingState": "success"})
    confirmation.value.order.number = args.orderNumber
    confirmation.value.order.closed = true
    confirmation.value.cart.snapshot = cart.value
    await batchUpdateCart()

    setTimeout(async () => {
      console.log("view switch to confirmation screen")
      await unbind()
      frontend.value.overlay.active = false
      updateCartMainHeight()
      console.log("setting view.value to confirmation", view.value)
      view.value = "confirmation"
      localStorage.removeItem("@platformaone/store/commerce/cart")
    }, 1500)
  }

  // - misc
  function resetConfirmation(args) {
    const open = args && args.keepOpen ? true : false
    const initialConfirmation = {
      cart: {
        snapshot: {},
      },
      order: {
        closed: false,
        number: null,
      },
    }

    view.value = "form" // there was also a duplicate function -> state.frontend.open = payload
    frontend.value.open = open
    confirmation.value = initialConfirmation
  }

  function setOpen(args) {
    const open = args
    frontend.value.open = open === false ? false : true
  }

  // - shared internal
  function resetFrontend() {
    const google =
      frontend.value && frontend.value.google
        ? frontend.value.google
        : {
            mapsApiLoaded: false,
          }

    const initialFrontend = {
      open: false,
      view: "form",
      currency: null, // used until cart is created
      showBackToStoreBtn: true,
      showDeliveryMethods: true,
      cartMainHeight: null,
      doubleClickLock: false,
      overlay: {
        active: false,
        color: "light",
      },
      redrawMap: false,
      google,
      autofillTestCard: false, // used for development
    }
    frontend.value = initialFrontend
  }

  function updateLocalStorage(args) {
    const data = {
      ...args.data,
      meta: {
        updated: new Date(),
      },
    }
    localStorage.setItem("@platformaone/store/commerce/cart", JSON.stringify(data))
    return
  }

  function updateCartMainHeight() {
    let cartMain = document.getElementById("pfm-cart-main")
    if (cartMain) {
      frontend.value.cartMainHeight = cartMain.clientHeight
    }
  }

  function cartTotal(args) {
    let cartSnapshot = cart.value
    // console.log("getCartTotal(): cartSnapshot BEFORE", JSON.parse(JSON.stringify(cartSnapshot)))
    // merge batch data with current cart and calculate (so we don't have to write batch to DB, then calculate and then write again the result)
    if (args.fromBatch) {
      let batchData = dbBatch.value
      // console.log('getCartTotal(): batchData:', JSON.parse(JSON.stringify(batchData)))
      let batchDataDeepen = deepen(batchData)
      // console.log('getCartTotal(): batchDataDeepen:', JSON.parse(JSON.stringify(batchDataDeepen)))
      cartSnapshot = {...cartSnapshot, ...batchDataDeepen}
    }
    // console.log('getCartTotal(): cartSnapshot AFTER:', JSON.parse(JSON.stringify(cartSnapshot)))
    let result = calculate(cartSnapshot)
    // console.log("getCartTotal(): result:", JSON.parse(JSON.stringify(result)))
    if (!result) return
    let totalPrice = result.filter((item) => item.type == "total")[0]

    let total = {
      brutto: totalPrice.brutto,
      netto: totalPrice.netto,
    }

    // console.log("total:", total)
    return total
  }

  return {
    // state
    cart,
    itemsCache,
    frontend,
    confirmation,
    view,
    statusStockCheck,
    status,
    unsubscribe,

    // getters
    shopCartLoaded,
    stockCheckLoaded,
    id,
    exists,
    selectedDeliveryMethod,
    selectedSlot,
    selectedSlotDate,
    isDeliveryValid,
    itemsSelectedDeliveryMethodOptions,

    // actions
    bind,
    unbind,
    init,
    markOrderClick,
    update,
    setCurrency,
    itemAdd,
    itemSubtract,
    itemRemove,
    batchAdd,
    batchUpdateCart,
    setDeliveryMethod,
    setDeliverySlotDate,
    setDeliverySlotSlot,
    setAddressGoogle,
    closeOrder,
    resetConfirmation,
    setOpen,
    getDeliveryMethodPriceConstrained,
  }
})

// convert dot notation to object
function deepen(obj) {
  let result = {}

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    let parts = objectPath.split(".")

    // Create sub-objects along path as needed
    let target = result
    while (parts.length > 1) {
      const part = parts.shift()
      target = target[part] = target[part] || {}
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath]
  }

  return result
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopCartStore, import.meta.hot))
}
