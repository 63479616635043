<template>
  <div
    class="one-element-form-chips"
    :class="[{disabled: disabled}, `mode-${mode}`, `size-${size}`]"
  >
    <OneLabel :text="label" />

    <div class="chips-wrap">
      <!-- chips -->
      <div
        class="chip"
        :class="[
          {'bg-neutral-600 hover:bg-neutral-700': !chipActive(chip.value)},
          {'bg-neutral-700 hover:bg-neutral-800': chipActive(chip.value)},
        ]"
        v-for="chip in chipsSorted"
        :key="chip.value"
      >
        <div
          class="chip-content cursor-pointer flex items-center h-8"
          :class="[{'px-3': !chipActive(chip.value)}, {'pl-1 pr-3': chipActive(chip.value)}]"
          @click="toggleChip(chip.value)"
        >
          <OneIcon
            class="p-0 !h-6 !w-6"
            icon="Tick"
            color="white"
            key="Tick"
            size="small"
            v-if="chipActive(chip.value)"
          />
          <span>{{ getTitle(chip) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy"
import {ref} from "vue"

export default {
  name: "OneFormChips",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    chips: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default", // small | default
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const chipValues = ref(props.modelValue)

    return {
      chipValues,
    }
  },
  computed: {
    chipsSorted() {
      return sortBy(this.chips, ["title"])
    },
  },
  methods: {
    getTitle(chip) {
      if (chip.title) return chip.title
      else if (chip.value) return chip.value
      else return chip
    },
    toggleChip(chipValue) {
      let event = null
      let index = this.chipValues.findIndex((v) => v === chipValue)
      if (index === -1) {
        this.chipValues.push(chipValue)
        event = "adding"
      } else {
        this.chipValues.splice(index, 1)
        event = "removing"
      }
      // this.$emit("update:modelValue", {event, value: chipValue})
      this.$emit("update:modelValue", {event, value: chipValue}, this.chipValues)
    },
    chipActive(chipValue) {
      let index = this.chipValues.findIndex((v) => v === chipValue)
      if (index === -1) return false
      else return true
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-chips {
  &.mode-view {
    .chips-wrap {
      border: none;
      padding: 0;

      .chip {
        &:first-child {
          margin-left: 0;
        }

        .chip-content {
          padding: 10px 12px 10px 12px;
        }
      }
    }
  }

  &.size-small {
    .chips-wrap {
      .chip {
        margin: 2px;
        border-radius: 500px;

        .chip-content {
          padding: 3px 4px;

          span {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }

  .chips-wrap,
  .autocomplete-wrap {
    margin-bottom: 10px;
    border-radius: 500px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    // padding: 5px;
    gap: 8px;

    .chip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      // margin: 5px;

      .chip-content {
        // padding: 10px 12px 10px 12px;
        span {
          color: @pfm-color-neutral-200;
          font-size: 0.9rem;
          line-height: 0.9rem;
          display: block;
        }
      }
    }
  }
}
</style>
