function getPageRoute(pageId, all, lang) {
  let route = "/"
  let page = all.find((p) => p.id == pageId)
  if (page !== undefined && page.slug !== undefined) {
    route += page.slug[lang]
    // Try to get parent path
    if (page.treeParentId != "root") {
      let parentPath = getPageRoute(page.treeParentId, all, lang)
      if (parentPath != "/") {
        route = parentPath + route
      }
    }
  }
  return route
}

export {getPageRoute}
