<template>
  <div class="one-element-form-object">
    <OneLabel :text="labelText" />

    <div>
      <!-- <draggable
      :list="scopedValue"
      handle=".handle"
      @end="onDragEnd"
    > -->
      <div class="flex flex-row items-center" v-for="(item, index) in scopedValue" :key="index">
        <div class="w-full">
          <OneLabel :text="objectKeyTitles[index]" v-if="objectKeyTitles" />

          <!-- editor: input -->
          <!-- TODO: untested -->
          <!-- <OneInput
            class="flex-1"
            v-model="scopedValue[index]"
            :mode="mode"
            label=""
            :autoState="autoState"
            :long="long"
            @update:modelValue="onInput"
            v-if="editor == 'input'"
          /> -->

          <!-- editor: select -->
          <!-- TODO: untested -->
          <!-- <OneSelect
            class="flex-1"
            v-model="scopedValue[index]"
            :options="selectOptions"
            :mode="mode"
            label=""
            @update:modelValue="onInput"
            v-if="editor == 'select'"
          /> -->

          <!-- editor: tags -->
          <OneFormTags
            class="w-full"
            label=""
            :mode="mode"
            v-model="scopedValue[index]"
            :autocomplete="tagsAutocomplete"
            @update:modelValue="onInput"
            v-if="editor == 'tags'"
          />
        </div>
        <div class="flex flex-row items-center" v-if="mode == 'edit'">
          <OneIcon
            class="ml-2"
            icon="MinusCircle"
            size="small"
            link
            @update:click="removeItem(index)"
          />
          <OneIcon class="handle ml-2 cursor-move" icon="List" size="small" v-if="sortable" />
        </div>
      </div>

      <!-- add item key options -->
      <div class="flex flex-row items-center" v-if="addingItem.open">
        <OneSelect
          class="flex-1"
          v-model="addingItem.value"
          :options="addItemSelectOptions"
          :mode="mode"
          label=""
          @update:modelValue="itemAdded"
        />
        <OneIcon
          class="ml-2"
          icon="XCircle"
          key="XCircle"
          size="small"
          link
          @update:click="addingItem.open = false"
        />
      </div>
      <!-- </draggable> -->
    </div>

    <OneIcon
      class="ml-2 mt-1"
      icon="PlusCircle"
      size="small"
      link
      @update:click="addItem()"
      v-if="mode == 'edit'"
    />
  </div>
</template>

<script type="text/javascript">
// import draggable from "vuedraggable"
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneObject",
  components: {
    // draggable,
  },
  props: {
    long: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
    },
    label: {
      type: String,
      default: "Text field",
    },
    // labelKey: {
    //   type: String
    // },
    lowercase: {
      type: Boolean,
      default: false,
    },
    nospace: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit", // view | edit
    },

    // how long to wait with emitting input event after typing has stopped
    debounce: {
      type: Number,
      default: 750,
    },

    // saving state indicator
    saveState: {
      // manual
      type: String,
      default: "init", // init | saving | saved
    },
    autoState: {
      // auto -> 'formSaveState' store necessary
      type: Boolean,
      default: false,
    },
    editor: {
      type: String,
      default: "input",
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Array,
    },
    objectKeyTitles: {
      type: Object,
      default: () => {
        return {}
      },
    },
    tagsAutocomplete: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data() {
    return {
      addingItem: {
        open: false,
        value: null,
      },
    }
  },
  computed: {
    addItemSelectOptions() {
      let o = []
      Object.keys(this.objectKeyTitles).forEach((item) => {
        if (!(item in this.modelValue)) {
          o.push({
            name: this.objectKeyTitles[item],
            value: item,
          })
        }
      })
      return o
    },
    scopedValue: {
      get() {
        if (!this.modelValue) return
        // sort the object so it doesn't move while editing tags
        let ordered = {}
        Object.keys(this.modelValue)
          .sort()
          .forEach((key) => {
            ordered[key] = this.modelValue[key]
          })
        return ordered
      },
      set() {
        // console.log('Array:scopedValue:set', v)
      },
    },
    labelText() {
      // if (this.labelKey) {
      //   return Langs.get(this.labelKey)
      // } else {
      return this.label
      // }
    },
  },
  methods: {
    removeItem(v) {
      console.log("removeItem", v, this.scopedValue)
      delete this.modelValue[v]
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    addItem() {
      this.addingItem.open = true
    },
    itemAdded(v) {
      // console.log(v)
      this.modelValue[v] = []
      this.addingItem.open = false
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    onDragEnd() {
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    onInput(value, meta) {
      // console.log('onInput', value, this.modelValue, meta)
      this.$emit("update:modelValue", this.modelValue, meta)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-array {
  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }
}
</style>
