import {router} from "@/router"
import {useSiteStore} from "@/stores/site.js"
import sentry from "@/plugins/sentry"

// design mode - lists all designed components
export default {
  install(app) {
    const siteStore = useSiteStore()

    siteStore.setSiteVar({
      siteVar: {
        lang: {
          current: "cs",
        },
        pages: [],
      },
    })
    router.init(app)
    app.use(router.r())
    app.use(sentry)
    app.mount("#app")
  },
}
