<template>
  <div>
    <OneInput v-model="time" label="" :mode="mode" @update:inputDebounced="onInput" />
  </div>
</template>

<script>
export default {
  name: "OneFormTime",
  props: {
    modelValue: {
      type: [Date, Object],
    },
    label: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    time: {
      get() {
        if (!this.modelValue) return `00:00`
        return `${this.hours}:${this.minutes}`
      },
      set() {},
    },
    hours: {
      get() {
        if (!this.modelValue) return
        let date
        if (typeof this.modelValue.toDate === "function") date = this.modelValue.toDate()
        else date = this.modelValue
        return String(date.getHours()).padStart(2, "0")
      },
    },
    minutes: {
      get() {
        if (!this.modelValue) return
        let date
        if (typeof this.modelValue.toDate === "function") date = this.modelValue.toDate()
        else date = this.modelValue
        return String(date.getMinutes()).padStart(2, "0")
      },
    },
  },
  methods: {
    onInput(v, meta) {
      // console.log('onInput', v, meta)
      this.$emit("update:modelValue", v, meta)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";
</style>
