<template>
  <div :data-one-component="$options.name" v-bind="props.root" :style="cssVars">
    <!-- card -->
    <!-- <a :href="props.link.href"
      data-one-label="cardWrap"
      v-bind="props.link"
      > -->
    <OneLink ref="link" v-bind="props.link">
      <!-- circle -->
      <div class="circle-wrap">
        <div class="circle"></div>
      </div>

      <!-- image & caption wrap -->
      <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
        <!-- logo -->
        <OneImage v-bind="props.logo" v-if="props.logo && props.logo.src" />

        <!-- caption -->
        <OneParagraph v-bind="props.caption" v-if="props.caption && props.caption.content" />
      </div>

      <!-- background image -->
      <div v-bind="props.backgroundWrap">
        <!-- color -->
        <OneImage
          :src="props.backgroundImage.src"
          v-bind="props.backgroundImageMain"
          v-if="props.backgroundImage && props.backgroundImage.src"
          :preload="true"
        />
        <!-- pseudo -->
        <OneImage
          :src="props.backgroundImage.src"
          v-bind="props.backgroundImagePseudo"
          v-if="props.backgroundImage && props.backgroundImage.src"
        />
      </div>
    </OneLink>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneCard2",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    backgroundImage: {
      type: Object,
    },
  },
  data: () => ({
    circleMaxSizePct: 80,
    circleR: 0,
    defaults: {
      commonClasses: "",
      root: {
        class: "element-card2 w-full",
      },
      link: {
        class: "z-10 relative overflow-hidden w-full flex-center",
        style: {
          height: "80vh", // if you change this, fix danielapolubedovova project
        },
      },
      backgroundWrap: {
        class: "background-wrap",
      },
      backgroundImageMain: {
        class: "main-image absolute inset-0",
        maxWidth: "full",
        minHeight: "full",
      },
      backgroundImagePseudo: {
        class: "pseudo-image absolute inset-0",
        maxWidth: "full",
        minHeight: "full",
      },
      logoCaptionWrap: {
        class: "relative z-10 flex flex-col pointer-events-none",
        // style: {
        //   width: '20%'
        // }
      },
      logo: {
        fit: "contain",
      },
      caption: {
        align: "center",
        color: "white",
        class: "whitespace-nowrap",
      },
    },
  }),
  computed: {
    cssVars() {
      return {
        "--circle-r": this.circleR,
      }
    },
    linkDimensions() {
      if (this.$refs && this.$refs.link && this.$refs.link.$el) {
        let d = this.$refs.link.$el.getBoundingClientRect()
        return {
          width: d.width,
          height: d.height,
        }
      } else {
        return null
      }
    },
  },
  mounted() {
    if (this.linkDimensions) {
      let linkSmallerDimension =
        this.linkDimensions.width < this.linkDimensions.height
          ? this.linkDimensions.width
          : this.linkDimensions.height
      this.circleR = ((linkSmallerDimension / 100) * this.circleMaxSizePct) / 2
    }
  },
  methods: {},
}
</script>

<style scoped lang="less">
.element-card2 {
  // --circle-r: 150;
  position: relative;

  // height: 80vh;

  .circle-wrap {
    left: 0;
    align-items: center;
    bottom: 0;
    display: flex;
    right: 0;
    position: absolute;
    justify-content: center;
    z-index: 9;
    top: 0;
    pointer-events: none;

    .circle {
      width: calc(var(--circle-r) * 2px);
      height: calc(var(--circle-r) * 2px);
      border: 1px solid #fff;
      // height: calc(68vh + 2px);
      border-radius: 50%;

      // &:after {
      //   content: '';
      //   display: block;
      //   padding-bottom: 100%;
      // }
    }
  }

  .title-wrap {
    top: 0;
    z-index: 9;
    justify-content: center;
    display: flex;
    right: 0;
    color: #ffffff;
    left: 0;
    position: absolute;
    pointer-events: none;
    align-items: center;
    bottom: 0;
  }

  .background-wrap {
    // height: 100%;
    // position: relative;
    // width: 100%;

    .main-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      filter: grayscale(100%) brightness(50%);
    }

    .pseudo-image {
      width: 100%;
      bottom: 0;
      position: absolute;
      right: 0;
      z-index: 8;
      top: 0;
      left: 0;
      object-fit: cover;
      -webkit-clip-path: circle(calc(var(--circle-r) * 1px) at center);
      height: 100%;
      filter: grayscale(100%) brightness(50%);

      &:hover {
        filter: grayscale(0%);
      }
    }
  }
}
</style>
