<template>
  <div class="one-section-hero" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer
      v-bind="containerOverride"
      :backgroundKind="containerBackgroundKind"
      :backgroundImage="containerBackgroundImage"
      :backgroundSlideshow="containerBackgroundSlideshow"
      :backgroundSlideshowConfig="containerBackgroundSlideshowConfig"
      :backgroundVideo="containerBackgroundVideo"
      :backgroundVideoPoster="containerBackgroundVideoPoster"
      :backgroundFit="containerBackgroundFit"
      :contentAlign="containerContentAlign"
      :width="containerWidth"
    >
      <!-- logo & caption wrap -->
      <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
        <!-- logo -->
        <OneImage
          v-bind="logoOverride"
          v-if="logo && logo.src"
          :src="logo.src"
          :imageId="logo.imageId"
        />

        <!-- heading -->
        <OneHeading
          v-bind="props.heading"
          :content="headingContent"
          :align="headingTextAlign"
          :size="headingFontSize"
          :color="headingTextColor"
          v-if="headingContent"
        />

        <!-- caption -->
        <OneParagraph
          class="mt-4"
          v-bind="props.caption"
          v-if="captionContent"
          :content="captionContent"
          :align="captionTextAlign"
          :size="captionFontSize"
          :color="captionTextColor"
        />

        <!-- CTA button -->
        <OneButton
          class="block mt-6"
          :class="[ctaButtonAlign]"
          :style="{marginTop: ctaButtonMarginTop}"
          v-bind="ctaButton"
          v-if="ctaButton && ctaButton.label && ctaButton.label != '\n'"
        />
      </div>

      <!-- scroll line -->
      <div class="scroll-line" v-if="scrollLine"></div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneHero1",
  mixins: [mixinClasses],
  props: {
    containerBackgroundKind: {
      type: String,
      default: "image",
    },
    containerBackgroundImage: {
      type: Object,
    },
    containerBackgroundSlideshow: {
      type: [Boolean, Array],
    },
    containerBackgroundSlideshowConfig: {
      type: [Boolean, Object],
    },
    containerBackgroundVideo: {
      type: Object,
    },
    containerBackgroundVideoPoster: {
      type: Object,
    },
    containerBackgroundFit: {
      type: String,
    },
    containerContentAlign: {
      type: String,
    },
    containerWidth: {
      type: String,
    },
    containerOverride: {
      type: Object,
    },
    logoOverride: {
      type: Object,
    },
    logo: {
      type: Object,
    },
    headingContent: {
      type: [String, Boolean],
    },
    headingTextAlign: {
      type: String,
      default: "center",
    },
    headingFontSize: {
      type: String,
      default: "base",
    },
    headingTextColor: {
      type: String,
    },
    captionContent: {
      type: [String, Boolean],
    },
    captionTextAlign: {
      type: String,
      default: "center",
    },
    captionFontSize: {
      type: String,
      default: "base",
    },
    captionTextColor: {
      type: String,
    },
    ctaButton: {
      type: Object,
    },
    ctaButtonAlign: {
      type: String,
      default: "flex-start",
    },
    ctaButtonMarginTop: {
      type: String,
      default: "4vh",
    },
    scrollLine: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        height: "screen",
        width: "full",
      },
      logoCaptionWrap: {
        class: "relative z-10 flex flex-col",
      },
      logo: {
        fit: "contain",
      },
    },
  }),
  created() {
    // this.setCaptionDefaultColor()
  },
  mounted() {
    // would be nice to implement this one day. needs to be rewritten
    // bgc.init({
    //   targets: '.target',
    //   images: '.thumbnails'
    // });
  },
  methods: {
    // setCaptionDefaultColor() {
    //   // set caption text color according to background image lightness
    //   if (this.props && this.props.container && this.props.container.backgroundImage && this.props.container.backgroundImage.lightness) {
    //     let l = this.props.container.backgroundImage.lightness
    //     if (l > 50) this.props.caption.color = 'primary-dark'
    //     else this.props.caption.color = 'primary-light'
    //   }
    // }
  },
}
</script>

<style scoped lang="less">
.scroll-line {
  position: absolute;
  z-index: 49;
  bottom: -50px;
  height: 100px;
  left: 50%;
  border-left: 1px solid #313131;
}
</style>
