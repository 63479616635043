export default {
  classes: {
    pfmButton: {
      base: "+ rounded-full",
      // base: 'lowercase', // override
      // base: '+ lowercase', // extension
      props: {
        type: {
          // primary: 'bg-primary xs:rounded-full hover:bg-primary-light',  /// override
          // secondary: '+ border-2 border-black'  // extension
        },
      },
    },
    pfmProductListingItem: {
      subcomponents: {
        price: {
          // base: 'text-xs'
        },
      },
    },
  },
}
