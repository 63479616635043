<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- items: Key Value -->
      <template v-if="variant == 1">
        <OneListItemLabelValue
          v-for="(item, index) in items"
          :key="`ListItem-${index}`"
          :override="props.item"
          v-bind="props.item"
          :label="item.label"
          :value="item.value"
        />
      </template>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneGridList",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex flex-wrap",
        contentAlign: "middleLeft",
      },
      item: {
        class: "pr-8 last:pr-0",
      },
    },
  }),
}
</script>
