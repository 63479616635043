<template>
  <div class="one-section-singlebutton" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer class="flex flex-col" v-bind="override.container">
      <!-- CTA button -->
      <OneButton
        class="block"
        :class="[ctaButtonAlign]"
        v-bind="ctaButton"
        v-if="ctaButton && ctaButton.label"
        @update:click="onClick"
      />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneSingleButton",
  mixins: [mixinClasses],
  props: {
    override: {
      type: Object,
    },
    ctaButton: {
      type: Object,
    },
    ctaButtonAlign: {
      type: String,
      default: "flex-start",
    },
  },
  emits: ["update:click"],
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        height: "screen",
        width: "full",
      },
    },
  }),
  methods: {
    onClick() {
      this.$emit("update:click")
    },
  },
}
</script>
