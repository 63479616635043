import {useShopStore} from "@/stores/shop.js"
import {useShopItemsStore} from "@/stores/shopItems.js"
import {useShopCartStore} from "@/stores/shopCart.js"

// if site has commerce -> load items, init cart, ...
export default {
  install() {
    if (!window.site.shopId) return

    const shopStore = useShopStore()
    const shopItemsStore = useShopItemsStore()
    const shopCartStore = useShopCartStore()

    shopStore.bind()
    shopItemsStore.bind()
    shopCartStore.init()

    // params when getting back from redirects (eg. customer auth)
    const query = window.location.search
    const urlParams = new URLSearchParams(query)
    let openCart = urlParams.get("openCart") || false
    if (openCart) {
      shopCartStore.setOpen()
    }
  },
}
