<script setup>
import {debounce} from "debounce"
import {ref, toRef, onMounted} from "vue"
import {storeToRefs} from "pinia"
import {v4 as uuidv4} from "uuid"

const props = defineProps({
  long: {
    type: Boolean,
    default: false,
  },
  langs: {
    type: Array,
  },
  modelValue: {
    type: [Object, String, Number, Boolean],
  },
  label: {
    type: String,
    default: "Text field",
  },
  // labelKey: {
  //   type: String
  // },
  lowercase: {
    type: Boolean,
    default: false,
  },
  nospace: {
    type: Boolean,
    default: false,
  },
  regex: {
    type: [String, Boolean],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: "edit", // view | edit
  },
  placeholder: {
    type: [String, Object],
  },

  // how long to wait with emitting input event after typing has stopped
  debounce: {
    type: Number,
    default: 750,
  },

  // saving state indicator
  saveState: {
    // manual
    type: String,
    default: "init", // init | saving | saved | error
  },
  autoState: {
    // auto -> 'formSaveState' store necessary
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  "update:modelValue",
  "update:keyup",
  "update:enter",
  "update:focus",
  "update:blur",
  "update:inputDebounced",
])

let autoSaveState
let setFieldState

onMounted(async () => {
  if (props.autoState) {
    const {useFormSaveStateStore} = await import("@/stores/formSaveState.js")

    // stores
    const formSaveStateStore = useFormSaveStateStore()

    // states
    const {state} = storeToRefs(formSaveStateStore)

    // actions
    const {setState} = formSaveStateStore

    autoSaveState = state
    setFieldState = setState
  }
})

const focused = ref(false)
const showPlaceholder = ref({cs: false, en: false})
const emitInputDebounced = debounce(function (v, lang) {
  // console.log("BOUNCE")
  emitInputDebouncedX(v, lang)
}, props.debounce)

const scopedValue = toRef(props, "modelValue")

const componentUid = uuidv4()

function getPlaceholder(args) {
  const lang = args
  if (typeof props.placeholder === "object" && lang) {
    return props.placeholder[lang]
  } else {
    return props.placeholder
  }
}

function setShowPlaceholder(args) {
  const lang = args
  if (!props.placeholder) {
    if (lang) showPlaceholder.value[lang] = false
    else showPlaceholder.value = false
  } else {
    let valueIsEmpty = true

    if (lang) {
      if (props.modelValue && props.modelValue[lang] && props.modelValue[lang].length > 0) {
        valueIsEmpty = false
      }

      showPlaceholder.value[lang] = valueIsEmpty
    } else {
      // setShowPlaceholder without langs is fired before value is updated. Dunno why. This fixes it.
      setTimeout(() => {
        if (props.modelValue && props.modelValue.length > 0) {
          valueIsEmpty = false
        }
        showPlaceholder.value = valueIsEmpty
      }, 50)
    }
  }
}

function setInitState() {
  if (props.autoState) {
    if (props.langs) {
      props.langs.forEach((lang) => {
        if (setFieldState) {
          setFieldState({
            id: `${componentUid}-${lang}`,
            state: "init",
          })
        }
        showPlaceholder.value[lang] = false
        setShowPlaceholder(lang)
      })
    } else {
      if (setFieldState) {
        setFieldState({
          id: componentUid,
          state: "init",
        })
      }
      setShowPlaceholder()
    }
  }
}

function getCurrentSaveState(args) {
  const lang = args
  // show indicator only for current field
  const fieldId = lang ? `${componentUid}-${lang}` : componentUid
  if (props.autoState && autoSaveState && autoSaveState.value)
    return autoSaveState.value.items[fieldId]
  else return props.saveState
}

function onKeyUp(e) {
  // console.log("Input.vue: onKeyUp")
  emit("update:keyup", e)
}
function onEnter(e) {
  // console.log("Input.vue: onEnter")
  emit("update:enter", e)
}
function onFocus() {
  // console.log("Input.vue: onFocus")
  focused.value = true
  emit("update:focus")
}
function onBlur() {
  // console.log("Input.vue: onBlur")
  focused.value = false
  emit("update:blur")
}
function onInput(value, lang) {
  console.log("Input.vue: onInput", value, lang)
  setShowPlaceholder(lang)
  emitInput(value, lang)
  emitInputDebounced(value, lang)
}

// debounced input emit
function emitInputDebouncedX(value, lang) {
  let v = props.langs ? scopedValue : value
  // console.log("emitInputDebouncedX()", value, scopedValue, v, lang)
  let fieldId = lang ? `${componentUid}-${lang}` : componentUid
  emit("update:inputDebounced", v, {fieldId, lang})
}

// undebounced (live) input emit
function emitInput(value, lang) {
  let v = props.langs ? scopedValue : value
  console.log("emitInput()", value, scopedValue, v)
  let fieldId = lang ? `${componentUid}-${lang}` : componentUid
  emit("update:modelValue", v, {fieldId, lang})
}

setInitState()
</script>

<template>
  <div
    class="one-element-form-input"
    :class="[{long: long}, {disabled: disabled}, {focused: focused}, `mode-${mode}`]"
  >
    <OneLabel :text="label" />

    <!-- multilang field -->
    <div v-if="langs">
      <div
        class="item"
        :class="[
          {'has-placeholder': showPlaceholder[lang]},
          `savestate-${getCurrentSaveState(lang)}`,
        ]"
        v-for="lang in langs"
        :key="lang"
      >
        <OneSave :state="getCurrentSaveState(lang)" />
        <span ref="placeholder" class="placeholder" v-if="showPlaceholder[lang]">{{
          getPlaceholder(lang)
        }}</span>
        <OneDatatypeString
          v-model="scopedValue[lang]"
          :multiline="long"
          :lowercase="lowercase"
          :nospace="nospace"
          :regex="regex"
          :disabled="disabled"
          :mode="mode"
          @update:modelValue="onInput($event, lang)"
          @update:onKeyup="onKeyUp"
          @update:onFocus="onFocus"
          @update:onBlur="onBlur"
          @update:onEnter="onEnter"
        />
        <span class="lang">{{ lang }}</span>
      </div>
    </div>

    <!-- single lang field -->
    <div
      class="item"
      :class="[{'has-placeholder': showPlaceholder}, `savestate-${getCurrentSaveState()}`]"
      v-if="!langs"
    >
      <OneSave :state="getCurrentSaveState()" />
      <span ref="placeholder" class="placeholder" v-if="showPlaceholder">{{
        getPlaceholder()
      }}</span>
      <OneDatatypeString
        v-model="scopedValue"
        :multiline="long"
        :lowercase="lowercase"
        :nospace="nospace"
        :regex="regex"
        :disabled="disabled"
        :mode="mode"
        @update:modelValue="onInput"
        @update:onKeyup="onKeyUp"
        @update:onFocus="onFocus"
        @update:onBlur="onBlur"
        @update:onEnter="onEnter"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-input {
  &.long {
    word-break: break-word;
    .item {
      p {
        min-height: 4rem;
      }
    }
  }

  &.disabled {
    .item {
      &:hover {
        background: none;
      }
    }
  }

  &.mode-view {
    .item {
      border: none;

      &:hover {
        background: none;
      }

      p {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &.mode-edit {
    .item {
    }
  }

  &.focused {
    .item {
      border: 2px solid @pfm-color-neutral-600;
      outline: none;
    }
  }

  // span.label {
  //     padding-left: 8px;
  //     color: @pfm-color-neutral-600;
  // }

  .item {
    border: 2px solid @pfm-color-neutral-400;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    min-width: 60px;
    outline: none;
    margin: 5px 0 5px 0;
    overflow-x: hidden;

    &.savestate-saving {
      span.lang {
        opacity: 0;
      }
    }

    &.savestate-saved {
      // border-color: @pfm-color-success;

      span.lang {
        opacity: 0;
      }
    }

    &.savestate-error {
      border-color: @pfm-color-danger;
    }

    &.has-placeholder {
      // min-width: 150px;
    }

    &:hover {
      background: @pfm-color-neutral-100;
    }

    p {
      flex: 1;
    }

    span.placeholder {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 8px;
      color: @pfm-color-neutral-500;
      pointer-events: none;
      white-space: nowrap;
      display: block;
    }

    span.lang {
      padding: 6px;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: bold;
      color: @pfm-color-neutral-400;
    }

    .one-element-indicator-save {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
}
</style>
