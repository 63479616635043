<template>
  <div class="one-layout-list-item">
    <slot />
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneList",
  mixins: [mixinClasses],
  props: {
    contentLayout: {
      type: String,
      default: "flex", // flex | grid
    },
    modelValue: {
      type: Array,
    },

    // all available options for selects
    globalOptions: {
      type: Array,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
    },
  }),
  methods: {
    onInput(v) {
      this.$emit("update:modelValue", v)
      // console.log('onInput', v)
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../variables.less";

.one-layout-list-item {
  padding: 14px;
  margin: 8px 0;
  background: #ffffff;
  border-radius: 8px;
  font-size: 1rem;
  letter-spacing: 1px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.03);
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
