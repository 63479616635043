<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- label -->
    <span v-bind="props.label" v-if="props.label">{{ label }}</span>

    <!-- value -->
    <span v-bind="props.value" v-if="props.value">{{ value }}</span>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "oneListItemLabelValue",
  mixins: [mixinClasses],
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "flex flex-col font-body",
      },
      label: {
        class: "font-bold",
      },
      value: {
        class: "",
      },
    },
  }),
}
</script>
