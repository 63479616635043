<template>
  <div class="one-layout-column min-w-full">
    <slot />
  </div>
</template>

<script>
// import mixinClasses from '../../mixins/classes'

export default {
  name: "oneLayoutColumn",
  // mixins: [mixinClasses],
  props: {},
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
    },
  }),
}
</script>
