<template>
  <div class="one-element-form-array">
    <OneLabel :text="labelText" />

    <slot name="header" :addItem="addItem"></slot>

    <div>
      <draggable :list="scopedValue" :item-key="getItemKey" handle=".handle" @end="onDragEnd">
        <template #item="{index}">
          <div class="flex flex-row items-center">
            <!-- editor: input -->
            <OneInput
              class="flex-1"
              v-model="scopedValue[index]"
              :mode="mode"
              label=""
              :autoState="autoState"
              :long="long"
              @update:inputDebounced="onInput"
              v-if="editor == 'input'"
            />

            <!-- editor: select -->
            <OneSelect
              class="flex-1"
              v-model="scopedValue[index]"
              :options="selectOptions"
              :mode="mode"
              label=""
              @update:modelValue="onInput"
              v-if="editor == 'select'"
            />

            <!-- editor: slot -->
            <OneSection
              class="w-full"
              :key="`section-${index}`"
              v-if="mode == 'edit' && editor == 'slot'"
            >
              <div
                class="flex flex-row items-center justify-end"
                v-if="mode == 'edit' && editor == 'slot'"
              >
                <OneIcon
                  class="ml-2"
                  icon="MinusCircle"
                  size="small"
                  link
                  @update:click="removeItem(index)"
                  v-if="showRemove"
                />
                <OneIcon class="handle ml-2 cursor-move" icon="List" size="small" v-if="sortable" />
              </div>
              <slot name="item" :item="scopedValue[index]"></slot>
            </OneSection>

            <div class="flex flex-row items-center" v-if="mode == 'edit' && editor != 'slot'">
              <OneIcon
                class="ml-2"
                icon="MinusCircle"
                size="small"
                link
                @update:click="removeItem(index)"
                v-if="showRemove"
              />
              <OneIcon class="handle ml-2 cursor-move" icon="List" size="small" v-if="sortable" />
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <OneIcon
      class="ml-2 mt-1"
      icon="PlusCircle"
      size="small"
      link
      @update:click="addItem()"
      v-if="mode == 'edit' && showAddItem"
    />
  </div>
</template>

<script type="text/javascript">
import draggable from "vuedraggable"
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneArray",
  components: {
    draggable,
  },
  props: {
    long: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
    },
    label: {
      type: String,
      default: "Text field",
    },
    // labelKey: {
    //   type: String
    // },
    lowercase: {
      type: Boolean,
      default: false,
    },
    nospace: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit", // view | edit
    },

    // how long to wait with emitting input event after typing has stopped
    debounce: {
      type: Number,
      default: 750,
    },

    // saving state indicator
    saveState: {
      // manual
      type: String,
      default: "init", // init | saving | saved
    },
    autoState: {
      // auto -> 'formSaveState' store necessary
      type: Boolean,
      default: false,
    },
    editor: {
      type: String,
      default: "input", // input | array | slot
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    showAddItem: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Array,
    },
    newItemTemplate: {
      type: [String, Object],
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  computed: {
    scopedValue: {
      get() {
        return this.modelValue
        // return this.modelValue.map((i, index) => {
        //   return {
        //     value: i,
        //     index,
        //   }
        // })
      },
      set() {
        // console.log('Array:scopedValue:set', v)
      },
    },
    labelText() {
      // if (this.labelKey) {
      //   return Langs.get(this.labelKey)
      // } else {
      return this.label
      // }
    },
  },
  methods: {
    getItemKey(v) {
      return v
    },
    removeItem(index) {
      this.scopedValue = this.scopedValue.splice(index, 1)
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    addItem() {
      let newItem = this.newItemTemplate ? this.newItemTemplate : ""
      this.scopedValue.push(newItem)
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    onDragEnd() {
      this.$emit("update:modelValue", this.modelValue, {
        fieldId: this.componentUid,
      })
    },
    onInput(value, meta) {
      console.log("onInput", value, this.modelValue, meta)
      this.$emit("update:modelValue", this.modelValue, meta)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-array {
  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }
}
</style>
