<template>
  <div
    :data-one-component="$options.name"
    :class="['wrap', `layout-${layout}`, `columns-${columns}`, `gap-${gap}`]"
  >
    <!-- image lightbox -->
    <OneImageLightbox
      :image="lightboxImage"
      @update:close="lightboxOpen = false"
      v-if="lightboxOpen && onClick == 'lightbox'"
    />

    <!-- images -->
    <OneImage
      :class="['image']"
      v-for="(image, index) in images"
      :key="`${image.imageId}-${index}`"
      :src="image.src"
      :imageId="image.imageId"
      :override="props.image"
      @update:click="open(image)"
      :ratio="ratio"
      maxHeight="none"
    />
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneImages",
  mixins: [mixinClasses],
  props: {
    images: {
      type: [Boolean, Array],
    },
    onClick: {
      type: String,
      default: "lightbox", // lightbox || href
    },
    layout: {
      type: String,
      default: "masonry", // list | grid | grid-sm | masonry
    },
    ratio: {
      type: String,
      default: "auto", // auto | square
    },
    columns: {
      type: [Number, String],
      default: "auto",
    },
    gap: {
      type: [Number, String],
      default: "auto",
    },
  },
  components: {},
  data: () => ({
    defaults: {},
    lightboxOpen: false,
    lightboxImage: null,
  }),
  methods: {
    open(image) {
      if (this.onClick == "lightbox") this.openLightbox(image)
      else if (this.onClick == "href") this.openHref(image)
    },
    openLightbox(image) {
      // console.log('openLightbox():', image)
      this.lightboxImage = image
      this.lightboxOpen = true
    },
    openHref(image) {
      if (image.href) window.open(image.href, "_blank")
    },
  },
}
</script>

<style scoped lang="less">
.wrap {
  &.layout-list {
    &.gap-auto {
      picture {
        margin-bottom: 0.5rem;
      }
    }

    // https://lesscss.org/features/#mixins-feature-loops-feature
    .listGap(@n, @i: 1) when (@i =< @n) {
      &.gap-@{i} {
        picture {
          margin-bottom: (@i * 0.25rem);
        }
      }
      .listGap(@n, (@i + 1));
    }
    .listGap(10);

    picture {
      @apply block;
    }
  }

  &.layout-grid {
    @apply grid cursor-pointer;

    &.columns-auto {
      @apply grid-cols-1;

      @media (min-width: theme("screens.sm")) {
        @apply grid-cols-2;
      }

      @media (min-width: theme("screens.lg")) {
        @apply grid-cols-3;
      }
    }

    // https://lesscss.org/features/#mixins-feature-loops-feature
    .gridCols(@n, @i: 1) when (@i =< @n) {
      &.columns-@{i} {
        grid-template-columns: repeat(@i, minmax(0, 1fr));
      }
      .gridCols(@n, (@i + 1));
    }
    .gridCols(12);

    &.gap-auto {
      gap: 0.5rem;
    }

    // gap-N works, because it is included in tailwind
    // https://lesscss.org/features/#mixins-feature-loops-feature
    .gridGap(@n, @i: 1) when (@i =< @n) {
      &.xgap-@{i} {
        gap: (@i * 0.25rem);
      }
      .gridGap(@n, (@i + 1));
    }
    .gridGap(10);
  }

  &.layout-masonry {
    &.columns-auto {
      columns: 1;
      column-gap: 0;

      @media (min-width: theme("screens.sm")) {
        columns: 2;
        column-gap: 0.5rem;
      }

      @media (min-width: theme("screens.lg")) {
        columns: 3;
        column-gap: 0.5rem;
      }
    }

    // https://lesscss.org/features/#mixins-feature-loops-feature
    .masonryCols(@n, @i: 1) when (@i =< @n) {
      &.columns-@{i} {
        column-count: @i;
        // column-gap: (@i * 0.25rem);
      }
      .masonryCols(@n, (@i + 1));
    }
    .masonryCols(10);

    &.gap-auto {
      gap: 0.5rem;
      picture {
        margin-bottom: 0.5rem;
      }
    }

    // https://lesscss.org/features/#mixins-feature-loops-feature
    .masonryGap(@n, @i: 1) when (@i =< @n) {
      &.gap-@{i} {
        picture {
          margin-bottom: (@i * 0.25rem);
        }
      }
      .masonryGap(@n, (@i + 1));
    }
    .masonryGap(10);

    picture {
      @apply block;
    }
  }
}
</style>
