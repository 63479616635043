<template>
  <div
    :data-one-component="$options.name"
    v-bind="props.root"
    :style="{height: height + 'vh'}"
  ></div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "oneSpacer",
  mixins: [mixinClasses],
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "",
      },
    },
  }),
}
</script>
