export default {
  classes: {
    pfmHeading: {
      props: {
        level: {
          2: "text-sm",
        },
      },
    },
    pfmButton: {
      base: "+ rounded-full",
      // base: 'lowercase', // override
      // base: '+ lowercase', // extension
      props: {
        type: {
          // primary: 'bg-primary xs:rounded-full hover:bg-primary-light',  /// override
          // secondary: '+ border-2 border-black'  // extension
        },
      },
    },
    pfmProductListingItem: {
      subcomponents: {
        price: {
          // base: 'text-xs'
        },
      },
    },
    pfmCover: {
      // base: 'bg-white',
      layout: {
        // container: '+ relative flex items-center justify-center h-screen',
        // logoWrap: '+ absolute bottom-0 pr-5 pb-12 w-3/6 sm:w-2/6',
        // background: 'absolute w-5/6'
      },
    },
    pfmHeader: {
      layout: {
        headingWrap: "border-b border-solid border-black p-10 text-center",
      },
    },
  },
}
