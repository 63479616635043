<template>
  <div class="one-section-logo-cloud" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer
      v-bind="props.container"
      :override="props.container"
      :width="containerWidth"
      :verticalPadding="verticalPadding"
      :contentAlign="contentAlign"
    >
      <div
        class="w-1/2 p-8 sm:w-1/4 md:w-1/5 md:p-8 lg:w-1/6 lg:p-8"
        v-for="(logo, index) in items"
        :key="index"
      >
        <!-- logo with link -->
        <a :href="getHref(logo.href)" target="_blank" v-if="logo.href">
          <OneImage :src="logo.image.src" :image="logo.image" :imageId="logo.image.imageId" />
        </a>

        <!-- only logo -->
        <OneImage
          :src="logo.image.src"
          :image="logo.image"
          :imageId="logo.image.imageId"
          v-if="!logo.href"
        />
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneLogoCloud",
  mixins: [mixinClasses],
  props: {
    items: {
      type: Array,
    },
    variant: {
      type: Number,
      default: 1,
    },
    headingColor: {
      // type: String
    },
    captionColor: {
      // type: String
    },
    contentAlign: {
      type: String,
      default: "center",
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex-wrap flex flex-row items-center justify-center",
      },
      // card: {
      //   class: "pb-5 md:pb-0 last:pb-0",
      // },
    },
  }),
  computed: {
    // cardCount() {
    //   return this.cards ? this.cards.length : 0
    // },
    verticalPadding() {
      return this.variant == 2 ? 0 : 5
    },
    containerWidth() {
      return this.variant == 2 ? "full" : "constrained"
    },
  },
  methods: {
    getHref(href) {
      if (href.startsWith("http")) {
        return href
      } else {
        return `https://${href}`
      }
    },
  },
}
</script>
