<template>
  <!-- youtube -->
  <OneSingleVideoYoutube
    :override="override"
    :code="code"
    :videoKind="videoKind"
    :autoplay="autoplay"
    :mute="mute"
    :controls="controls"
    :allowFullscreen="allowFullscreen"
    :loop="loop"
    v-if="service == 'youtube'"
  />
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneSingleVideo",
  mixins: [mixinClasses],
  props: {
    service: {
      type: String,
    },
    code: {
      type: String,
    },
    videoKind: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    mute: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
