import {useAppStore} from "@/stores/app"

// init siteVar for admin mode
export default {
  install() {
    const appStore = useAppStore()
    const query = window.location.search
    const urlParams = new URLSearchParams(query)
    const mode = urlParams.get("mode")

    const siteId = urlParams.get("siteId")
    const shopId = urlParams.get("shopId") || false

    if (mode) appStore.app.mode = mode

    if (!window.site) window.site = {}
    if (siteId) window.site.id = siteId
    if (shopId) window.site.shopId = shopId

    // development json - when published, the same information is available in window.site aswell
    if (import.meta.env.VITE_ENVIRONMENT == "local_dev") {
      // DESIGN SYSTEM MODE uncomment to toggle design system mode
      // appStore.app.mode = "design"

      // this is only for client mode - admin mode sends this information from parent frame
      //

      // now picked via DevelopmentPanel.vue
      if (localStorage.getItem("@platformaone/client/mode/forcedSite")) {
        let ls = JSON.parse(localStorage.getItem("@platformaone/client/mode/forcedSite"))
        window.site = ls
      } else {
        if (mode != "admin") {
          window.site = {
            id: "fhG0HNTsYSCUIXNbbGAy",
            shopId: "cmLBWshKY9N2mMY3hPsD",
            lang: {
              current: "cs",
            },
          }
        }
      }
    }
  },
}
