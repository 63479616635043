<template>
  <div class="pass-protected-block-login">
    <div class="form">
      <OneInput
        v-model="pass"
        name="pass"
        label="Kód"
        helpText="Sem vlož kód pro odemknutí obsahu"
        placeholder=""
        kind="input"
        inputType="text"
        inputEvent="onkeyup"
        :required="true"
      />

      <OneButton
        :class="[{shake: ['failed', 'invalid'].includes(this.passwordState)}]"
        @update:click="requestBlockContents"
      >
        <div v-if="['working'].includes(this.passwordState)">
          <div class="animation-wrap loading">
            <OneIcon icon="Loading" />
          </div>
        </div>
        <div v-if="this.passwordState == 'init'">
          <span>odemknout</span>
        </div>
        <div v-if="this.passwordState == 'failed'">
          <span>špatný kód</span>
        </div>
      </OneButton>
    </div>
  </div>
</template>

<script>
// instructions:
// add config.passwordProtected = true to desired block
// add collection 'passwordProtection' to desired block
// add document 'default'
// add field 'password'
// eg. sites/{siteId}/blocks/{blockId}/passwordProtection/default {password:'tralala'}

import {functions} from "@/firebase/index.js"
import {httpsCallable} from "firebase/functions"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

const getContentsFn = httpsCallable(functions, "calls-sites-getPassProtectedBlockContents")

export default {
  name: "pfmPassProtectedBlockLogin",
  props: {
    blockId: {
      type: String,
    },
    pageId: {
      type: String,
    },
  },
  emits: ["password-valid"],
  setup() {
    // stores
    const siteStore = useSiteStore()

    // states
    const {id: siteId} = storeToRefs(siteStore)

    return {
      siteId,
    }
  },
  data: () => ({
    pass: null,
    passwordState: "init",
  }),
  methods: {
    async requestBlockContents() {
      this.passwordState = "working"

      let response = await getContentsFn({
        siteId: this.siteId,
        blockId: this.blockId,
        pass: this.pass,
      })

      // console.log('response:', response)

      if (response.data.code == "bad-password") {
        this.passwordState = "failed"
        setTimeout(() => {
          this.passwordState = "init"
        }, 1000)
        return false
      }

      this.siteUpdateBlock({
        block: {
          id: this.blockId,
          pageId: this.pageId,
          content: response.data.blockContents.content,
        },
      })

      this.$emit("password-valid")
    },
  },
}
</script>

<style scoped lang="less">
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.pass-protected-block-login {
  padding: 30px 20px 30px 20px;

  .form {
    // max-width: 50vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid #acacac;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 30px;

    .pfm-cart-input-field {
      width: 100%;
      margin-bottom: 30px;

      .main {
        .input-wrap {
          input {
            font-family: var(--font-family-secondary) !important;
          }
        }
      }
    }

    button {
      .animation-wrap.loading {
        display: flex;

        svg {
          animation: spin 1s linear infinite;
          width: 24px;
          height: 24px;

          :deep(*) {
            fill: #ffffff;
          }
        }
      }
    }
  }
}
</style>
