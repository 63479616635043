import cloneDeep from "lodash/cloneDeep"

export const resetState = ({store}) => {
  const initialState = cloneDeep(store.$state)

  // console.log("saved store initial state:", store.$id, JSON.parse(JSON.stringify(initialState)))

  // $reset is natively only available with Options API, but i'm using Composition API (setup)
  store.$reset = (key) => {
    // reset specific key
    if (key) {
      store.$patch((state) => (state[key] = cloneDeep(initialState[key])))
    }
    // reset entire store
    else {
      store.$patch((state) => {
        // console.log(
        //   "resetting entire store with initialState:",
        //   JSON.parse(JSON.stringify(initialState))
        // )
        Object.keys(initialState).forEach((key) => {
          state[key] = JSON.parse(JSON.stringify(initialState))[key]
        })
      })
    }
  }
}
