import {router} from "@/router"
import Langs from "@/langs"
import {useSiteStore} from "@/stores/site.js"
import axios from "axios"
import sentry from "@/plugins/sentry"

// regular client web view
export default {
  install(app) {
    const siteStore = useSiteStore()

    axios
      .get(`${import.meta.env.VITE_URL_STATIC}/sitevar/${window.site.id}`, {withCredentials: false})
      .then((res) => {
        // console.log("fetched siteVar:", res.data, window.site.id)
        // res.data.lang.current = window.site.lang.current // this does not seem to be used

        Langs.setLang(res.data.lang.current)

        siteStore.setSiteVar({siteVar: res.data})

        router.init(app)

        app.use(router.r())
        app.use(sentry)
        app.mount("#app")
      })
      .catch((err) => {
        console.error(err)
      })
  },
}
