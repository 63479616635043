<template>
  <div
    class="one-element-form-toggle"
    :class="[`value-${modelValue}`, {disabled: disabled}, `size-${size}`, `mode-${mode}`]"
  >
    <!-- <div
    class='label'
    v-if="label"
  >
    <span v-html="label"></span>
  </div> -->

    <OneLabel :text="label" v-if="label" />

    <div class="toggle-wrap" :class="`align-${align}`">
      <span class="label-state" v-html="labelFalse" v-if="labelFalse"></span>

      <div class="toggle" :class="[`value-${modelValue}`]" @click="toggle">
        <div class="switch">
          <OneIcon icon="Tick" class="icon-tick" v-if="modelValue" key="value-true" />
          <OneIcon icon="X" class="icon-x" v-if="!modelValue && mode == 'view'" key="value-false" />
        </div>
      </div>

      <span class="label-state" v-html="labelTrue" v-if="labelTrue"></span>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid"
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    labelFalse: {
      type: String,
      default: null,
    },
    labelTrue: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: "center", // left | center | right
    },
    size: {
      type: [String, Number],
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  methods: {
    getLang(key, option) {
      if (typeof option[key] === "string") {
        return option[key]
      } else if (typeof option[key] === "object") {
        return option[key]["cs"]
      }
    },
    toggle() {
      if (!this.disabled) {
        // console.log("toggle", !this.modelValue)
        this.$emit("update:modelValue", !this.modelValue, {fieldId: this.componentUid})
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-toggle {
  &.disabled {
    .toggle {
      pointer-events: none;

      .switch {
        background-color: @pfm-color-neutral-400 !important;
      }
    }
  }

  &.size-small {
    .toggle-wrap {
      .toggle {
        width: 54px;
        height: 28px;
        padding: 3px;
        border-radius: 8px;

        &.value-true {
          .switch {
            margin-left: 26px;
          }
        }

        .switch {
          height: 22px;
          width: 22px;
          border-radius: 6px;

          svg.icon-tick {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  &.size-default {
    .toggle-wrap {
      .toggle {
        width: 68px;
        height: 36px;
        padding: 4px;
        border-radius: 10px;

        &.value-true {
          .switch {
            margin-left: 32px;
          }
        }

        .switch {
          height: 28px;
          width: 28px;
          border-radius: 6px;

          svg.icon-tick {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  &.size-large {
    .toggle-wrap {
      .toggle {
        width: 100px;
        height: 54px;
        padding: 6px;
        border-radius: 10px;

        &.value-true {
          .switch {
            margin-left: 46px;
          }
        }

        .switch {
          height: 42px;
          width: 42px;
          border-radius: 6px;

          svg.icon-tick {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  &.mode-view {
    .toggle-wrap {
      .toggle {
        background: none;
        pointer-events: none;

        &.value-true {
          .switch {
            margin-left: 0;
          }
        }

        .switch {
          background: none;

          svg.icon-tick {
            :deep(*) {
              fill: @pfm-color-neutral-900;
            }
          }
        }
      }
    }
  }

  // .label {
  //     text-align: center;
  //     width: 100%;
  //     padding: 15px 0 10px;
  //     font-weight: bold;
  //     text-transform: uppercase;
  //     letter-spacing: 0.1rem;
  //     font-size: 0.8rem;
  //     display: block;
  //
  //     span {}
  // }

  span.label {
    padding-left: 8px;
    margin-bottom: 4px;
    display: inline-block;
    color: @pfm-color-neutral-600;
  }

  .toggle-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.align-left {
      justify-content: flex-start;
    }

    &.align-center {
      justify-content: center;
    }

    &.align-right {
      justify-content: flex-end;
    }

    span.label-state {
      margin: 15px;
      font-weight: 500;
      font-size: 0.75rem;
      color: @pfm-color-neutral-900;
    }

    .toggle {
      background: @pfm-color-neutral-300;
      cursor: pointer;
      transition: background 0.25s ease-in-out;

      &:hover {
        background: @pfm-color-neutral-400;

        .switch {
          background: @pfm-color-neutral-800;
        }
      }

      &.value-false {
        margin-left: 0;

        .switch {
          svg.icon-tick {
            opacity: 0;
          }
        }
      }

      &.value-true {
        .switch {
          svg.icon-tick {
            opacity: 1;
          }
        }
      }

      .switch {
        background-color: @pfm-color-neutral-700;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: margin 0.25s ease-in-out;

        svg.icon-tick {
          transition: opacity 0.25s ease-in-out;

          :deep(*) {
            fill: #fff;
          }
        }
      }
    }
  }
}
</style>
