<template>
  <component :is="componentFile" v-bind="props.root" @click="onClick" />
</template>

<script>
import mixinClasses from "../../mixins/classes"
import {defineAsyncComponent} from "vue"
/* eslint-disable-next-line */
const icons = import.meta.glob("../../icons/**.svg")

export default {
  name: "OneIcon",
  mixins: [mixinClasses],
  props: {
    size: {
      type: String,
      _setsClass: true,
      default: "default",
      validator: (val) => ["custom", "xs", "small", "default", "large"].includes(val),
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "default",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:click"],
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        // class: ' children:fill-primary-dark w-12 h-12'
        class: "",
        propClass: {
          link: {
            true: "cursor-pointer hover:opacity-75 transition-all duration-300 ease-in-out",
            false: "",
          },
          size: {
            custom: "",
            xs: "w-6 h-6",
            small: "w-8 h-8",
            default: "w-12 h-12",
            large: "w-14 h-14",
          },
          color: {
            default: "xdark:children:fill-white", // disabled: this breaks many things
            white: "children:fill-white",
            danger: "children:fill-danger",
            warning: "children:fill-warning",
            success: "children:fill-success",
            primary: "children:fill-primary",
            secondary: "children:fill-secondary",
            tertiary: "children:fill-tertiary",
            100: "children:fill-neutral-100",
            200: "children:fill-neutral-200",
            300: "children:fill-neutral-300",
            400: "children:fill-neutral-400",
            500: "children:fill-neutral-500",
            600: "children:fill-neutral-600",
            700: "children:fill-neutral-700",
            800: "children:fill-neutral-800",
            900: "children:fill-neutral-900",
          },
        },
      },
    },
  }),
  computed: {
    componentName() {
      return `${this.icon}`
    },
    componentFile() {
      return defineAsyncComponent(() => icons[`../../icons/${this.componentName}.svg`]())
    },
  },
  methods: {
    onClick() {
      this.$emit("update:click")
    },
  },
}
</script>
