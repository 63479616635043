<!-- component for displaying contents of a passed object. ideal for viewing configs in DB -->

<template>
  <div class="one-element-typo-codeblock" :class="{folded: folded}">
    <OneLabel :text="labelText" />

    <div class="wrap">
      <OneButtonFold :folded="folded" @update:click="folded = !folded" v-if="showFoldButton" />

      <OneLoading size="s" v-if="loading" />
      <pre ref="code">{{ content }}</pre>
    </div>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneObjectBlock",
  mixins: [mixinClasses],
  props: {
    content: {
      type: [Object, Array, String, Number],
    },
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    folded: false,
    showFoldButton: false,
    defaults: {
      commonClasses: "",
      root: {
        class: ``,
        propClass: {},
      },
    },
  }),
  computed: {
    labelText() {
      // if (this.labelKey) {
      //   return Langs.get(this.labelKey)
      // } else {
      return this.label
      // }
    },
  },
  mounted() {
    this.autoEnableFoldButton()
  },
  methods: {
    autoEnableFoldButton() {
      // // console.log('ref:', this.$refs.code.clientHeight)
      if (this.$refs.code.clientHeight > 100) {
        this.showFoldButton = true
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-typo-codeblock {
  &.folded {
    .wrap {
      max-height: inherit;
    }
  }

  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }

  .wrap {
    max-height: 100px;
    background: @pfm-color-neutral-900;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow-x: scroll;
    position: relative;

    .one-element-button-fold {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    pre {
      font-family: monospace;
      font-size: 1rem;
      line-height: 1rem;
      color: @pfm-color-neutral-300;
    }
  }
}
</style>
