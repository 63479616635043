<template>
  <div class="one-element-form-color-picker" :class="[`mode-${mode}`]">
    <div v-if="mode == 'edit'">
      <OneLabel :text="label" v-if="label" />
      <div class="options flex flex-row flex-wrap w-full">
        <div
          class=""
          v-for="option in opts"
          :class="[{disabled: option.disabled}]"
          :key="option.value"
          @click="selectOption(option)"
        >
          <div
            class="p-1 border border-neutral-200 hover:border-neutral-400 rounded-full mr-1 mt-1"
            :class="[{'border-neutral-600': modelValue == option.value}]"
          >
            <div
              class="item cursor-pointer block w-6 h-6 rounded-full"
              :style="{backgroundColor: `#${option.value}`}"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mode == 'view'">
      <OneLabel :text="label" v-if="label" />
      <div
        class="p-1 item cursor-pointer block w-6 h-6 rounded-full"
        :style="{backgroundColor: `#${modelValue}`}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OneColorPicker",
  props: {
    modelValue: {
      type: [String, Number],
    },
    mode: {
      type: String,
      default: "edit",
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
    },
    size: {
      type: [String, Number],
      default: "m",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    opts() {
      if (this.options) return this.options
      else {
        // default colors
        return [
          {
            name: "",
            value: "ff0000",
          },
          {
            name: "",
            value: "00ff00",
          },
          {
            name: "",
            value: "0000ff",
          },
        ]
      }
    },
  },
  created() {},
  methods: {
    selectOption(option) {
      if (option.disabled || option.value == this.modelValue) return
      // console.log('selectOption', value)
      this.$emit("update:modelValue", option.value)
    },
  },
}
</script>
