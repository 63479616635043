<script setup>
// TODO: rewrite mixins/classes to composable

import {ref, computed, inject, onMounted} from "vue"
import {storeToRefs} from "pinia"

const props = defineProps({
  href: {
    type: String,
  },
  kind: {
    type: String,
    default: "page",
  },
  url: {
    type: String,
  },
  pageId: {
    type: String,
  },
  path: {
    type: String,
  },
  switchLangTo: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const route = inject("VUE_ROUTER_USE_ROUTE")
const router = inject("VUE_ROUTER_USE_ROUTER")

const defaults = ref({
  commonClasses: "",
  root: {
    class: "block w-full",
    propClass: {
      disabled: {
        true: "pointer-events-none",
        false: "cursor-pointer pointer-events-auto",
      },
    },
  },
})

const siteDefaultLang = ref(false)

const routerLinkTo = computed(() => {
  // console.log("routerLinkTo:", {dv: defaults.value, route, router, props})
  if (defaults.value.root.is != "RouterLink") return ""

  // generic link, follow current lang
  let lang = route && route.meta && route.meta.lang ? route.meta.lang : null

  // lang switch link, change lang
  if (props.switchLangTo) lang = props.switchLangTo

  let to = {}

  if (props.switchLangTo) {
    to.name = `${route.meta.id}-${lang}`
  } else if (props.pageId) {
    if (siteDefaultLang.value == lang) lang = "default"
    to.name = `${props.pageId}-${lang}`
  } else {
    if (siteDefaultLang.value == lang) lang = null
    to.path = lang ? `/${lang}` + props.path : props.path
  }

  return to
})

if (props.href || (props.kind == "url" && props.url)) {
  defaults.value.root.is = "a"
  defaults.value.root.href = props.href ? props.href : props.url
  console.log("we are here", defaults.value.root.href)
  if (
    !defaults.value.root.href.startsWith("http://") &&
    !defaults.value.root.href.startsWith("https://") &&
    !defaults.value.root.href.startsWith("tel:") &&
    !defaults.value.root.href.startsWith("mailto:") &&
    !defaults.value.root.href.startsWith("#") &&
    !defaults.value.root.href != "/"
  ) {
    defaults.value.root.href = "https://" + defaults.value.root.href
  }
  if (defaults.value.root.href != "/" && !defaults.value.root.href.startsWith("#")) {
    defaults.value.root.target = "_blank"
  }
} else if ((props.path || props.pageId) && router) {
  defaults.value.root.is = "RouterLink"
} else {
  defaults.value.root.is = "a"
}

onMounted(async () => {
  // platforma.one admin/client specific:
  try {
    const {useSiteStore} = await import("@/stores/site.js")

    // stores
    const siteStore = useSiteStore()
    // console.log("Link.vue:", siteStore, this)

    // getters
    const {siteLangDefault} = storeToRefs(siteStore)

    siteDefaultLang.value = siteLangDefault.value

    // console.log("Link.vue: siteDefaultLang:", siteDefaultLang.value)
  } catch (e) {
    console.warn("@/stores/site.js not available")
  }
})
</script>

<template>
  <component
    :is="defaults.root.is"
    :to="routerLinkTo"
    :data-one-component="$options.name"
    v-bind="defaults.root"
  >
    <slot />
  </component>
</template>
