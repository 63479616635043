// services:
// - facebook pixel
// - google analytics

//     tracked events (platforma uses same naming as gtag):
//     kind     | action (pfm)     | action (gtag)    | action (facebook pixel)
// ----------------------------------------------------------------------------
// [ ] app      | screen_view      | screen_view      | PageView
// [ ] app      | download_file    | -                | -
// [x] commerce | view_item        | view_item        | ViewContent
// [x] commerce | add_payment_info | add_payment_info | AddPaymentInfo
// [x] commerce | add_to_cart      | add_to_cart      | AddToCart
// [x] commerce | purchase         | purchase         | Purchase
// [ ] commerce | remove_from_cart | remove_from_cart | -
// ... there are more, implement later

// if site uses gtag or facebook pixel, it was already installed indexTemplate.js when publishing, so it is globally available

import {useShopCartStore} from "@/stores/shopCart"

export default {
  event(action, params) {
    console.log("sink event:", action, params)

    // TODO: hardcoded
    let lang = "cs"

    // commerce
    let isCommerce = [
      "view_item",
      "add_payment_info",
      "add_to_cart",
      "purchase",
      "remove_from_cart",
    ].includes(action)

    if (isCommerce) {
      if (params && params.item) {
        var item = {
          sku: params.item.sku,
          title: params.item.desc.title[lang],
          quantity: 1,
          price: 123,
        }
      }

      const shopCartStore = useShopCartStore()
      var cart = shopCartStore.cart
    }

    // add to cart
    if (action == "add_to_cart") {
      gte({
        action,
        params: {
          items: [
            {
              item_id: item.sku,
              item_name: item.title,
              quantity: item.quantity,
              price: item.price,
            },
          ],
        },
      })

      fpe({
        action: "AddToCart",
        params: {
          content_ids: [item.sku],
          content_name: item.title,
          content_type: "product",
          contents: [
            {
              id: item.sku,
              quantity: item.quantity,
            },
          ],
        },
      })
    }
    // add payment info
    else if (action == "add_payment_info") {
      console.log("add_payment_info triggered")
      gte({
        action,
      })

      fpe({
        action: "AddPaymentInfo",
      })
    }
    // purchase
    else if (action == "purchase") {
      var cartItemsGTE = cart.items.map((i) => {
        let itemPrice = {}
        let itemPriceKind = i.snapshot.config.priceKind

        if (itemPriceKind == "fixed") {
          itemPrice = i.snapshot.price.find(
            (price) => price.currency == cart.currency && price.model == "fixed"
          )
        } else if (itemPriceKind == "voluntary") {
          itemPrice = i.snapshot.price.find(
            (price) => price.currency == cart.currency && price.model == "voluntary"
          )
        } else if (itemPriceKind == "package") {
          itemPrice = i.snapshot.price.find(
            (price) =>
              price.currency == cart.currency && price.model == "package" && price.units == i.count
          )
        }

        return {
          item_id: i.snapshot.sku,
          item_name: i.snapshot.desc.title[lang],
          currency: cart.currency,
          price: parseInt(itemPrice.netto),
          quantity: parseInt(i.count),
        }
      })

      let FPEcontentIds = cart.items.map((i) => i.snapshot.sku)
      let FPEcontents = cart.items.map((i) => {
        return {id: i.snapshot.sku, quantity: i.count}
      })

      gte({
        action,
        params: {
          currency: cart.currency,
          transaction_id: params.order.orderNumber,
          value: parseInt(cart.total.brutto),
          shipping: parseInt(cart.delivery.price.brutto),
          tax: parseInt(cart.total.brutto - cart.total.netto),
          items: cartItemsGTE,
        },
      })

      fpe({
        action: "Purchase",
        params: {
          content_ids: FPEcontentIds,
          contents: FPEcontents,
          currency: cart.currency,
          value: parseInt(cart.total.brutto),
        },
      })
    }
    // view item
    else if (action == "view_item") {
      gte({
        action,
        params: {
          items: [
            {
              item_id: item.sku,
              item_name: item.title,
              quantity: item.quantity,
              price: item.price,
            },
          ],
        },
      })

      fpe({
        action: "ViewContent",
        params: {
          content_ids: [item.sku],
          content_name: item.title,
          content_type: "product",
          contents: [
            {
              id: item.sku,
              quantity: item.quantity,
            },
          ],
        },
      })
    }
    // file downloaded`
    else if (action == "download_file") {
      gte({
        action,
        params: {
          fileName: params.fileName,
          fileId: params.fileId,
          buttonLabel: params.buttonLabel,
        },
      })
    }
  },
}

// google analytics tag
// default events reference: https://developers.google.com/gtagjs/reference/ga4-events
// recommended events: https://support.google.com/analytics/answer/9267735?hl=en
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
function gte(args) {
  if (typeof gtag !== "function") return

  let action = args.action
  let params = args.params

  /* eslint-disable-next-line */ // gtag is global
  gtag("event", action, params)
}

// facebook pixel
// default events reference: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
function fpe(args) {
  if (typeof fbq !== "function") return

  let action = args.action
  let params = args.params

  /* eslint-disable-next-line */ // fbq is global
  fbq("track", action, params)
}
