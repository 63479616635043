<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- card -->
    <!-- <a :href="props.link.href"
      data-one-label="cardWrap"
      v-bind="props.link"
      > -->
    <OneLink v-bind="props.link" :disabled="linkDisabled">
      <!-- image & caption wrap -->
      <div data-one-label="logoWrap" v-bind="props.logoCaptionWrap">
        <!-- logo -->
        <OneImage v-bind="props.logo" v-if="props.logo && props.logo.src" />

        <!-- heading -->
        <OneHeading
          v-bind="props.heading"
          :color="headingColor"
          align="center"
          v-if="props.heading && props.heading.content"
        />

        <!-- caption -->
        <OneParagraph
          v-bind="props.caption"
          :color="captionColor"
          v-if="props.caption && props.caption.content"
        />
      </div>

      <!-- background image -->
      <OneImage
        v-bind="props.backgroundImage"
        v-if="props.backgroundImage && props.backgroundImage.src"
        :preload="true"
      />
    </OneLink>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneCard1",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    cardCount: {
      type: Number,
      default: 1,
    },
    headingColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        propClass: {
          cardCount: {
            _common: "w-full",
            1: "",
            2: "md:w-1/2 md:first:pr-2 md:last:pl-2",
            // 3: "md:w-1/2 md:first:pr-2 md:last:pl-2",
            3: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            4: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            5: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            6: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            7: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            8: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            9: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            10: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            11: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            12: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            13: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            14: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            15: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            16: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            17: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            18: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            19: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
            20: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:py-2",
          },
        },
      },
      link: {
        class:
          "z-10 relative overflow-hidden w-full flex-center rounded-lg hover:opacity-75 transition-all duration-300 ease-in-out",
        style: {
          height: "50vh",
        },
      },
      backgroundImage: {
        class: "absolute inset-0",
        maxWidth: "full",
        minHeight: "full",
      },
      logoCaptionWrap: {
        class: "relative z-10 flex flex-col",
        // style: {
        //   width: '20%'
        // }
      },
      logo: {
        fit: "contain",
      },
      caption: {
        align: "center",
        color: "white",
        class: "whitespace-nowrap",
      },
    },
  }),
  computed: {
    linkDisabled() {
      return this.props.link.href ||
        this.props.link.url ||
        this.props.link.pageId ||
        (this.props.link.path && this.props.link.path.length)
        ? false
        : true
    },
  },
}
</script>
