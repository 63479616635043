<template>
  <div
    class="one-section-menu"
    :data-one-component="$options.name"
    v-bind="props.root"
    :style="menuStyle"
  >
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- lang switch -->
      <div v-if="!showHamburger && !showBackButton" v-bind="props.langWrap">
        <OneLink
          v-bind="{...props.menuLang, path: `/${lang.code}`, switchLangTo: lang.code}"
          v-for="(lang, index) in siteLangsFull"
          :key="`lang-${index}`"
          >{{ lang.title }}</OneLink
        >
      </div>

      <!-- hamburger -->
      <div v-bind="props.hamburgerWrap" v-if="showHamburger">
        <!-- commerce: cart button -->
        <OneButton
          type="icon"
          size="auto"
          class="mr-4"
          v-bind="props.cartButton"
          :icon="cartButtonIcon"
          v-if="showCartButton && showHamburger"
          @update:click="onCartBtnClick()"
        />

        <!-- hamburger: closed menu -->
        <OneButton
          type="icon"
          size="auto"
          icon="Hamburger1"
          key="Hamburger1"
          v-bind="props.hamburger"
          v-if="showHamburger && !menuOpen"
          @update:click="menuOpen = true"
        />
        <!-- hamburger: opened menu -->
        <OneButton
          type="icon"
          size="auto"
          icon="Hamburger1Close"
          key="Hamburger1Close"
          v-bind="props.hamburger"
          v-if="showHamburger && menuOpen"
          @update:click="menuOpen = false"
        />
      </div>

      <!-- back button -->
      <OneButton
        type="icon"
        size="auto"
        v-bind="props.backButton"
        icon="ArrowLeft"
        v-if="showBackButton"
        @update:click="onBackBtnClick()"
      />

      <!-- flex filler if back button disabled -->
      <span class="btn-back-fill" v-if="showBackBtnFill"></span>

      <!-- logo -->
      <OneImage
        class="logo"
        v-bind="props.logo"
        v-if="props.logo && props.logo.src"
        @click="onLogoClick()"
      />

      <!-- links -->
      <div class="flex flex-row" v-bind="props.menubarLinks">
        <OneLink
          v-bind="{...props.menubarItem, ...item}"
          v-for="(item, index) in menuItems"
          :key="`item-${index}`"
          >{{ item.title }}</OneLink
        >

        <!-- lang switch -->
        <div class="pl-8">
          <OneLink
            v-bind="{...props.menuLang, path: `/${lang.code}`, switchLangTo: lang.code}"
            v-for="(lang, index) in siteLangsFull"
            :key="`lang-${index}`"
            class="opacity-50"
            >{{ lang.title }}</OneLink
          >
        </div>
      </div>

      <!-- commerce: cart button -->
      <OneButton
        type="icon"
        size="auto"
        v-bind="props.cartButton"
        icon="Cart1"
        v-if="showCartButton && !showHamburger"
        @update:click="onCartBtnClick()"
      />

      <!-- flex filler if cart button disabled -->
      <span class="btn-cart-fill" v-if="showCartBtnFill"></span>

      <!-- menu items -->
      <div class="menu-items" v-bind="props.menuItems" v-if="menuOpen" :style="menuItemsStyle">
        <!-- links -->
        <OneLink
          v-bind="{...props.menuItem, ...item}"
          v-for="(item, index) in menuItems"
          :key="`item-${index}`"
          >{{ item.title }}</OneLink
        >

        <span class="lang-separator" v-if="siteLangsFull && siteLangsFull.length > 0"></span>

        <!-- lang switch -->
        <OneLink
          v-bind="{...props.menuLang, path: `/${lang.code}`, switchLangTo: lang.code}"
          v-for="(lang, index) in siteLangsFull"
          :key="`lang-${index}`"
          class="opacity-50"
          >{{ lang.title }}</OneLink
        >
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneMenu3",
  mixins: [mixinClasses],
  props: {
    openedSize: {
      type: String,
      default: "small",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    showHamburgerButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showCartButton: {
      type: Boolean,
      default: false,
    },

    // positions
    hamburgerWrapPosition: {
      type: String,
      default: "right",
    },
    cartButtonIcon: {
      type: String,
      default: "Cart1",
    },
    cartButtonPosition: {
      type: String,
      default: "right",
    },
    logoPosition: {
      type: String,
      default: "center",
    },
    backButtonPosition: {
      type: String,
      default: "left",
    },

    keepLogoCentered: {
      type: Boolean,
      default: true,
    },
    menuItems: {
      type: Array,
    },
    background: {
      type: String,
      default: "transparent",
    },
    backgroundOnScroll: {
      type: String,
      default: "transparent",
    },
    currentLang: {
      type: String,
    },
    siteLangs: {
      type: Array,
      default: () => ["cs", "en"],
    },
  },
  emits: ["update:logoClick", "update:backBtnClick", "update:cartBtnClick"],
  data: () => ({
    prevScrollPos: 0,
    menuStyle: {
      top: null,
    },
    menuItemsStyle: {},
    menuOpen: false,
    menuVisible: true,
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full transition-all duration-500 ease-in-out",
        propClass: {
          fixed: {
            true: "fixed top-0 z-50",
            false: "relative z-0",
          },
          // background: {
          //   white: 'bg-white',
          //   transparent: '',
          //   black: 'bg-black'
          // }
        },
      },
      container: {
        class: "grid grid-cols-auto grid-rows-1",
        contentLayout: "grid",
        contentAlign: "none",
      },
      langWrap: {
        class: "col-span-1 row-start-1 row-span-1 self-center",
        propClass: {},
      },
      hamburgerWrap: {
        class: "flex flex-row justify-center items-center col-span-1 row-start-1 row-span-1 z-20",
        propClass: {
          hamburgerWrapPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      hamburger: {
        class: "md:hidden",
        propClass: {},
      },
      backButton: {
        class: "col-span-1 row-start-1 row-span-1",
        propClass: {
          backButtonPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      logo: {
        class: "cursor-pointer col-span-1 row-start-1 row-span-1 flex-center",
        propClass: {
          logoPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
        fit: "contain",
        style: {
          maxWidth: "150px",
          maxHeight: "20px",
          alignSelf: "center",
        },
      },
      cartButton: {
        class: "col-span-1 row-start-1 row-span-1",
        propClass: {
          cartButtonPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      menuItems: {
        class: "",
        propClass: {
          openedSize: {
            small: "absolute top-0 flex flex-col mt-20 px-2 xs:px-4 sm:px-6 xl:px-32 pb-5",
            fullscreen:
              "absolute top-0 w-screen h-screen z-10 flex flex-col justify-center items-center text-center ",
          },
          hamburgerWrapPosition: {
            // this is intentinal - to align the menu items with the hamburger icon
            left: "left-0 text-left",
            center: "left-0 text-center w-full",
            right: "right-0 text-right",
          },
          background: {
            white: "bg-white",
            transparent: "",
          },
        },
      },
      menuItem: {
        class: "px-3 my-1 py-1",
        propClass: {
          openedSize: {
            small: "",
            fullscreen: "!w-auto",
          },
        },
      },
      menubarLinks: {
        class: "hidden md:flex md:justify-center",
        propClass: {},
      },
      menubarItem: {
        class: "flex text-white text-center justify-center items-center",
        propClass: {},
      },
      menuLang: {
        class: "px-3 my-1 py-1 text-dark",
      },
    },
  }),
  computed: {
    siteLangsFull() {
      let langLength = "long"
      let l = []
      let langsMap = {
        cs: {
          short: "cs",
          long: "česky",
        },
        en: {
          short: "en",
          long: "english",
        },
      }

      this.siteLangs.forEach((lang) => {
        if (lang != this.currentLang) {
          l.push({
            title: langsMap[lang][langLength],
            code: lang,
          })
        }
      })

      return l
    },
    showHamburger() {
      return this.showHamburgerButton && this.menuItems.length > 0
    },
    showBackBtnFill() {
      // return true
      return this.keepLogoCentered && !this.showBackButton && !this.showHamburger
    },
    showCartBtnFill() {
      return this.keepLogoCentered && !this.showCartButton && this.showHamburger
    },
  },
  created() {
    if (this.autohide) this.enableAutohide()
    this.setMenuBackground()
  },
  methods: {
    enableAutohide() {
      window.addEventListener("scroll", (e) => {
        this.scrolled(e)
        this.setMenuBackground()
      })
    },
    scrolled() {
      let currentScrollPos = window.pageYOffset
      // console.log('scrolled:', currentScrollPos, this.prevScrollPos, e)
      if (this.prevScrollPos >= currentScrollPos) {
        if (!this.menuVisible) this.showMenu()
      } else {
        if (this.menuVisible && this.prevScrollPos >= 0) this.hideMenu()
      }
      this.prevScrollPos = currentScrollPos
    },
    setMenuBackground() {
      let currentScrollPos = window.pageYOffset
      let background = null
      if (currentScrollPos <= 100) background = this.background
      else background = this.backgroundOnScroll

      // console.log('background:', background)

      let backgroundColor = null
      if (background == "transparent") backgroundColor = "transparent"
      else if (background == "black") backgroundColor = "#000000"
      else if (background == "white") backgroundColor = "#ffffff"
      else if (background == "black-glass") backgroundColor = "rgba(0,0,0,0.5)"
      else if (background == "white-glass") backgroundColor = "rgba(255,255,255,0.5)"
      this.menuStyle.backgroundColor = backgroundColor
      this.menuItemsStyle.backgroundColor = backgroundColor

      if (["black-glass", "white-glass"].includes(background)) {
        this.menuStyle.backdropFilter = "saturate(180%) blur(10px)"
        this.menuItemsStyle.backdropFilter = "saturate(180%) blur(10px)"
      }
    },
    showMenu() {
      // console.log('showMenu()')
      this.menuStyle.top = "0"
      this.menuVisible = true
    },
    hideMenu() {
      // console.log('hideMenu()')
      this.menuStyle.top = "-100px"
      this.menuVisible = false
      this.menuOpen = false
    },
    onLogoClick() {
      this.$emit("update:logoClick")
    },
    onBackBtnClick() {
      this.$emit("update:backBtnClick")
    },
    onCartBtnClick() {
      this.$emit("update:cartBtnClick")
    },
  },
}
</script>

<style scope lang="less">
.btn-back-fill,
.btn-cart-fill {
  width: 48px;
  height: 48px;
}
</style>
