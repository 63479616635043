<template>
  <div class="one-element-form-datetime">
    <OneLabel :text="label" v-if="label" />

    <div class="wrap" v-if="mode == 'edit'">
      <OneFormTime v-model="date" @update:modelValue="onInput" />

      <OneWidgetCalendar v-model="date" @update:modelValue="onInput" />
    </div>

    <div class="view" v-if="mode == 'view'">
      {{ dateString }}
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "OneFormDateTime",
  props: {
    modelValue: {
      type: [Date, Object],
    },
    label: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    date: {
      get() {
        if (this.modelValue && typeof this.modelValue.toDate === "function")
          return this.modelValue.toDate()
        else return this.modelValue
      },
      set() {},
    },
    time: {
      get() {
        return `${this.hours}:${this.minutes}`
      },
      set() {},
    },
    hours: {
      get() {
        let date
        if (this.modelValue && typeof this.modelValue.toDate === "function")
          date = this.modelValue.toDate()
        else date = this.modelValue
        return date.getHours()
      },
    },
    minutes: {
      get() {
        let date
        if (this.modelValue && typeof this.modelValue.toDate === "function")
          date = this.modelValue.toDate()
        else date = this.modelValue
        return date.getMinutes()
      },
    },
    dateString() {
      if (!this.modelValue) return
      return moment(this.modelValue.toDate()).format("YYYY-MM-DD HH:mm")
    },
  },
  created() {},
  methods: {
    onInput(v, meta) {
      // console.log('onInput typeof', typeof v)
      let vIsObject = typeof v === "object"
      let date = vIsObject ? moment(v) : moment(this.date)
      let time = vIsObject ? moment(this.time, "HH:mm") : moment(v, "HH:mm")
      let concat = date
        .set({
          hour: time.get("hour"),
          minute: time.get("minute"),
          second: 0,
          millisecond: 0,
        })
        .toDate()
      // console.log('onInput', v, meta, concat)
      this.$emit("update:modelValue", concat, meta)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-datetime {
  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }
  .wrap {
    background: @pfm-color-neutral-200;
    border-radius: 8px;
    padding: 4px;

    .one-element-form-input {
      width: 40%;
    }
  }

  .view {
    padding: 4px 4px 4px 8px;
  }
}
</style>
