export function evalItemVisibility(args) {
  let display = false // false (hidden) / available / unavailable (sold out) / preorder
  let c = args.config
  let qs = c.quantity.stock

  // stock tracking override
  if (c.showAs == "hidden") display = false
  if (c.showAs == "unavailable") display = "unavailable"
  if (c.showAs == "available" && !qs.trackingEnabled) display = "available"
  if (c.showAs == "preorder" && !qs.trackingEnabled) display = "preorder"

  // stock tracking enabled
  if (c.showAs == "auto" && qs.trackingEnabled) {
    if (qs.available) display = "available"
    else {
      if (qs.whenUnavailable == "hide") display = false
      if (qs.whenUnavailable == "restrict") display = "unavailable"
      if (qs.whenUnavailable == "preorder") display = "preorder"
    }
  }

  return display
}
