// local storage cart version mismatch check
// this resets cart if there was an app update and locally stored cart is older

export default {
  install() {
    let lsCart = localStorage.getItem("@platformaone/store/commerce/cart")

    console.log("checking cart version", window.site.shopId, lsCart != null)
    if (window.site.shopId && lsCart) {
      let lss = JSON.parse(lsCart)
      let cartVersion = lss && lss.version ? lss.version : false
      console.log("local storage cart version:", cartVersion, "app version:", "__VERSION__")
      if (cartVersion == null) {
        console.log("cart version is null")
      } else if (cartVersion == undefined) {
        console.log("cart version is undefined")
      } else if (cartVersion == false) {
        console.log("cart version is false")
      } else if (cartVersion != "__VERSION__") {
        console.error("cart version mismatch -> resetting cart", cartVersion, "__VERSION__")
        localStorage.removeItem("@platformaone/store/commerce/cart")
        location.reload()
      } else if (cartVersion == "__VERSION__") {
        console.log("cart version matches app version")
      }
    }
  },
}
