<template>
  <!-- <section class='block' :class="[`component-${componentName}`,`allow-${allow}`,{'block-admin-tools-open': blockAdminToolsOpen}, {'block-admin-tools-open-selected': block.id == blockAdminToolsOpenBlockId}]"> -->
  <section class="block" :class="[`component-${componentName}`, `allow-${allow}`]">
    <block-admin
      :block="block"
      :blockIndex="blockIndex"
      :last="last"
      :pageId="pageId"
      v-if="mode == 'admin'"
    />
    <div :class="classes()" v-if="allow">
      <slot>
        <component :is="componentFile" :id="`block_${block.id}`" :block="block" />
      </slot>
    </div>
    <div v-if="!allow">
      <pass-protected-block-login
        :blockId="block.id"
        :pageId="pageId"
        @password-valid="passwordValid = true"
      />
    </div>
  </section>
</template>

<script>
import camelCase from "camelcase"
import BlockAdmin from "@/components/BlockAdmin.vue"
import PassProtectedBlockLogin from "@/components/elements/main/PassProtectedBlockLogin.vue"
import {defineAsyncComponent} from "vue"
import {useClasses} from "@/composables/classes.js"
import {useAppStore} from "@/stores/app"
import {storeToRefs} from "pinia"

export default {
  name: "pfmBlockNative",
  props: {
    block: {
      type: Object,
      default: () => {
        return {}
      },
    },
    blockIndex: {
      type: Number,
    },
    last: {
      type: Boolean,
    },
    pageId: {
      type: String,
    },
  },
  components: {
    BlockAdmin,
    PassProtectedBlockLogin,
  },
  setup(props) {
    // stores
    const appStore = useAppStore()
    const {app} = storeToRefs(appStore)

    const Classes = useClasses({props})
    const {classes} = Classes

    return {
      app,
      classes,
    }
  },
  data: () => ({
    kind: null,
    defaultClasses: {
      // base: 'm-auto px-2 xs:px-4 sm:px-6 xl:px-32 py-5'
    },
    passwordValid: false,
  }),
  computed: {
    componentName() {
      if (!this.block.nativeBlock) return "lala"
      return camelCase(this.block.nativeBlock.replace("/", "-"))
    },
    componentFile() {
      let nb = this.block.nativeBlock
      let nbKind = nb.split("/")[0]
      let nbName = nb.split("/")[1]
      // capitalize first letter
      nbName = nbName.charAt(0).toUpperCase() + nbName.slice(1)
      return defineAsyncComponent(() => import(`@/components/blocks/${nbKind}/${nbName}.vue`))
    },
    mode() {
      return this.app.mode
    },
    allow() {
      if (this.block.config && this.block.config.passwordProtected) {
        if (this.passwordValid) return true
        else return false
      } else {
        return true
      }
    },
  },
  created() {
    if (this.app.mode == "design") {
      return
    }
  },
}
</script>

<style scoped lang="less">
section.block {
  position: relative;

  &.component-mainVideoYoutube {
    &.allow-true {
      background-color: #212121;
    }
  }
}
</style>
