<template>
  <div class="one-section-cards" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer
      v-bind="props.container"
      :override="props.container"
      :width="containerWidth"
      :verticalPadding="verticalPadding"
    >
      <!-- cards -->
      <template v-if="variant != 3">
        <OneCard
          :variant="variant"
          :headingColor="headingColor"
          :captionColor="captionColor"
          :cardCount="cardCount"
          v-for="(card, index) in cards"
          :key="index"
          :index="index"
          :override="card"
          v-bind="props.card"
        />
      </template>

      <div class="col-1 w-1/2" v-if="variant == 3">
        <template v-for="(card, index) in cards">
          <OneCard
            :variant="variant"
            :headingColor="headingColor"
            :captionColor="captionColor"
            :cardCount="cardCount"
            :key="index"
            :index="index"
            :override="card"
            v-bind="props.card"
            v-if="index % 2 == 0"
          />
        </template>
      </div>

      <div class="col-1 w-1/2" v-if="variant == 3">
        <template v-for="(card, index) in cards">
          <OneCard
            :variant="variant"
            :headingColor="headingColor"
            :captionColor="captionColor"
            :cardCount="cardCount"
            :key="index"
            :index="index"
            :override="card"
            v-bind="props.card"
            v-if="index % 2 == 1"
          />
        </template>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneCards",
  mixins: [mixinClasses],
  props: {
    cards: {
      type: Array,
    },
    variant: {
      type: Number,
      default: 1,
    },
    headingColor: {
      // type: String
    },
    captionColor: {
      // type: String
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        // class: 'flex-wrap flex flex-row items-center justify-center'
      },
      card: {
        class: "pb-5 md:pb-0 last:pb-0",
      },
    },
  }),
  created() {
    if (this.variant == 1)
      this.defaults.container = {class: "flex-wrap flex flex-row items-center justify-center"}
  },
  computed: {
    cardCount() {
      return this.cards ? this.cards.length : 0
    },
    verticalPadding() {
      return this.variant == 2 ? 0 : 5
    },
    containerWidth() {
      return this.variant == 2 ? "full" : "constrained"
    },
  },
}
</script>
