<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <div
        class="w-full flex flex-col"
        :class="[
          {'md:flex-row-reverse': imageAlign == 'right'},
          {'md:flex-row': imageAlign == 'left'},
        ]"
      >
        <!-- image -->
        <div class="w-full md:w-1/2">
          <OneImage :src="imageSrc" :applySvgMask="imageBlobMask" />
        </div>

        <!-- text -->
        <div
          class="w-full md:w-1/2"
          :class="[
            {'pl-8 pr-8': imageAlign == 'right'},
            {'pl-8': imageAlign == 'left'},
            {'flex flex-col justify-center': textVerticalAlign == 'middle'},
            {'flex flex-col justify-end': textVerticalAlign == 'bottom'},
          ]"
        >
          <div
            class="flex flex-col"
            :class="[
              {'items-center': textAlign == 'center'},
              {'flex flex-row items-end': textAlign == 'right'},
            ]"
          >
            <OneHeading :level="headerLevel" :align="textAlign" :content="header" />
            <OneParagraph :align="textAlign" :content="paragraph" />
            <OneButton
              class="mt-8"
              :class="[{'self-start': textAlign == 'left'}, {'self-center': textAlign == 'center'}]"
              :label="buttonLabel"
              :kind="link.kind"
              :pageId="link.pageId"
              :url="link.url"
              v-if="link.kind != 'none'"
            />
          </div>
        </div>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneImageText",
  mixins: [mixinClasses],
  props: {
    imageSrc: {
      type: String,
    },
    imageAlign: {
      type: String,
    },
    imageBlobMask: {
      type: Boolean,
    },
    header: {
      type: String,
    },
    headerLevel: {
      type: Number,
    },
    paragraph: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    link: {
      type: Object,
    },
    textAlign: {
      type: String,
      default: "left",
    },
    textVerticalAlign: {
      type: String,
      default: "top",
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {},
    },
  }),
}
</script>
