<template>
  <div class="slider">
    <div class="value">{{ formattedValue }}</div>

    <div class="tape-container">
      <div class="main-wrap">
        <div class="main"></div>
      </div>
      <div class="overlay"></div>
      <div ref="tape" class="tape-wrapper">
        <IconTape class="tape-section" v-for="n in 1000" :key="`IconTape-${n}`" />
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css"
import mixinClasses from "../../../mixins/classes"
import IconTape from "../../../icons/Tape.svg?component"

export default {
  name: "OneValueSlider",
  mixins: [mixinClasses],
  components: {
    IconTape,
  },
  props: {
    min: {
      type: Number,
      // default: null
      default: 0,
    },
    max: {
      type: Number,
      // default: null
      default: 999999,
    },
    initial: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    value: null,
    scrollLeft: null,
    lastScrollLeft: null,
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
        propClass: {},
      },
    },
  }),
  emits: ["update:modelValue"],
  computed: {
    formattedValue() {
      return this.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    },
  },
  created() {
    this.setInitialValue()
  },
  mounted() {
    if (this.$refs && this.$refs.tape) {
      this.$refs.tape.addEventListener("scroll", this.onTapeScroll)
      this.$refs.tape.addEventListener("mousewheel", this.onTapeMousewheel)
    }
  },
  methods: {
    setInitialValue() {
      if (this.initial) this.value = this.initial
      else this.value = this.min
    },
    onTapeScroll() {
      // // console.log('onTapeScroll', e)
      this.updateValue()
    },
    onTapeMousewheel(e) {
      // // console.log('onTapeMousewheel', e)
      // // console.log('onTapeMousewheel', e.clientX, e.deltaX, e.movementX, e.offsetX, e.pageX, e.screenX, e.x)

      // prefent browser back swipe when at minimum
      if (this.scrollLeft == 0 && e.deltaX < 0) e.preventDefault()
    },
    updateValue() {
      let sensitivity = 2
      let tape = this.$refs.tape
      let left = tape.scrollLeft
      let width = tape.scrollWidth
      this.scrollLeft = left

      // round it
      left = Math.round(left)

      let newValue = Math.round((left / width) * 10000 * sensitivity + this.min)
      this.value = newValue
      // console.log('updateValue()', width, left, newValue)

      // // scrolling right (more)
      // if (left > this.lastScrollLeft) {
      //   this.value = this.value + diff
      // }
      //
      // // scrolling left (less)
      // if (left < this.lastScrollLeft) {
      //   let newValue = this.value - diff
      //   if (this.min && newValue < this.min) this.value = this.min
      //   else this.value = newValue
      // }

      this.lastScrollLeft = left
      this.onChange()
    },
    onChange() {
      this.$emit("update:modelValue", this.value)
    },
  },
}
</script>

<style lang="less">
.slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .value {
    font-size: 3rem;
  }

  .tape-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    // border: 1px solid #ff0000;

    .overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 80;
      pointer-events: none;
      background: linear-gradient(
        90deg,
        var(--color-background) 0%,
        hsla(var(--color-background-h), var(--color-background-s), var(--color-background-l), 0) 50%,
        var(--color-background) 100%
      );
    }

    .main-wrap {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      pointer-events: none;

      .main {
        width: 4px;
        height: 40px;
        background: var(--color-primary-base);
      }
    }

    .tape-wrapper {
      overflow-x: scroll;
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      // flex-direction: column;
      // justify-content: flex-end;
      scroll-snap-type: x mandatory;
      align-items: flex-end;
      scroll-padding: 50%;
      cursor: ew-resize;

      &::-webkit-scrollbar {
        display: none;
      }

      svg.tape-section {
        margin-right: 10px;
        display: inline-block;
        width: 60px;
        height: 20px;
        flex-shrink: 0;
        scroll-snap-align: center;
      }
    }
  }
}
</style>
