<template>
  <div class="one-element-button-fold" @click="onClick">
    <OneIcon icon="ChevronDown" v-if="!folded" key="chevron-down" />
    <OneIcon icon="ChevronUp" v-if="folded" key="chevron-up" />
  </div>
</template>

<script>
// import mixinClasses from '../../mixins/classes'

export default {
  name: "OneButtonFold",
  // mixins: [mixinClasses],
  props: {
    folded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:click"],
  methods: {
    onClick() {
      this.$emit("update:click")
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-button-fold {
  display: flex;
  cursor: pointer;

  &:hover {
    svg {
      :deep(*) {
        fill: @pfm-color-neutral-200;
      }
    }
  }

  svg {
    width: 22px;
    height: 22px;

    * {
      fill: @pfm-color-neutral-600;
    }
  }
}
</style>
