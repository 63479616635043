export default {
  text: {
    "auth-signIn-magicLinkSentTo": "přihlašovací odkaz odeslán na",
    "auth-signIn-magicLinkWrongEmail": "špatný e-mail?",
    "auth-signIn": "přihlásit se",
    "auth-signOut": "odhlásit se",

    "account-header": "můj účet",

    "account-videos-header": "videa",

    // countries
    "country-CZ": "Česká republika",
    "country-SK": "Slovensko",
    "country-AT": "Rakousko",
    "country-DE": "Německo",

    "buy-for": "koupit za",
    buy: "koupit",
    "preorder-for": "předobjednat za",
    preorder: "předobjednat",
    "fill-form-first": "vyplň formulář",
    "sold-out": "vyprodáno",
    "cart-remove": "odebrat",
    "cart-price-free": "zdarma",
    "cart-price-from": "od",
    "cart-orders-closed": "objednávky uzavřeny",
    "cart-slot-full": "slot je plný",
    "cart-pick-less-items": "vyber méně věcí",
    "cart-continue-shopping": "pokračovat v nákupu",
    "cart-order-in": "objednej do",
    "cart-calendar-mo": "po",
    "cart-calendar-tu": "út",
    "cart-calendar-we": "st",
    "cart-calendar-th": "čt",
    "cart-calendar-fr": "pá",
    "cart-calendar-sa": "so",
    "cart-calendar-su": "ne",
    "cart-calendar-yday": "včera",
    "cart-calendar-today": "dnes",
    "cart-calendar-tmrw": "zítra",
    "cart-form-promocode-invalid": "Neplatný kód",
    "cart-form-optional": "nepovinné",
    "cart-form-mistyped-something": "nepřekelpl/a ses?",
    "cart-form-street-number-missing": "Bez čísla ulice to nepůjde",
    "cart-form-address-restricted": "Na tuto adresu bohužel nevozíme",
    "cart-form-phone-missing": "bez telefonu to nepůjde",
    "cart-form-email-missing": "bez e-mailu to nepůjde",
    "cart-form-name-missing": "bez jména to nepůjde",
    "cart-form-surname-missing": "potřebujeme jméno a příjmení",
    "cart-form-street-missing": "bez ulice to nepůjde",
    "cart-form-city-missing": "bez města to nepůjde",
    "cart-form-city-invalid": "takhle město nevypadá",
    "cart-form-postal-missing": "bez PSČ to nepůjde",
    "cart-form-postal-invalid": "takhle PSČ nevypadá",
    "cart-order-pay": "zaplatit",
    "cart-order-order-for": "objednat za",
    "cart-order-failed-something-wrong": "něco je špatně",
    "cart-order-check-invalid-fields": "zkontroluj chybná pole",
    "cart-payment-cardonline-processedby":
      "Kartu zpracovává <a href='%0' target='_blank'>%1</a>,<br>my její údaje nevidíme.",

    // if you change this, change the admin translation file
    "cart-main-title": "Košík",
    "cart-header-backToShop": "Zpět do obchodu",
    "cart-itemList-empty": "Nic tu není. 😔 Něco si vyber 🙃",
    "cart-promoCode-title": "Slevový kód",
    "cart-promoCode-insert": "použít slevový kód",
    "cart-promoCode-field-promoCode-label": "Máte kód slevového kódu?",
    "cart-promoCode-field-promoCode-placeholder":
      "Kouzelné heslo, které způsobí, že i při utrácení ušetříte. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRAKADABRA",
    "cart-customerLogin-signInWithPasskey": "Přihlásit se Klíčem",
    "cart-customerLogin-or": "nebo",
    "cart-customerLogin-signInWithEmail": "použít e-mail",
    "cart-customerLogin-signIn": "přihlásit se",
    "cart-customerLogin-signOut": "odhlásit se",
    "cart-customerSignUp-registerPasskey": "vytvořit Klíč",
    "cart-customerSignUp-nextTimeUsePasskey": "Příště se přihlaš pomocí klíče.",
    "cart-customerSignUp-nextTimeUseMagicLink": "Příště se přihlaš pomocí e-mailu.",
    "cart-customerSignUp-usePasswordInstead": "raději nastavit heslo",
    "cart-delivery-title": "Doručení",
    "cart-delivery-branch-title": "Pobočka",
    "cart-delivery-undeliverableItemsCombination":
      "Kombinaci produktů nelze doručit. Objednej, prosím, zvlášť.",
    "cart-delivery-deliveryOptions": "Možnosti doručení",
    "cart-customerInfo-title": "Moje údaje",
    "cart-customerInfo-actionButtons-addBillingInfo": "Zadat fakturační údaje",
    "cart-customerInfo-fields-customerEmail-label": "E-mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "petr@novak.cz",
    "cart-customerInfo-fields-customerEmail-helpText":
      "Tvůj e-mail potřebujeme, abychom Tě mohli informovat o stavu objednávky",
    "cart-customerInfo-fields-customerName-label": "Jméno a příjmení",
    "cart-customerInfo-fields-customerName-placeholder": "Petr Novák",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Telefon",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "Tvůj telefon potřebujeme, abychom Tě mohli rychle kontaktovat, pokud se objeví nečkaná situace.",
    "cart-billingInfo-title": "Fakturace",
    "cart-billingInfo-actionButtons-fillManually": "Vyplnit ručně",
    "cart-billingInfo-actionButtons-companyAddress": "Na firmu",
    "cart-billingInfo-fields-billingAddress-label": "Adresa",
    "cart-billingInfo-fields-billingAddress-placeholder": "Hlavní 1, Praha",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Ulice",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Hlavní 1, Praha",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "Město",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Praha",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "PSČ",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "IČO",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Firma",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Poznámka na fakturu",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Dárek pro šéfovou",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Doručení",
    "cart-deliveryInfo-actionButtons-fillManually": "Vyplnit ručně",
    "cart-deliveryInfo-actionButtons-companyAddress": "Doručit na firmu",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Jiné fakturační údaje",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Přidat fakturační údaje",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "pro mě",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "jako dárek",
    "cart-deliveryInfo-fields-recipientName-label": "Jméno a příjmení",
    "cart-deliveryInfo-fields-recipientName-placeholder": "Petr Novák",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Telefon",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText":
      "Telefon příjemce potřebujeme, aby jej kurýr mohl kontaktovat.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresa",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Hlavní 1, Praha",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Ulice",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Hlavní 1, Praha",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "Město",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Praha",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "PSČ",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "IČO",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 45 678",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Firma",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Poznámka pro kurýra",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "První patro",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Vzkaz pro příjemce",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "Užij si dárek!",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-contactless-labelTrue": "Bezkontaktní doručení",
    "cart-deliverySlots-noMoreDaysAvailable": "další dny zatím<br>nemáme 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "Bohužel nemáme<br>žádné sloty 😔",
    "cart-note-title": "Poznámka",
    "cart-note-insert": "Přidat poznámku",
    "cart-note-fields-note-label": "Ještě něco na srdci?",
    "cart-note-fields-note-placeholder": "Kámen!",
    "cart-note-fields-note-helpText":
      "Budeme se snažit splnit modré z nebe! Ale taky jsme jen lidi, žejo 😜",
    "cart-payment-title": "Platba",
    "cart-stripeWallet-otherMethod": "Jiný způsob",
    "cart-stripeWallet-total": "Celkem",
    "cart-orderButton-terms-pre": "Platbou souhlasím s",
    "cart-orderButton-terms-link": "obchodními podmínkami",
    "cart-confirmation-orderNumber-heading": "objednávka",
    "cart-confirmation-email-heading": "potvrzení",
    "cart-confirmation-merchantNote": "Těšíme se na příště!",
    "cart-confirmation-continueShoppingBtn": "ještě něco? 😜",
    "cart-confirmation-watchVideoNow": "spustit video",
    "cart-confirmation-downloadNow": "stáhnout",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Děkujeme",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Tvá objednávka se už vyřizuje.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Děkujeme",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Tvá objednávka se už vyřizuje a brzy ji odešleme příjemci.",

    "delivery-method-constraints-label": "Možnosti doručení",
    "delivery-method-constraints-min-dispatch-time": "Odeslání nejdříve za",
    "delivery-method-constraints-shipping-from": "Odeslání od",
    "delivery-method-constraints-shipping-to": "Odeslání do",

    main_title: "Administrace",

    "menu-pages": "Stránky",
    "menu-commerce": "Obchod",
    "menu-analytics": "Analytics",
    "menu-settings": "Nastavení",
    "menu-help": "Nápověda",
    "menu-logout": "Odhlásit",
    "menu-publisher": "Vydavatel",
    "menu-designer": "Designer",

    "sites-main-headline": "Moje stránky",

    "option-yes": "Ano",
    "option-no": "Ne",

    monthNames: [
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srpen",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec",
    ],
    dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
    dayNamesShort: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],

    "login-header": "Přihlášení",
    "login-header-label": "Přihlásit se zde...",
    "login-user-name": "Přihlašovací jméno",
    "login-user-password": "Heslo",
    "login-user-email": "Přihlašovací email",
    "login-submit-button": "Přihlásit se",
    "login-help": "Pomoc",
    "login-use-pwd": "Použít heslo",
    "login-use-pwdless": "Použít odkaz do e-mailu",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Špatné heslo",
    "login-error-too-many-requests": "Příliš mnoho pokusů, zkuste později.",
    "login-validator-passwordlessemail-errormessage-notvalid": "Skoro. Není tam nějaký přelkep?",
    "login-validator-passwordlessemail-errormessage-notfound": "Nenašli jsme tě. Existuješ?",
    "login-validator-passwordlessemail-errormessage-empty": "Sem by to chtělo napsat e-mail",
    "login-validator-passwordemail-errormessage-bad": "Tohle",
    "login-validator-passwordemail-errormessage-empty": "Notak, buď trochu přesnější.",
    "login-validator-passwordemail-errormessage-notvalid": "Takhle nevypadá e-mail!",
    "login-validator-password-errormessage-bad": "nebo toto není správně.",
    "login-validator-password-errormessage-empty": "No tak, hesla jsou důležitá!",
    "login-email-instructions-headline": "Mrkni do e-mailu",
    "login-email-sent-instructions-pt1": "Na e-mail",
    "login-email-sent-instructions-pt2": "letí přihlašovací tlačítko.",
    "login-email-sent-instructions-pt3": "Tohle okno můžeš zavřít.",
    "login-email-instructions-tryagain": "Zkusit znovu",

    "guest-signup-email-instructions-headline": "Hurá! Už jen jeden krok k tvému webu",
    "guest-signup-email-sent-instructions-pt1":
      "Potvrzovací odkaz ke zveřejnění tvé stránky jsme poslali na",
    "guest-signup-email-sent-instructions-pt2":
      "Toto okno můžeš zavřít. V mailu klikni na odkaz, který otevře nové.",
    "guest-signup-published-headline": "A je to!",
    "guest-signup-published-text": "Tvá stránka běží na",
    "guest-signup-published-open-btn": "Podívej se na ni",

    "main-site-menu": "Hlavní nabídka",

    "settings-header": "Nastavení",
    "site-config-title": "Název webu",

    "pages-header": "Stránky",
    "page-list": "Seznam stránek",
    "main-view-site": "Ukázat náhled",
    "page-selected-layout": "Rozložení",
    "page-selected-content": "Obsah",
    "page-selected-settings": "Nastavení",
    "page-selected-styles": "Vzhled",
    "page-setup-title": "Titulek",
    "page-setup-role": "Pořebná role",
    "page-setup-description": "Popis",
    "page-setup-slugs": "Slugs",
    "page-setup-visible": "Viditelné",
    "page-button-delete": "Smazat stránku",
    "page-add-hint": "Vyberte bod vložení",
    "page-duplicate": "Duplikovat",
    "page-duplicate-parent": "Nadřazená stránka",
    "page-duplicate-new-title": "Název nové stránky",
    "page-duplicate-execute": "Staniž se",
    "page-clone-fill_name": "Doplňte název stránky pro jazyk: ",
    "page-clone-parent_no_slug": "Nadřazená stránkanemá vyplněný slug",
    "page-clone-slag_for_lang": "Nadřazená stránkanemá vyplněný slug pro jazyk: ",
    "page-clone-done": "Duplikace se zdařila.",
    "page-clone-error": "Duplikace selhala!",
    "page-block-styling": "Stylování",
    "label-add-language-version": "Přidat verzi pro jazyk",

    "commerce-header": "E-shop",
    "commerce-shop-name": "Název obchodu",
    "commerce-no-site": "Nejprve vyberte web",
    "commerce-new-eshop": "Založit nový e-shop",
    "commerce-orders": "Objednávky",
    "commerce-inventory": "Produkty",
    "commerce-shop-items": "Položky e-shopu",
    "commerce-customers": "Zákazníci",
    "commerce-subscriptions": "Subscriptions",
    "commerce-orders-setup": "Nastavení",
    "commerce-orders-flow-setup": "Nastavení stavů objednávek",
    "commerce-shop-create-model": "Vytvořit novou specifikaci formuláře produktu",
    "commerce-shop-update-model": "Upravit specifikaci formuláře produktu",
    "commerce-products-header": "Produkty",
    "commerce-product-attr-name": "Název pole",
    "commerce-product-model-name": "Název typu produktu",
    "commerce-shop-create-product": "Vytvoření nového produktu",
    "commerce-shop-update-product": "Změna detailů produktu",
    "commerce-product-header": "Produkt",
    "commerce-fee-reports": "Poplatky",
    "commerce-no-products-available": "žádné produkty",
    "commerce-display-all": "další",
    "commerce-go-to-cart": "do košíku",
    "product-created-info": "Produkt by vytvořen.",
    "product-not-created-error": "Problém zabránil vytvoření produktu!",
    "product-saved-info": "Produkt byl upraven.",
    "product-not-saved-error": "Problém zabránil upravení produktu!",
    "product-question-delete": "Opravdu chcete odebrat produkt (včetně variant)?",
    "product-image-main": "Hlavní produktu",
    "product-image-variant": "Hlavní varianty",
    "product-fees": "Poplatky",
    "upload-file-in-process": "Probíhá nahrávání",
    "commerce-setup": "Nastavení vlastností",
    "commerce-supported-curr": "Podporované měny",
    "commerce-default-curr": "Výchozí měna",
    "commerce-supported-langs": "Podporované jazyky",
    "commerce-default-lang": "Výchozí jazyk",
    "commerce-order-num-format": "Formát čísla objednávky",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Prodejní jednotky",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bankovní spojení",
    "commerce-bank-name": "Název banky",
    "commerce-bank-code": "Číslo banky",
    "commerce-account-number": "Číslo účtu",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Adresa společnosti",
    "commerce-setup-payments": "Nasvatení plateb",
    "commerce-payment-methods": "Platební metody",
    "commerce-payment-method-add": "Přidat další metodu platby",
    "commerce-tax-values": "Povolené sazby daně",
    "commerce-delivery-planning": "Doručovací sloty",
    "commerce-delivery-channels": "Podporované doručovací metody",
    "commerce-setup-deliveries": "Nastavení doručování",
    "commerce-delivery-method-add": "Přidat další metodu doručení",
    "commerce-setup-delivery-planning-settings": "Nastavení doručovacího plánu",
    "commerce-setup-delivery-planning": "Doručovací plán",
    "commerce-delivery-next-day-order": "Last next-day order at:",
    "commerce-delivery-next-days": "Zobrazit nasledující dny:",
    "commerce-delivery-use-defaults": "Použít šablonu",
    "commerce-delivery-add-slot": "+ Přidat okno",
    "commerce-delivery-additional-fields": "Dodatečná pole na formuláři košíku",
    "commerce-delivery-add-field": "+ Přidat pole",
    "commerce-delivery-setup": "Plán doručování",
    "commerce-delivery-default-slots": "Šablona slotů",
    "commerce-setup-delivery-planning-slots": "Aktivní sloty",
    "commerce-delivery-settings": "Nastavení",
    "commerce-flow-cmd-set": "Nastavit hodnotu",
    "commerce-flow-cmd-exe": "Provést operaci",
    "commerce-flow-operation-request-liftago": "Objednat Liftago",
    "commerce-terms-agreement":
      'Platbou souhlasím s&nbsp;<a target="_blank" href="%0">obchodními&nbsp;podmínkami</a>',
    "commerce-product-not-found": "Produkt neexistuje",
    "commerce-product-video-vod-full-avilable-after-purchase":
      "Celé video bude k přehrání ihned po zakoupení v zákaznické sekci.",

    "stripe-error-card-expired": "Karta expirovala. Použijte jinou kartu.",
    "stripe-decline-card-expired": "Karta expirovala. Použijte jinou kartu.",
    "stripe-decline-auth-required": "Platba vyžaduje autorizaci. Zkuste znovu.",
    "stripe-decline-try-again": "Zkuste znovu nebo kontaktujte Vaši banku.",
    "stripe-decline-contact-bank": "Kontaktujte Vaši banku.",
    "stripe-decline-duplicate": "Duplicitní platba",
    "stripe-decline-incorrect-number": "Chybné číslo karty",
    "stripe-decline-incorrect-cvc": "Chybné CVC",
    "stripe-decline-incorrect-expiry": "Chybná expirace",
    "stripe-decline-insufficient-funds": "Zkontrolujte stav svého účtu.",
    "stripe-decline-test-card": "Nepoužívejte testovací kartu.",
    "stripe-decline-test-mode-live-card": "Ostrá karta použita v testovacím módu",

    "address-street": "Ulice",
    "address-number": "Číslo popisné",
    "address-area": "Oblast",
    "address-city": "Město",
    "address-zip": "PSČ",
    "address-region": "Region",
    "address-country": "Země",
    "commerce-invoice-disclaimer": "Informativní text faktury",

    "product-field-title": "Název",
    "product-field-description": "Popis",
    "product-tags": "Tagy",
    "product-sku": "SKU",
    "product-options": "Možnosti",
    "product-images": "Fotografie",
    "product-quantity": "Sklad & viditelnost",
    "product-shipping": "Logistika",
    "product-shipping-available-deliveries": "Dostupné způsoby dopravy",
    "product-channels": "Prodejní cesty",
    "system-settings": "Systémová nastavení",
    "other-settings": "Ostatní",
    "channel-online": "Online",
    "product-files": "Soubory",
    "add-a-new": "Přidat nový:",
    "button-add-variant": "Přidat variantu",
    "button-add-product": "Produkt",
    "button-add-option": "+ Přidat možnost",
    "button-add-attribute": "+ Přidat atribut",
    "product-field-type": "Typ",
    "product-field-name": "Název",
    "question-product-opt-delete": "Opravdu si přejete smazat tuto možnost?",
    "question-product-product-delete": "Opravdu si přejete smazat tento produkt?",
    "question-product-product-variants-delete":
      "Opravdu si přejete smazat tento produkt a jeho varianty?",
    "product-enable-stock-track": "Sledování skladu",
    "product-min-order": "Minimální množství objednávky:",
    "product-when-sold-out": "Po vyprodání",
    "product-on-sold-hide": "Skrýt",
    "product-on-sold-disable": "Zakázat",
    "product-on-sold-preorder": "Předobjednat",
    "product-show-as": "Viditelnost",
    "product-show-as-hide": "Skrytý",
    "product-show-as-unavailable": "Nedostupný",
    "product-show-as-available": "Dostupný",
    "product-show-as-auto": "Podle skladu",
    "product-show-stock-counts": "Zobrazovat počet na skladu",
    "product-pricing": "Cena",
    "product-enable-reservation": "Povolit reservování",
    "product-reservation-timeout": "Doba rezervování zboží k obědnání",
    "commerce-pricing-base": "Základ",
    "commerce-pricing-tax": "Daň",
    "commerce-pricing-final": "Celkem",
    "commerce-pricing-purchased": "Pořizovací cena",
    "commerce-pricing-commission": "Provize",
    "commerce-pricing-profit": "Profit",
    "shipping-weight": "Váha [kg]",
    "shipping-dimensions": "Rozměry [cm]",
    "question-product-file-attr-delete": "Opravdu si přejete smazat tento atribut?",
    "question-product-file-delete": "Opravdu si přejete smazat tento soubor?",
    "commerce-flow-delete-error": "Nelze smazat stav, protože je použitý v: [%0]",

    "preorder-starts-in": "předprodej začíná za",
    "preorder-ends-in": "předprodej končí za",
    "preorder-ended": "předprodej skončil",
    "preorder-error-tooSoon":
      "Objednávka obsahuje produkt v předprodeji, který ještě nezačal. Odeberte jej, nebo objednejte později.",
    "preorder-error-tooLate":
      "Objednávka obsahuje produkt v předprodeji, který již skončil. Odeberte produkt a objednejte znovu.",

    "signup-email": "Vaše emailová adresa",
    "signup-site-domain": "Jak nazveme vaši doménu?",
    "signup-done": "... zkontrolujte svoji e-mailovou schránku.",

    "products-prepared": "Připraveno -> Odeslat",

    "button-cancel": "Zrušit",
    "button-confirm": "Potvrdit",
    "button-remove": "Odebrat",
    "button-save-draft": "Uložit náčrt",
    "button-publish": "Publikovat",
    "button-publish-all": "Publikovat vše",
    "button-save": "Uložit",
    "button-add": "Přidat",
    "button-add-file": "Přidat soubor",
    "button-change-file": "Nahradit soubor",
    "button-add-font": "Přidat font",
    "button-delete": "Smazat",
    "button-all": "Vše",
    "button-signup": "Registrovat",

    // "order-incomming-new" : "Nová objednávka! [%0]",
    "order-incomming-new": "Nová objednávka!",
    "orders-customer": "Zákazník",
    "orders-products": "Obsah",
    "orders-shipping": "Doprava",
    "order-payment-not-paid": "Zatím neuhrazeno",
    "order-payment-paid": "Zaplaceno:",
    "order-customer-note": "Poznámka zákazníka",
    "order-customer-notify": "Informovat zákazníka",
    "order-note-add": "Přidat poznámku",
    "order-pay-products": "Produkty",
    "order-pay-delivery": "Doručení",
    "order-pay-payment": "Platba",
    "order-pay-discount": "Sleva",
    "order-pay-total": "Zákazník zaplatí",
    "order-pay-method-card-online": "Kartou online",
    "order-pay-method-card-site": "Kartou v obchodě",
    "order-pay-method-card-deliv": "Kartou při převzetí",
    "order-pay-method-cash-site": "V hotovosti v obchodě",
    "order-pay-method-cash-deliv": "V hotovosti při převzetí (dobírka)",
    "order-pay-method-bank-wire": "Bankovním převodem",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Vyzvednutí na pobočce",
    "order-shipping-method-messanger": "Kurýr",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilk": "Zásilkovna",
    "order-shipping-method-cpost": "Česká Pošta",
    "order-shipping-method-cpost-ob": "Česká Pošta - Obchodní balík",
    "order-shipping-method-custom": "Vlastní",
    "order-shipping-method-": "",

    oa_state_unpaid: "Nezaplaceno",
    oa_state_paid: "Zaplaceno",
    oa_state_preparing: "Příprava produktu",
    oa_state_printing: "Tisk štítků",
    oa_state_packing: "Balení",
    oa_state_dispatching: "K Odeslání",
    oa_state_sent: "Odesláno",
    oa_state_pickup: "K vyzvednutí",
    oa_state_refunding: "Náhrady",
    oa_state_cancelled: "Zrušeno",
    oa_state_archived: "Archivováno",
    oa_go_paid: "Bylo zaplaceno",
    oa_go_prepare: "Připravit produkty",
    oa_go_print: "Vytisknout štítky",
    oa_go_pack: "Zabalit zboží",
    oa_go_cancel: "Zrušit",
    oa_go_dispatch: "Předat",
    oa_go_send: "Odeslat",
    oa_go_pickup: "Možné vyzvednout",
    oa_go_refund: "Vrátit platbu",
    oa_go_archive: "Archivovat",
    oa_go_open: "Otevřít",

    "billing-address": "Fakturační adresa",
    "delivery-address": "Doručovací údaje",
    "order-courier-deliver-details": "Doručení",
    "order-courier-recipient-details": "Příjemce",
    "order-courier-dispatch": "Odeslání",

    "order-courier-ordered": "Kurýr objednán",
    "order-courier-dispatched": "Odesláno",

    "block-config-name": "Název bloku",
    "block-config-shared": "Sdílený",
    "block-config-region": "Region",
    "block-config-viewports": "Viewports",
    "block-config-tag": "Tag",

    "forms-field-id": "ID",
    "forms-field-type": "Typ pole",
    "target-window-same": "Stejné okno",
    "target-window-new": "Nové okno",

    "form-font-name": "Název fontu",
    "form-font-weight": "Font weight (CSS)",
    "form-font-url": "URL fontu",
    "form-font-source-file": "Soubor fontu",

    "page-create-new": "Nová stránka",
    "page-create-new-abort": "Přerušit přidávání",
    "page-create-new-info": "Nyní vyberte strnku nadřazenou nové",
    "page-new-name": "Nová stránka",
    "page-new-slug": "novastranka",
    "page-new-created": "Nová stránka byla vytvořena",
    "page-new-not-created": "Novou stránku se nepodařilo vytvořit! Více informací v konzili.",

    "shared-blocks-label": "Sdílené",
    "private-blocks-label": "Privátní",

    "settings-css-header": "Styly",
    "settings-scripts-header": "Scripty",
    "settings-config-header": "Nasvení stylů",
    "settings-fonts-header": "Fonty",

    "template-editor": "Šablony",
    "theme-label-fonts": "Fonty",
    "theme-label-scripts": "Soubory skriptů",

    "field-listing-source": "Zdroj dat",
    "field-listing-root-page": "Kořenová stránka",

    "question-publish-all": "Do you relly want to publish all?",
    "message-info-site-published": "Stránka byla publikována",
    "error-login-nonce-request":
      "Přihlášení se nezdařilo. Opakujte proces ještě jednou, a pokud to nepomůže, tak nás neváhejte kontaktovat.",
    "error-email-invlaid": "E-mail adresa není platná!",
    "error-email-not-found": "E-mail adresa nebyla nalezena!",
    "error-sitemName-already-used": "Tento název stránky je již použitý!",
    "error-email-already-used": "Tato e-mailová adresa je již použita!",
    "error-both-already-used": "Obě hodnoty jsou již použité!",
    "error-invlaid-values": "Formulář není řádně vyplněn!",

    // block selector
    sections: "sekce",
    "section-main": "hlavní",
    "section-commerce": "obchod",
    "section-shared": "sdílené",
    "section-custom": "vlastní",
    "section-cancel": "zrušit",
    "section-main-hero": "úvod",
    "section-main-space": "mezera",
    "section-main-separator": "oddělovač",
    "section-main-header": "nadpis",
    "section-main-cards": "karty",
    "section-main-button": "tlačítko",
    "section-main-footer": "patička",
    "section-main-menu": "menu",
    "section-main-gridList": "seznam",
    "section-main-logoCloud": "loga",
    "section-main-popup": "pop-up",
    "section-main-headerText": "text s nadpisem",
    "section-main-gallery": "galerie",
    "section-main-instagram": "instagram",
    "section-main-text": "text",
    "section-main-image": "fotka",
    "section-main-imageText": "fotka s textem",
    "section-main-video": "video",
    "section-main-team": "tým",
    "section-commerce-productListing": "seznam produktů",
    "section-commerce-productDetail": "produkt",
    "section-commerce-productPriceSlider": "produkt s dobrovolnou cenou",
    "section-commerce-productImages": "fotky produktu",

    "priceVoluntary-voluntary": "Jiná částka",
    "priceVoluntary-price": "dobrovolná částka",
    "giftCard-delivery": "Komu poslat?",
    "giftCard-dispatchToCustomer": "mně",
    "giftCard-dispatchToCustomer-info":
      "Ihned po zaplacení odešleme na e-mail zadaný v košíku A4 PDF k vytištění.",
    "giftCard-dispatchToRecipient": "obdarovanému",
    "giftCard-dispatchToRecipient-info":
      "Ihned po zaplacení odešleme e-mail s poukazem přímo obdarovanému.",
    "giftCard-design": "Vzhled",
    "giftCard-message": "Věnování",
    "giftCard-message-placeholder": "",
    "giftCard-download-preview": "Stáhnout náhled",
    "giftCard-recipient": "Pro",
    "giftCard-recipientName": "Jméno příjemce",
    "giftCard-recipientName-placeholder": "Petr",
    "giftCard-recipientEmail": "E-mail příjemce",
    "giftCard-recipientEmail-placeholder": "petr@obdarovany.cz",
    "giftCard-sender": "Od",
    "giftCard-senderName": "Jméno odesílatele",
    "giftCard-senderName-placeholder": "Anna",
    "giftCard-senderEmail": "E-mail odesílatele",
    "giftCard-senderEmail-placeholder": "anna@hodna.cz",
    "giftCard-deliverTo": "Doručit",
    "giftCard-language": "Jazyk",
    "digitalProduct-download-sample": "Stáhnout ukázku",
  },
}
