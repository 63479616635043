import {useAppStore} from "@/stores/app"

// screen resize listener
export default {
  install() {
    const appStore = useAppStore()

    // run on init
    updateViewportSize()

    // events
    window.addEventListener("resize", updateViewportSize)
    window.addEventListener("scroll", updateViewportScrollY)

    function updateViewportSize() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const {setViewportWidth, setViewportHeight} = appStore

      setViewportWidth(vw)
      setViewportHeight(vh)
    }

    function updateViewportScrollY() {
      appStore.app.scrollY = window.scrollY
    }
  },
}
