<template>
  <div class="one-element-form-radio" :class="[`layout-${layout}`, `mode-${mode}`]">
    <div v-if="mode == 'edit'">
      <div class="label" v-if="label">
        <span v-html="label"></span>
      </div>
      <div class="options w-full">
        <div
          class="item transition-all duration-500 ease-in-out cursor-pointer"
          v-for="option in options"
          :class="[
            {selected: modelValue == option.value},
            `columns-${columns}`,
            {disabled: option.disabled},
            `kind-${kind}`,
          ]"
          :key="option.value"
          @click="selectOption(option)"
        >
          <div class="radio-wrap">
            <label class="radio-label" v-if="option.key" v-localize="{key: option.key}"></label>
            <label class="radio-label" v-if="option.name">{{ getOptionName(option.name) }}</label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mode == 'view'">
      <OneLabel :text="label" v-if="label" />
      <span class="value">{{ selectedTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OneFormRadio",
  props: {
    modelValue: {
      type: [String, Number],
    },
    mode: {
      type: String,
      default: "edit",
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
    },
    layout: {
      type: String,
      default: "column", // column | grid | row
    },
    size: {
      type: [String, Number],
      default: "m",
    },
    columns: {
      type: Number,
      default: 0,
    },
    currentLang: {
      type: [Boolean, String],
      default: false,
    },
    kind: {
      type: String,
      default: "filled",
      validator: (val) => ["filled", "outlined", "ghost"].includes(val),
    },
  },
  emits: ["update:modelValue"],
  computed: {
    selectedTitle() {
      if (this.modelValue) {
        return this.options.find((o) => o.value == this.modelValue).name
      } else {
        return "---"
      }
    },
  },
  created() {},
  methods: {
    getOptionName(name) {
      if (typeof name === "object" && name !== null && this.currentLang) {
        return name[this.currentLang]
      } else {
        return name
      }
    },
    selectOption(option) {
      if (option.disabled || option.value == this.modelValue) return
      this.$emit("update:modelValue", option.value)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";
.one-element-form-radio {
  &.mode-view {
    padding: 0 0px 10px 8px;

    .label {
      text-align: left;
      padding: 0;
    }
  }

  &.layout-column {
    .options {
      .item {
        margin-right: 4px;
        margin-left: 4px;
      }
    }
  }

  &.layout-list {
    .options {
      .item {
        display: inline-block;
        width: initial;
        margin-right: 4px;
        margin-left: 4px;
      }
    }
  }

  &.layout-grid {
    .options {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: center;
      display: grid;
      @apply grid-cols-2 gap-2;

      // @media (min-width: 768px) {
      //   justify-content: flex-start;
      // }

      .item {
        // margin-right: 4px;
        // margin-left: 4px;

        // &.columns-2 {
        //   flex: 0 calc(50% - 8px);
        // }

        // &.columns-3 {
        //   flex: 0 calc(33.3% - 8px);
        // }

        // &.columns-4 {
        //   flex: 0 calc(25% - 12px);
        // }

        // &.columns-5 {
        //   flex: 0 calc(20% - 16px);
        // }

        .radio-wrap {
          // justify-content: center;
        }
      }
    }
  }

  .label {
    text-align: center;
    width: 100%;
    padding: 15px 0 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.8rem;
    display: block;

    span {
    }
  }

  .options {
    .item {
      width: 100%;
      // padding: 20px;
      // background-color: @pfm-color-neutral-300;
      border-radius: 7px;
      margin: 4px 0;
      cursor: pointer;
      // overflow: hidden;

      &.kind-filled {
        @apply bg-secondary;

        &:hover {
          @apply bg-secondary-dark;
          label.radio-label {
            color: #ffffff;
          }
        }

        &.selected {
          @apply bg-secondary-dark;
          height: auto;

          label.radio-label {
            color: #ffffff;
          }

          .radio-wrap {
          }
        }
      }

      &.kind-outlined {
        box-shadow: 0 0 0 1px theme("colors.neutral.400");

        &:hover {
          box-shadow: 0 0 0 2px theme("colors.neutral.400");
          @apply border-neutral-700;
        }

        &.selected {
          box-shadow: 0 0 0 2px theme("colors.secondary.dark");
        }
      }

      &.disabled {
        background-color: @pfm-color-neutral-100;
        cursor: not-allowed;

        label.radio-label {
          color: @pfm-color-neutral-400;
        }

        .radio-wrap {
        }
      }

      .radio-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 14px;

        .multiple-lines {
          .delivery-place {
            font-size: 0.75rem;
            display: block;
            color: @pfm-color-neutral-500;
            letter-spacing: 0;
          }
        }
      }

      input {
        display: none;
      }

      label.radio-label {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
        color: @pfm-color-neutral-700;
        pointer-events: none;
        text-transform: none;
        white-space: nowrap;
      }
    }
  }
}
</style>
