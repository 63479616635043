<template>
  <div class="one-section-footer-2" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- logo container -->
      <div v-bind="props.logoWrap">
        <!-- logo -->
        <OneImage
          v-bind="props.logo"
          :stylesConfig="{width: logo.width}"
          :src="logo.src"
          fit="cover"
          :imageId="logo.imageId"
          v-if="logo && logo.src"
        />

        <!-- caption -->
        <!-- <span
      v-bind="props.caption"
      v-if="caption"
      >{{caption}}</span> -->
        <OneParagraph :content="caption" v-bind="props.caption" v-if="caption"></OneParagraph>
      </div>

      <!-- contacts -->
      <div v-bind="props.contacts" v-if="phone || email">
        <!-- phone -->
        <OneLink v-bind="props.icon" :href="phone.href" v-if="phone">{{ phone.label }}</OneLink>

        <!-- email -->
        <OneLink v-bind="props.icon" :href="email.href" v-if="email">{{ email.label }}</OneLink>
      </div>

      <!-- icons -->
      <div class="icons" v-bind="props.icons">
        <!-- facebook -->
        <OneLink v-bind="props.icon" :href="facebook.href" v-if="facebook">
          <OneIcon icon="SocialFacebook" />
        </OneLink>

        <!-- instagram -->
        <OneLink v-bind="props.icon" :href="instagram.href" v-if="instagram">
          <OneIcon icon="SocialInstagram" />
        </OneLink>

        <!-- linkedin -->
        <OneLink v-bind="props.icon" :href="linkedin.href" v-if="linkedin">
          <OneIcon icon="SocialLinkedin" />
        </OneLink>

        <!-- twitter -->
        <OneLink v-bind="props.icon" :href="twitter.href" v-if="twitter">
          <OneIcon icon="SocialTwitter" />
        </OneLink>

        <!-- github -->
        <OneLink v-bind="props.icon" :href="github.href" v-if="github">
          <OneIcon icon="SocialGithub" />
        </OneLink>

        <!-- dribbble -->
        <OneLink v-bind="props.icon" :href="dribbble.href" v-if="dribbble">
          <OneIcon icon="SocialDribbble" />
        </OneLink>

        <!-- tripadvisor -->
        <OneLink v-bind="props.icon" :href="tripadvisor.href" v-if="tripadvisor">
          <OneIcon icon="SocialTripadvisor" />
        </OneLink>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneFooter2",
  mixins: [mixinClasses],
  props: {
    logo: {
      type: Object,
    },
    caption: {
      type: [String, Boolean],
    },
    phone: {
      type: [Object, Boolean],
      default: null,
    },
    email: {
      type: [Object, Boolean],
      default: null,
    },
    facebook: {
      type: [Object, Boolean],
      default: null,
    },
    instagram: {
      type: [Object, Boolean],
      default: null,
    },
    linkedin: {
      type: [Object, Boolean],
      default: null,
    },
    twitter: {
      type: [Object, Boolean],
      default: null,
    },
    github: {
      type: [Object, Boolean],
      default: null,
    },
    dribbble: {
      type: [Object, Boolean],
      default: null,
    },
    tripadvisor: {
      type: [Object, Boolean],
      default: null,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full py-10 font-body",
      },
      logoWrap: {
        class: "flex flex-col flex-center",
      },
      caption: {
        class: "pt-3 text-center",
      },
      contacts: {
        class: "text-center pt-10",
      },
      container: {
        class: "flex flex-col",
      },
      icons: {
        class: "flex flex-row pt-10",
      },
      icon: {
        class: "social-icon px-2",
      },
    },
  }),
}
</script>

<style scope lang="less">
.social-icon {
  svg {
    width: 36px;
    height: 36px;
  }
}
</style>
