<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- image & caption wrap -->
    <div data-one-label="memberWrap" v-bind="props.memberWrap">
      <!-- potrait -->
      <OneImage
        maxHeight="none"
        minHeight="none"
        v-bind="props.portrait"
        :src="portrait.src"
        circle
        v-if="portrait"
      />

      <!-- name -->
      <OneHeading v-bind="props.name" :color="nameColor" v-if="props.name && props.name.content" />

      <!-- caption -->
      <OneParagraph
        v-bind="props.caption"
        :color="captionColor"
        v-if="props.caption && props.caption.content"
      />
    </div>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneTeamMember1",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    memberCount: {
      type: Number,
      default: 1,
    },
    nameColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
    portrait: {
      type: Object,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "flex flex-col justify-center",
        propClass: {
          memberCount: {
            _common: "w-full",
            1: "",
            2: "md:w-1/2 md:first:pr-2 md:last:pl-2",
            3: "md:w-1/3 md:first:pr-2 md:last:pl-2",
            4: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:mb-16",
            5: "md:w-1/3 md:odd:pr-1 md:even:pl-1 md:mb-16",
            6: "md:w-1/3 md:odd:pr-1 md:even:pl-1 md:mb-16",
            7: "md:w-1/3 md:odd:pr-1 md:even:pl-1 md:mb-16",
            8: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:mb-16",
            9: "md:w-1/3 md:odd:pr-1 md:even:pl-1 md:mb-16",
            10: "md:w-1/2 md:odd:pr-1 md:even:pl-1 md:mb-16",
          },
        },
      },
      memberWrap: {
        class: "flex flex-col items-center",
      },
      portrait: {
        class: "!w-32 !h-32 md:!w-64 md:!h-64",
      },
      name: {
        class: "mt-4",
        level: 4,
        align: "center",
      },
      caption: {
        align: "center",
        color: "default",
        class: "whitespace-nowrap",
        size: "base",
      },
    },
  }),
}
</script>
