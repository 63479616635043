export const routes = [
  {
    path: "/download/:kind", // invoice | giftCard | product (digital)
    name: "DownloadView",
    component: () => import("../views/DownloadView.vue"),
    meta: {
      // requiresAuth: false,
    },
  },
  // {
  // path: "/user",
  // name: "UserView",
  // component: () => import("../views/UserView.vue"),
  // meta: {
  //   // requiresAuth: false,
  // },
  // children: [
  //   {
  //     path: "purchases",
  //     name: "UserPurchasesView",
  //     component: () => import("../views/UserPurchasesView.vue"),
  //     meta: {
  //       // requiresAuth: false,
  //     },
  //   },
  // ],
  // },
  // {path: "", redirect: {name: "MainView"}},
  // {path: "/:pathMatch(.*)*", redirect: {name: "MainView"}},
]
