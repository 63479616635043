<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer
      v-bind="props.container"
      :override="props.container"
      :width="containerWidth"
      :verticalPadding="verticalPadding"
    >
      <!-- member -->
      <OneTeamMember
        :variant="variant"
        :portrait="member.portrait"
        :nameColor="nameColor"
        :captionColor="captionColor"
        :memberCount="memberCount"
        v-for="(member, index) in members"
        :key="index"
        :override="member"
        v-bind="props.member"
      />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneTeam",
  mixins: [mixinClasses],
  props: {
    members: {
      type: Array,
    },
    variant: {
      type: Number,
      default: 1,
    },
    nameColor: {
      // type: String
    },
    captionColor: {
      // type: String
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex-wrap flex flex-row items-center justify-center",
      },
      member: {
        class: "pb-5 md:pb-0 last:pb-0",
      },
    },
  }),
  computed: {
    memberCount() {
      return this.members ? this.members.length : 0
    },
    verticalPadding() {
      return this.variant == 2 ? 0 : 5
    },
    containerWidth() {
      return this.variant == 2 ? "full" : "constrained"
    },
  },
}
</script>
