<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- images -->
    <OneImage
      v-for="(image, index) in images"
      :key="`${image.imageId}-${index}`"
      :src="image.src"
      :style="getStyle(image, index)"
      :fit="fit"
      :imageId="image.imageId"
      :override="props.image"
      v-bind="props.image"
      maxHeight="screen"
      minHeight="full"
    />
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"
import easing from "../../mixins/easing"

export default {
  name: "OneImageSlideshow",
  mixins: [mixinClasses, easing],
  props: {
    images: {
      type: [Boolean, Array],
    },
    config: {
      type: Object,
    },
    fit: {
      type: String,
    },
  },
  components: {},
  data: () => ({
    imageStyles: [],
    currentImageIndex: 0,
    nextImageIndex: 1,
    slideshowFadeSpeed: 2500,
    slideshowDelay: 5000,
    defaults: {
      commonClasses: "",
      root: {
        class: "relative w-full h-full",
        propClass: {},
      },
      image: {
        class: "absolute inset-0",
        propClass: {},
      },
    },
  }),
  created() {
    this.init()
  },
  methods: {
    getStyle(image, index) {
      return this.imageStyles[index]
    },
    init() {
      if (this.config && this.config.slideshow && this.config.slideshow.fadeSpeed)
        this.slideshowFadeSpeed = this.config.slideshow.fadeSpeed
      if (this.config && this.config.slideshow && this.config.slideshow.delay)
        this.slideshowDelay = this.config.slideshow.delay
      if (this.images.length) {
        this.images.forEach((image, index) => {
          let opacity = index == 0 ? 1 : 0
          this.imageStyles.push({
            opacity,
          })
        })
        setTimeout(() => {
          this.fadeToNext()
        }, this.slideshowDelay)
      }
    },
    fadeToNext() {
      // console.log('fadeToNext(), currentImageIndex:', this.currentImageIndex, 'nextImageIndex:', this.nextImageIndex)
      let fadeDelay = 0
      for (let i = 0; i < 1; i += 0.01) {
        fadeDelay += this.slideshowFadeSpeed / 100
        setTimeout(() => {
          let currentImageOpacity = this.easeInOutQuad(1 - i)
          let nextImageOpacity = this.easeInOutQuad(i)
          if (currentImageOpacity <= 0.01 || currentImageOpacity >= 0.99)
            currentImageOpacity = Math.round(currentImageOpacity)
          if (nextImageOpacity <= 0.01 || nextImageOpacity >= 0.99)
            nextImageOpacity = Math.round(nextImageOpacity)
          if (this.imageStyles[this.currentImageIndex])
            this.imageStyles[this.currentImageIndex].opacity = currentImageOpacity
          if (this.imageStyles[this.nextImageIndex])
            this.imageStyles[this.nextImageIndex].opacity = nextImageOpacity

          // console.log(i, '|', this.currentImageIndex, this.linear(currentImageOpacity), '|', this.nextImageIndex, this.linear(nextImageOpacity))
          if (i >= 0.99) this.incrementIndexes()
        }, fadeDelay)
      }

      setTimeout(() => {
        this.fadeToNext()
      }, this.slideshowDelay)
    },
    incrementIndexes() {
      // increment current & next
      // console.log('incrementing')
      this.currentImageIndex =
        this.currentImageIndex < this.images.length - 1 ? (this.currentImageIndex += 1) : 0
      this.nextImageIndex =
        this.nextImageIndex < this.images.length - 1 ? (this.nextImageIndex += 1) : 0
    },
  },
}
</script>
