import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useAdminStore = defineStore("admin", () => {
  // state
  const admin = ref({
    lang: "cs",
    addingBlock: false,
    referenceBlockId: null, // block above/under the new block
    referenceBlockIndex: null, // page may have mutliple blocks with same id, so we need exact position
    newBlockPosition: null, // above | below
    toolsOpenGlobal: false,
    toolsOpenBlockId: null, // because reordering blocks kills the state
  })

  // getters

  // actions
  function addBlock(args) {
    console.log("addBlock:", args)
    admin.value.addingBlock = true
    if (args && args.referenceBlockId) {
      admin.value.referenceBlockId = args.referenceBlockId
    }
    if (args && args.referenceBlockIndex) {
      admin.value.referenceBlockIndex = args.referenceBlockIndex
    }
    if (args && args.position) {
      admin.value.newBlockPosition = args.position
    }
  }

  function blockAdded() {
    admin.value.addingBlock = false
    admin.value.referenceBlockId = null
    admin.value.referenceBlockIndex = null
    admin.value.newBlockPosition = null
  }

  return {
    // state
    admin,

    // getters

    // actions
    addBlock,
    blockAdded,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminStore, import.meta.hot))
}
