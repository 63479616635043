import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "../firebase/index.js"
import {doc, query} from "firebase/firestore"
import {useShopCartStore} from "./shopCart"

export const useShopStore = defineStore("shop", () => {
  // stores
  const shopCartStore = useShopCartStore()

  // state
  const shop = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopLoaded = computed(() => status.value == "loaded")
  const id = computed(() => shop.value.id)
  const tenantId = computed(() => shop.value.auth?.tenantId)
  const deliveryMethods = computed(() => shop.value.delivery.methods)
  const paymentMethods = computed(() => shop.value.payment.methods)
  const currenciesEnabledCodes = computed(() => {
    let c = []
    if (!shop.value || !shop.value.currencies) return
    shop.value.currencies.forEach((i) => {
      if (i && i.enabled) c.push(i.code)
    })
    return c
  })

  // actions
  async function bind() {
    const shopId = window.site.shopId
    console.log("shop.js bind query", firestore)
    const docRef = doc(firestore, `shops/${shopId}`)
    const q = query(docRef)
    await this.attach("shop", q).catch((e) => console.error("attach error:", e))
    setInitialCurrency()
    return
  }

  async function unbind() {
    return await this.detach()
  }

  function setInitialCurrency() {
    // TODO: this might work better with IP analysis
    const browserLanguage = navigator.language
    const defaultCurrency = shop.value.config.defaultCurrency
    const enabledCurrencies = currenciesEnabledCodes.value
    const langcodeCurrency = {
      en: "USD",
      "en-US": "USD",
      "en-GB": "GBP",
      cs: "CZK",
      "cs-CZ": "CZK",
      sk: "EUR",
      "sk-SK": "EUR",
    }
    let currency = langcodeCurrency[browserLanguage]

    console.log("browserLanguage:", browserLanguage)
    console.log("enabledCurrencies", enabledCurrencies)
    console.log("shop default currency:", defaultCurrency)
    console.log("langcodeCurrency:", currency)

    if (!currency || !enabledCurrencies || !enabledCurrencies.includes(currency))
      currency = defaultCurrency

    console.log("setting currency:", currency)
    shopCartStore.frontend.currency = currency
  }

  return {
    // state
    shop,
    status,
    unsubscribe,

    // getters
    shopLoaded,
    id,
    tenantId,
    deliveryMethods,
    paymentMethods,
    currenciesEnabledCodes,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopStore, import.meta.hot))
}
