export default {
  classes: {
    pfmCover: {
      base: "bg-background-light",
      layout: {
        scrollLine: "hidden",
      },
    },
    pfmMenu: {
      base: "+ bg-background-light",
      layout: {
        // btnCart: '+ fixed top-0 right-0 mt-5 mr-5 z-50'
      },
    },
    pfmFooter: {
      base: "",
      layout: {
        logoWrap: "",
        icons: "",
      },
    },
  },
}
