import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, query, where} from "firebase/firestore"
import {evalItemVisibility} from "@platformaone/common/commerce"

export const useShopItemsStore = defineStore("shopItems", () => {
  // state
  const items = ref([])
  const selected = ref({
    id: null,
    variantId: null,
  })
  const itemDetailPageId = ref(null)
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemsLoaded = computed(() => status.value == "loaded")
  const selectedItem = computed(() => items.value.find((p) => p.id == selected.value.id))
  const selectedItemVariant = computed(() =>
    items.value.find((p) => p.id == selected.value.variantId)
  )

  // actions
  async function bind() {
    const shopId = window.site.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/products`)
    const q = query(
      collectionRef,
      where("config.showAs", "in", ["available", "unavailable", "auto", "preorder"]),
      where("meta.deleted", "==", false)
    )
    return await this.attach("items", q, serializeItems).catch((e) =>
      console.error("attach error:", e)
    )
  }

  async function unbind() {
    return await this.detach()
  }

  function setSelectedItemBySKU(args) {
    const itemSKU = args

    if (!itemSKU) {
      console.error("itemSKU not specified")
      return
    }
    const selectedItem = items.value.find((p) => p.sku == itemSKU)

    if (selectedItem) {
      // if this is a variant, take parent instead and set the variant as selected variant
      if (selectedItem.parentId && selectedItem.parentId != "main" && items.value) {
        const selectedItemVariant = selectedItem
        selected.value.id = items.value.find((p) => p.id == selectedItemVariant.parentId).id
        selected.value.variantId = selectedItemVariant.id
      } else {
        selected.value.id = selectedItem.id
        selected.value.variantId = selectedItem.id
      }
    } else {
      console.warn(
        "no product selected (probably does not exist):",
        itemSKU,
        JSON.stringify(items.value)
      )
    }
  }

  function getItemBySKU(args) {
    const itemSKU = args
    if (!itemSKU) return false
    return items.value.find((i) => i.sku == itemSKU)
  }

  function getItemById(args) {
    const itemId = args
    return items.value.find((i) => i.id == itemId)
  }

  function getItemVariants(args) {
    const itemId = args
    const itemVariantIds = items.value.find((i) => i.id == itemId).variants
    if (!itemVariantIds) return []
    let itemVariants = []

    itemVariantIds.forEach((pv) => {
      let p = items.value.find((i) => i.id == pv)
      if (p) itemVariants.push(p)
    })
    return itemVariants
  }

  return {
    // state
    items,
    selected,
    itemDetailPageId,
    status,
    unsubscribe,

    // getters
    shopItemsLoaded,
    selectedItem,
    selectedItemVariant,

    // actions
    bind,
    unbind,
    setSelectedItemBySKU,
    getItemBySKU,
    getItemById,
    getItemVariants,
  }
})

// extend with properties
const serializeItems = (snapshot, data) => {
  // product minimum requirements
  if (
    !data.config ||
    !data.config.quantity ||
    !data.desc ||
    !data.desc.title ||
    !data.modelId ||
    !data.price ||
    !data.sku
  ) {
    return false
  }

  // remove sold out products that are set to be hidden when sold out
  if (
    data.config.showAs == "auto" &&
    !data.config.quantity.stock.available &&
    data.config.quantity.stock.whenUnavailable == "hide"
  ) {
    return false
  }

  // remove hidden products (if for some reason the .where() is not working
  if (data.config.showAs == "hide") {
    return false
  }

  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _display: {
      value: evalItemVisibility(data),
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemsStore, import.meta.hot))
}
