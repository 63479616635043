import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc} from "firebase/firestore"

export const useShopCustomerStore = defineStore("shopCustomer", () => {
  // state
  const customer = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const customerLoaded = computed(() => status.value == "loaded")
  const customerId = computed(() => customer.value.id)

  // actions
  async function bind({shopId = window.site.shopId, customerId}) {
    console.log("binding customer:", customerId)
    const docRef = doc(firestore, `shops/${shopId}/customers/${customerId}`)
    return await this.attach("customer", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  // async function update({userId, data}) {
  //   const docRef = doc(firestore, `users/${userId}`)
  //   const updateData = {
  //     ...data,
  //     "meta.updated": serverTimestamp(),
  //   }
  //   console.log("updating customer with data:", data)
  //   return await updateDoc(docRef, updateData)
  // }

  return {
    // state
    customer,
    status,
    unsubscribe,

    // getters
    customerLoaded,
    customerId,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopCustomerStore, import.meta.hot))
}
