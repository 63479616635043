<template>
  <div :data-one-component="$options.name" v-bind="props.root" @click="close()">
    <!-- images -->
    <OneImage
      :src="image.src"
      :override="props.image"
      v-bind="props.image"
      maxWidth="screen"
      maxHeight="screen"
      fit="contain"
    />
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneImageLightbox",
  mixins: [mixinClasses],
  props: {
    image: {
      type: Object,
    },
  },
  emits: ["update:close"],
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "w-screen h-screen fixed inset-0 bg-black z-50 flex-center cursor-pointer",
        propClass: {},
      },
      image: {
        class: "",
        propClass: {},
      },
    },
  }),
  methods: {
    close() {
      this.$emit("update:close")
    },
  },
}
</script>
