import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

/* eslint-disable */
// FIXME: i have no idea what this should be doing -> update for pinia
// store.subscribe((mutation, state) => {
//   if (
//     mutation.type.startsWith("vuexfire/SET_VALUE") &&
//     mutation.payload.path == "cart" &&
//     mutation.payload.data &&
//     mutation.payload.data.delivery
//   ) {
//     console.log("mutation:", mutation)

//     let valid = null

//     //
//     // delivery.slot.date
//     valid =
//       (mutation.payload.data.delivery.slot.date &&
//         typeof mutation.payload.data.delivery.slot.date.toDate === "function") ||
//       (store.getters["commerce/cart/getCartSelectedDeliveryMethod"] &&
//         store.getters["commerce/cart/getCartSelectedDeliveryMethod"].constraints.deliverySlots ===
//           false)
//     store.commit(
//       "commerce/cart/validator/setFieldValidity",
//       {
//         name: "deliverySlotDate",
//         valid,
//       },
//       {root: true}
//     )
//     if (valid) {
//       store.commit(
//         "commerce/cart/validator/setFieldInteraction",
//         {
//           name: "deliverySlotDate",
//         },
//         {root: true}
//       )
//     }
//     valid = null

//     //
//     // delivery.slot.id
//     valid =
//       (mutation.payload.data.delivery.slot.id != null &&
//         mutation.payload.data.delivery.slot.id != undefined) ||
//       (store.getters["commerce/cart/getCartSelectedDeliveryMethod"] &&
//         store.getters["commerce/cart/getCartSelectedDeliveryMethod"].constraints.deliverySlots ===
//           false)
//     store.commit(
//       "commerce/cart/validator/setFieldValidity",
//       {
//         name: "deliverySlotSlot",
//         valid,
//       },
//       {root: true}
//     )
//     if (valid) {
//       store.commit(
//         "commerce/cart/validator/setFieldInteraction",
//         {
//           name: "deliverySlotSlot",
//         },
//         {root: true}
//       )
//     }
//     valid = null
//   }
// })
/* eslint-enable */

export const useValidatorStore = defineStore("validator", () => {
  // state
  const fields = ref({})

  // getters
  const allRequiredFieldsFilledAndValid = computed(() => {
    let valid = true
    for (const field of Object.values(fields.value)) {
      if (field.required && (!field.interacted || !field.valid)) {
        valid = false
      }
    }
    return valid
  })
  const invalidFields = computed(() => {
    if (!fields.value) return
    let f = []
    for (const [key, field] of Object.entries(fields.value)) {
      let fieldValid = true
      field._name = key
      if (field.required && (!field.interacted || !field.valid)) {
        fieldValid = false
      }
      if (!fieldValid) {
        f.push(field)
      }
    }
    return f
  })

  // actions
  function addField(args) {
    const fieldExists = fields.value[args.name]
    const initialState = {
      name: args.name,
      state: fieldExists && fieldExists.state ? fieldExists.state : args.state,
      interacted: fieldExists && fieldExists.interacted ? fieldExists.interacted : false,
      required: fieldExists && fieldExists.required ? fieldExists.required : args.required,
      valid: fieldExists && fieldExists.valid ? fieldExists.valid : args.valid,
    }

    fields.value[args.name] = {
      ...initialState,
      initialSnapshot: initialState,
    }
  }

  function highlightInvalidFields() {
    for (const field of Object.values(fields.value)) {
      let fieldValid = true
      if (field.required && (!field.interacted || !field.valid)) {
        fieldValid = false
      }
      if (!fieldValid) {
        setFieldValidity({name: field.name, valid: false})
      }
    }
  }

  function isValid(args) {
    const fieldName = args
    if (fields.value[fieldName]) {
      return fields.value[fieldName].valid
    } else {
      return true // field not yet added and valid is default
    }
  }

  function detail(args) {
    const fieldName = args
    if (fields.value[fieldName]) {
      return fields.value[fieldName]
    } else {
      return true // field not yet added and valid is default
    }
  }

  function setFieldValidity(args) {
    if (!fields.value[args.name]) fields.value[args.name] = {}
    fields.value[args.name].valid = args.valid
    fields.value[args.name].error = args.error
  }

  function setFieldRequirement(args) {
    if (!fields.value[args.name]) fields.value[args.name] = {}
    fields.value[args.name].required = args.required
  }

  function setFieldInteraction(args) {
    if (!fields.value[args.name]) fields.value[args.name] = {}
    fields.value[args.name].interacted = true
  }

  function setFieldState(args) {
    if (!fields.value[args.name]) fields.value[args.name] = {}
    fields.value[args.name].state = args.state
  }

  function removeField(args) {
    delete fields.value[args.name]
  }

  function resetField(args) {
    fields.value[args.name] = {
      ...fields.value[args.name].initialSnapshot,
      initialSnapshot: fields.value[args.name].initialSnapshot,
      wasReset: true,
    }
  }

  return {
    // state
    fields,

    // getters
    allRequiredFieldsFilledAndValid,
    invalidFields,

    // actions
    addField,
    highlightInvalidFields,
    isValid,
    detail,
    setFieldValidity,
    setFieldRequirement,
    setFieldInteraction,
    setFieldState,
    removeField,
    resetField,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useValidatorStore, import.meta.hot))
}
