<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <OneContainer v-bind="props.container" :override="props.container">
      <!-- images -->
      <OneImages :images="images" :override="props.images" v-bind="props.images" />
    </OneContainer>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneGallery",
  mixins: [mixinClasses],
  props: {
    images: {
      type: Array,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
    },
  }),
}
</script>
