<template>
  <!-- variant 1 -->
  <OneTeamMember1
    :override="override"
    :portrait="portrait"
    :nameColor="nameColor"
    :captionColor="captionColor"
    :memberCount="memberCount"
    v-if="variant == 1"
  />

  <!-- variant 2 -->
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneTeamMember",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
    portrait: {
      type: Object,
    },
    memberCount: {
      type: Number,
      default: 1,
    },
    nameColor: {
      type: String,
    },
    captionColor: {
      type: String,
    },
  },
}
</script>
