<template>
  <video
    ref="video"
    v-bind="props.root"
    :poster="posterSrc"
    muted
    loop
    playsinline
    autoplay
    @canplay="onCanPlay"
    @canplaythrough="onCanPlayThrough"
    @emptied="onEmptied"
    @loadeddata="onLoadedData"
    @loadedmetadata="onLoadedMetadata"
    @play="onPlay"
    @pause="onPause"
    @ended="onEnded"
    @progress="onProgress"
    @stalled="onStalled"
    @suspend="onSuspend"
    @waiting="onWaiting"
  >
    <source :src="this.src" :type="contentType" />
    <!-- <source src="https://storage.googleapis.com/platformaone-development.appspot.com/sites/fhG0HNTsYSCUIXNbbGAy/videos/hp34rMaAzlEP5jqRG6VR" type="video/mp4" /> -->
  </video>
</template>

<script>
// import mixinBlock from '@/mixins/block'
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneVideo",
  mixins: [mixinClasses],
  props: {
    classOverride: {
      type: Object,
    },
    stylesConfig: {
      type: Object,
    },
    src: {
      type: [String, Boolean],
      default: false,
      validator: (val) => {
        if (typeof val === "string") {
          return val.length >= 1
        } else if (typeof val === "boolean") {
          return true
        }
      },
    },
    posterSrc: {
      type: [String, Boolean],
      default: false,
    },
    videoId: {
      type: [String, Boolean],
      default: false,
    },
    contentType: {
      // type: [String, Boolean],
    },
    ratio: {
      type: String,
      default: "auto",
    },
    fit: {
      type: String,
      default: "cover",
    },
    maxHeight: {
      type: String,
      default: "auto", // auto | full | screen | none
    },
    minHeight: {
      type: String,
      default: "auto", // auto | full | screen | none
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: "",
        propClass: {
          maxHeight: {
            auto: "max-h-full",
            full: "h-full",
            screen: "max-h-screen",
            none: "",
          },
          minHeight: {
            auto: "h-auto",
            full: "h-full",
            screen: "min-h-screen",
            none: "",
          },
          fit: {
            cover: "h-full w-full object-cover",
            contain: "flex",
          },
          ratio: {
            auto: "ratio-auto",
            square: "ratio-square",
          },
          circle: {
            true: "block",
            false: "",
          },
        },
      },
    },
    found: false,
  }),
  computed: {
    // style() {
    //   let s = {
    //     width: "100%",
    //     // height: '100%',
    //     objectFit: this.fit,
    //   }
    //   // width
    //   if (this.stylesConfig && this.stylesConfig.width) {
    //     let prop = this.stylesConfig.width
    //     let v = this.isObject(prop) ? prop[this.viewportSize] : prop
    //     s.width = `${v}`
    //   }
    //   if (this.ratio == "square") {
    //     if (this.picture && this.picture.clientWidth) {
    //       s.height = `${this.picture.clientWidth}px`
    //       s.width = `${this.picture.clientWidth}px`
    //     }
    //   }
    //   return s
    // },
  },
  created() {
    // console.log('src', JSON.parse(JSON.stringify(this.src)))
  },
  mounted() {
    // console.log(this.$refs.video)
    // this.$refs.video.src = this.src
  },
  methods: {
    onCanPlay() {
      // console.log("onCanPlay")
    },
    onCanPlayThrough() {
      // console.log("onCanPlayThrough")
      // let video = this.$refs.video
      // var playPromise = video.play()
      // if (playPromise !== undefined) {
      //   playPromise.then(_ => {
      //     console.log('started')
      //     // Automatic playback started!
      //     // Show playing UI.
      //   })
      //   .catch(error => {
      //     console.error('error', error)
      //     // Auto-play was prevented
      //     // Show paused UI.
      //   });
      // }
    },
    onEmptied() {
      // console.log("onEmptied")
    },
    onLoadedData() {
      // console.log("onLoadedData")
    },
    onLoadedMetadata() {
      // console.log("onLoadedMetadata")
    },
    onPlay() {
      // console.log("onPlay")
    },
    onPause() {
      // console.log("onPause")
      if (!this.$refs || !this.$refs.video) return
      let video = this.$refs.video

      var isPlaying =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA
      if (!isPlaying) {
        video.play()
      }
    },
    onEnded() {
      // console.log("onEnded")
    },
    onProgress() {
      // console.log("onProgress")
    },
    onStalled() {
      // console.log("onStalled")
    },
    onSuspend() {
      // console.log("onSuspend")
    },
    onWaiting() {
      // console.log("onWaiting")
    },
  },
}
</script>
