<template>
  <p
    ref="editable"
    :contenteditable="contenteditable"
    v-on="listeners"
    :class="[{lowercase: lowercase}, {disabled: disabled}, `mode-${mode}`]"
  />
</template>

<script>
export default {
  name: "OneDatatypeString",
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    nospace: {
      type: Boolean,
      default: false,
    },
    regex: {
      type: [String, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit", // view | edit
    },
  },
  emits: [
    "update:modelValue",
    "update:input",
    "update:keydown",
    "update:keyup",
    "update:focus",
    "update:blur",
    "update:paste",
    "update:enter",
  ],
  data() {
    return {
      focused: false,
      savedSelection: null,
    }
  },
  computed: {
    contenteditable() {
      return this.mode == "edit"
    },
    listeners() {
      return {
        ...this.$attrs,
        input: this.onInput,
        keydown: this.onKeyDown,
        keyup: this.onKeyUp,
        focus: this.onFocus,
        blur: this.onBlur,
        paste: this.onPaste,
      }
    },
  },
  watch: {
    modelValue: {
      handler() {
        // this is used when the field is not updated directly by user (e.g. auto calculation of non VAT price by typing into VAT price field
        if (!this.focused) {
          // // console.log('updating value:', this.modelValue)
          this.setValue()
        }
      },
    },
  },
  mounted() {
    this.setValue()
  },
  methods: {
    setValue() {
      this.$refs.editable.innerText = this.modelValue
    },
    onPaste(e) {
      e.preventDefault()
      e.stopPropagation()
      let clipboardText = e.clipboardData.getData("text/plain")
      console.log("clipboardText:", clipboardText)
      this.savedSelection = this.getCurrentSelection()
      console.log("savedSelection", this.savedSelection)

      var sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          // let br = document.createElement('br')
          range.insertNode(document.createTextNode(clipboardText))
        }
      } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().text = clipboardText
      }

      this.restoreSelection(this.savedSelection)
    },
    getCurrentSelection() {
      let sel
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          return sel.getRangeAt(0)
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange()
      }
      return null
    },

    restoreSelection(range) {
      let sel
      if (range) {
        if (window.getSelection) {
          sel = window.getSelection()
          console.log("sel:", sel)
          // sel.removeAllRanges()
          // sel.removeRange(this.getCurrentSelection())
          sel.collapseToEnd(this.$refs.editable)
          console.log("removeAllRanges", sel)
          sel.addRange(range)
          console.log("addRange", sel, range)
        } else if (document.selection && range.select) {
          range.select()
        }
      }
    },
    onKeyDown(e) {
      if (this.disabled) return
      // console.log('onKeyDown: e.keyCode:', e.keyCode)

      if (!this.valid(e)) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    onKeyUp(e) {
      // console.log('onKeyUp', e)
      if (e.key == "Enter") this.$emit("update:enter", e)
      this.$emit("update:keyup", e)
      if (this.disabled) return
      // // console.log('onKeyUp: e.keyCode:', e.keyCode)

      if (!this.valid(e)) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        console.log("onKeyUp() emitting modelValue", e.target.innerText)
        this.$emit("update:modelValue", this.format(e.target.innerText))
      }
    },
    onInput() {
      // console.log("onInput", e)
      // !!! This needs to stay, even if it does nothing, because v-model expects it.
    },
    onFocus(e) {
      // // console.log('onFocus', e)
      this.$emit("update:focus", e)
      this.focused = true
    },
    onBlur(e) {
      // // console.log('onBlur', e)
      this.$emit("update:blur", e)
      this.focused = false
    },

    // formatters
    // it would be great to make the formatting live & visible, but it is a lot of work, so fuck it for now.
    format(v) {
      let string = v
      if (this.lowercase) string = string.toLowerCase() // live version for this is faked by CSS
      if (this.nospace) {
        // this solves issue when spaces are pasted
        string = string.replace(/\s/g, "")
      }
      if (string === "") string = null
      if (string === "true") string = true
      if (string === "false") string = false
      // // console.log('formatted:', this.lowercase, string)
      return string
    },

    // validators
    valid(e) {
      if (this.disabled) return
      return this.validateSpace(e) && this.validateEnter(e) && this.validateRegex(e)
    },
    validateSpace(e) {
      if (this.nospace) {
        // ignore space
        if (e.keyCode === 32) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    validateEnter(e) {
      if (!this.multiline) {
        // ignore enter
        if (e.keyCode === 13) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    validateRegex(e) {
      // allow backspace, delete, tab, arrows, cmd/ctrl + a, cmd/ctrl + c, cmd/ctrl + v
      if (
        this.regex &&
        ![8, 46, 9, 37, 38, 39, 40].includes(e.keyCode) &&
        !([65, 67, 86].includes(e.keyCode) && (e.metaKey || e.ctrlKey))
      ) {
        let regex = new RegExp(`${this.regex}`)
        let valid = regex.test(e.key)
        return valid
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../variables.less";
p {
  padding: 12px 8px;
  outline: none;
  white-space: pre-wrap;

  &.lowercase {
    text-transform: lowercase;
  }

  &.disabled {
    color: @pfm-color-neutral-600;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
