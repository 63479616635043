<template>
  <!-- variant 1 -->
  <OneHero1
    v-bind="{...$props, ...$attrs}"
    :containerOverride="props.container"
    :logoCaptionWrapOverride="props.logoCaptionWrap"
    :logoOverride="props.logo"
    v-if="variant == 1"
  >
  </OneHero1>
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneHero",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
  },
}
</script>
